import { Dialog, DialogPanel } from '@headlessui/react';
import Button from 'src/components/button/Button';
import SyncLoader from 'react-spinners/SyncLoader';
import IconSvg from 'src/components/common/ui/IconSvg';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import rewardContracts from '../../../../contracts/avax/AvaxRewardContract.json';
import { useHttp } from 'src/hooks';

const environment = process.env.REACT_APP_ENVIRONMENT;

interface Props {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  userLotteryData: any;
  collectionDetail: any;
  rerollNft: () => void;
  claimNft: () => void;
  isClaimingNft: boolean;
  isRerollingNft: boolean;
}

const AvaxRewardModal = ({
  isOpen,
  onClose,
  userLotteryData,
  collectionDetail,
  claimNft,
  rerollNft,
  isClaimingNft,
  isRerollingNft,
}: Props) => {
  const { sendRequest } = useHttp();
  const [text, setText] = useState<string | null>(null);
  const [data, setData] = useState<{ title: string; image: string } | null>(null);

  useEffect(() => {
    const fetchReward = async () => {
      try {
        const phase1Config = collectionDetail?.stages?.active[0]?.metadata[environment];
        const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
        const contract = new ethers.Contract(
          phase1Config.LOOTY_TOKEN_LOTTERY.address,
          phase1Config.LOOTY_TOKEN_LOTTERY.abi,
          provider,
        );
        const rewardType = await contract.rewardTypes(userLotteryData?.candidateRewardTypeIndex);
        const { rewardContract, amountIfToken: tokenIdOrAmount, isNFT } = rewardType;
        setData(null);
        // Check if it's an NFT or token
        if (rewardType.isNFT) {
          setText(`NFT - ${userLotteryData?.candidateTokenIdIfNFT.toString()}`);

          const nftContract = new ethers.Contract(
            rewardContract,
            ['function tokenURI(uint256) view returns (string)'],
            provider,
          );
          let tokenUri = '';
          let collectionName = '';
          tokenUri = await nftContract.tokenURI(userLotteryData?.candidateTokenIdIfNFT);
          const metadata = await getMetadataByTokenUri(tokenUri);

          console.log('metadata', metadata);

          if (rewardContracts && rewardContracts[rewardContract]) {
            collectionName = rewardContracts[rewardContract].collectionName;
          }
          setData({
            title: `${collectionName} (${metadata.name})`,
            image: metadata.image,
          });
        } else {
          setText(
            `TOKEN - ${(parseFloat(tokenIdOrAmount.toString() || '0') / Math.pow(10, 18))
              .toFixed(2)
              .toString()}`,
          );
          const amount = (parseFloat(tokenIdOrAmount.toString() || '0') / Math.pow(10, 18))
            .toFixed(2)
            .toString();
          const collectionName = rewardContracts[rewardContract].collectionName;
          setData({
            title: `${amount} ${collectionName}`,
            image: `/assets/images/reward/avax/tokens/${collectionName}.png`,
          });
        }
      } catch (error) {
        console.error('Error fetching reward details:', error);
      }
    };
    if (userLotteryData && collectionDetail && userLotteryData.state === 2) fetchReward();
  }, [userLotteryData, collectionDetail]);

  const getMetadataByTokenUri = (tokenUri: string) => {
    return new Promise((resolve, reject) => {
      sendRequest(
        {
          url: `metadata/nft`,
          method: 'POST',
          payload: { tokenUri },
        },
        (data: any) => {
          if (data) {
            resolve(data); // Resolve the promise with the data
          } else {
            reject(new Error('No metadata found'));
          }
        },
      );
    });
  };

  return (
    <Dialog
      open={isOpen}
      as="div"
      className="relative z-20 focus:outline-none"
      onClose={() => {
        onClose(false);
        setData(null);
      }}
    >
      <div className="overflow-y-auto fixed inset-0 z-10 w-screen bg-grayscale-900/50">
        <div className="flex justify-center items-center p-4 min-h-full">
          <DialogPanel
            transition
            className="relative w-full p-5 3xl:px-8 border-2 rounded-md max-w-fit bg-grayscale-800 border-grayscale-700 max-h-[95vh] overflow-y-auto no-scrollbar"
          >
            {userLotteryData && userLotteryData.state === 0 && (
              <button
                className="absolute top-[11px] right-[13px]"
                onClick={() => {
                  onClose(false);
                  setData(null);
                }}
              >
                <IconSvg icon="closeM" className="text-grayscale-400" />
              </button>
            )}

            {/*<div className="bg-white text-black">*/}
            {/* ---- {text} ----*/}
            {/*</div>*/}

            {!data && <SyncLoader color="#fff" size={5} />}

            {data && userLotteryData && (
              <div className="max-w-[595px] space-y-6">
                {/* Show Image or Token Logo */}
                {/* <img
                  src={data.image}
                  className="max-w-[35vh] rounded-md w-full mx-auto"
                  alt={data.title}
                /> */}

                {data?.image.endsWith('.mp4') ? (
                  <video className="max-w-[35vh] rounded-md w-full mx-auto" controls autoPlay loop>
                    <source src={data?.image} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    src={data?.image}
                    className="max-w-[35vh] rounded-md w-full mx-auto"
                    alt={data?.title}
                  />
                )}

                {/* Display NFT/Token Name */}
                <h2 className="uppercase text-brand-primary-light text-[18px] lg:text-[32px]">
                  {data.title}
                </h2>

                <div className="w-full border-b-2 border-grayscale-700"></div>

                <div className="text-sm text-center text-grayscale-200 lg:text-base">
                  {userLotteryData && userLotteryData.state === 0 && (
                    <p className="mb-4">Your reward has been added to your wallet.</p>
                  )}
                  {userLotteryData.state !== 0 && (
                    <p className="mb-4">
                      {userLotteryData?.rerollsLeft > 0
                        ? 'Claim your reward or re-roll if your key allows it. Note that once you re-roll, you cannot claim this reward.'
                        : 'Claim your reward.'}
                    </p>
                  )}
                </div>

                <div className="flex flex-wrap gap-y-4 gap-x-6 justify-center items-center">
                  {userLotteryData?.state > 0 && userLotteryData?.rerollsLeft > 0 && (
                    <Button
                      size="m"
                      type="primary"
                      classNames={
                        isClaimingNft || isRerollingNft || userLotteryData?.rerollsLeft == 0
                          ? 'cursor-not-allowed'
                          : 'cursor-allowed'
                      }
                      disabled={
                        isClaimingNft || isRerollingNft || userLotteryData?.rerollsLeft == 0
                      }
                      onClick={rerollNft}
                    >
                      {isRerollingNft ? (
                        <SyncLoader color="#fff" size={8} />
                      ) : (
                        `Re-roll (${userLotteryData?.rerollsLeft})`
                      )}
                    </Button>
                  )}

                  {(userLotteryData?.state === 1 || userLotteryData?.state === 2) && (
                    <Button
                      size="m"
                      type="secondary"
                      classNames={
                        isRerollingNft || isClaimingNft || userLotteryData?.state === 0
                          ? 'cursor-not-allowed'
                          : 'cursor-allowed'
                      }
                      disabled={isRerollingNft || isClaimingNft || userLotteryData?.state === 0}
                      onClick={claimNft}
                    >
                      {isClaimingNft || isRerollingNft ? (
                        <SyncLoader color="#fff" size={8} />
                      ) : (
                        'Claim'
                      )}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default AvaxRewardModal;
