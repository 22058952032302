import React, { useEffect, useState } from 'react';
import VideoPlayer from './VideoPlayer';
import { useNavigate, useParams } from 'react-router-dom';
import ProgressBar from './progressBar/ProgressBar';
import IconSvg from 'src/components/common/ui/IconSvg';
import SyncLoader from 'react-spinners/SyncLoader';

const EnclaveVideoPage: React.FC = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState<number>(0);
  const [accessToken, setAccessToken] = useState('');
  const [videoId, setVideoId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const params = new URLSearchParams(location.search);

  const { slug: videoSlug } = useParams<{ slug: string }>();

  const page = params.get('page');
  const jwtString = localStorage.getItem('jwt');
  let tokenshit = '';
  if (jwtString) {
    const data = JSON.parse(jwtString);
    tokenshit = data.token;
  }

  const updateWatchedStatus = async (newProgress: number) => {
    const response = await fetch(
      `${process.env.REACT_APP_PLATFORM_API_URL}/youtube/watch-content-video/${videoSlug}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenshit}`,
        },
        body: JSON.stringify({
          // Include the request payload here, if any
          tokenshit: 'your-value', // Replace 'your-value' with the actual value
        }),
      },
    );
  };

  useEffect(() => {
    if (!loading && videoId === null) {
      navigate('/'); // Redirect to home if no video found
    }
  }, [loading, videoId, navigate]);

  useEffect(() => {
    const fetchVideoDetails = async () => {
      if (!videoSlug) return;

      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_PLATFORM_API_URL}/youtube/video/${videoSlug}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${tokenshit}`,
            },
          },
        );

        const data = await response.json();
        if (response.ok) {
          if (data.metadata && data.metadata.videoId) {
            setVideoId(data.metadata.videoId);
          }
        } else {
          console.error('Failed to fetch video details:', data.message);
        }
      } catch (error) {
        console.error('Error fetching video details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideoDetails();
  }, [videoSlug]);
  useEffect(() => {
    // Check if a token is already present on page load
    const jwtString = localStorage.getItem('jwt');
    if (jwtString) {
      const data = JSON.parse(jwtString);
      const token = data.token;
      setAccessToken(token);

      // sendRequest(
      //   {
      //     url: 'auth/verify-token',
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     payload: JSON.stringify({ token }),
      //   },
      //   (responseData: any) => {
      //     console.log('verify-token video', responseData.success, responseData.token);
      //
      //     if (responseData.success) {
      //       setAccessToken(responseData.token);
      //     } else {
      //       localStorage.removeItem('jwt');
      //     }
      //   },
      // );
    }
  }, []);

  let isCalled = false;
  const progressCount: number[] = [];
  const handleProgress = (newProgress: number) => {
    console.log('newProgress', newProgress)
    if (
      progressCount.indexOf(parseInt(String(newProgress))) === -1 &&
      parseInt(String(newProgress)) > 0
    ) {
      // progressCount.push(parseInt(String(newProgress)));
      progressCount.push(parseInt(String(newProgress)));
    }
    console.log('progressCount', progressCount[progressCount.length-1])
    // setProgress(progressCount.length + 1);
    setProgress(progressCount[progressCount.length-1]);
    if (progressCount[progressCount.length-1] >= 80 && !isCalled) {
      isCalled = true;
      console.log(
        'progressCount ----------------- update progress to server -----------------------',
      );
      updateWatchedStatus(newProgress);
    }
  };

  // Only render if we have a valid video ID
  // if (!videoId) return null;

  return (
    <div className="min-h-screen bg-black">
      <div
        onClick={() => navigate(`/leaderboards/enclave?page=${page}&scrollToEarn=1`)}
        className="flex items-center cursor-pointer w-fit mb-5"
      >
        <IconSvg icon="arrowLeftM" className="text-grayscale-400" />
        <p className="text-sm font-normal text-grayscale-400">Go back</p>
      </div>

      <div className="relative w-full">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <SyncLoader color="#ffffff" size={10} />
          </div>
        ) : videoId ? (
          <VideoPlayer videoId={videoId} onProgress={handleProgress} />
        ) : null}

        <div className="w-full mt-6 rounded-full">
          <h1 className="text-brand-primary-light lg:text-[32px] text-lg">Watch Video</h1>
          <div className="flex flex-col mt-6">
            <span className="text-sm text-right text-white">{`${Math.round(progress)}%`}</span>
            <ProgressBar progress={progress} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnclaveVideoPage;
