import { useEffect, useState } from 'react';
import Button from 'src/components/button/Button';
import IconSvg, { Icons } from 'src/components/common/ui/IconSvg';
import { useAccount } from 'wagmi';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import { useHttp } from 'src/hooks';
import SuccessRedeemedModal from 'src/components/common/modal/SuccessRedeemedModal';
import ArenaRewardCard from './components/ArenaRewardCard';

import whitelist_dev_p1 from '../../whitelist_dev_p1.json';
import { useNavigate } from 'react-router-dom';
import SyncLoader from 'react-spinners/SyncLoader';
import { allowedTwitterUsers } from 'src/allowedTwitterUsers';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import whitelistSeason1 from '../../whitelist/enclave/season1.json';
import EnclaveRewardCard from './components/EnclaveRewardCard';

import { avaxRewardCardData } from 'src/dummydata';
import AvaxRewardCard from '../avax/components/AvaxRewardCard';

const environment = process.env.REACT_APP_ENVIRONMENT;
const whitelistP1 = environment === 'production' ? whitelist_dev_p1 : whitelist_dev_p1;

interface WhitelistP1 {
  [address: string]:
    | string
    | {
        signature: string;
        keysOfEachType: number[];
      };
}

const whitelistTypedP1: WhitelistP1 = whitelistP1;

// const KEY_NAMES = {
//   0: 'Silver Key',
//   1: 'Gold Key',
//   2: 'Platinum Key',
// } as const;

const KEY_NAMES = {
  0: 'Bronze Key',
  1: 'Silver Key',
  2: 'Gold Key',
  3: 'Platinum Key',
  4: 'Diamond Key',
} as const;

const ENCLAVE_KEY_NAMES = {
  0: 'Silver Key',
  1: 'Gold Key',
  2: 'Platinum Key',
  3: 'Silver Key',
  4: 'Gold Key',
  5: 'Platinum Key',
  6: 'Silver Key',
  7: 'Gold Key',
  8: 'Platinum Key',
  9: 'Silver Key',
  10: 'Gold Key',
  11: 'Platinum Key',
  12: 'Silver Key',
  13: 'Gold Key',
  14: 'Platinum Key',
} as const;
interface RewardEvent {
  user: string;
  requestId: string;
  reward: string;
}

function ProfileKey() {
  const [activeTab, setActiveTab] = useState('keys'); // Handle active tab
  const [contractCurrentSeason, setContractCurrentSeason] = useState<number | null>(null);

  const [activeSidebar, setActiveSidebar] = useState('avax');
  const [activeProject, setActiveProject] = useState<any>(null);
  const [selectedRequest, setSelectedRequest] = useState('');
  const { address, isConnected } = useAccount();
  const { address: addressMultiversX } = useGetAccountInfo();
  const [keyIds, setKeyIds] = useState<number[]>([]);
  const [keyIdsLoading, setKeyIdsLoading] = useState<boolean>(false);

  const { sendRequest } = useHttp();
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);

  const [isSuccessRedeemedModalOpen, setIsSuccessRedeemedModalOpen] = useState(false);
  const [isLoadingKeys, setLoadingKeys] = useState<boolean | null>(null);
  const [isLoadingAvaxKeys, setLoadingAvaxKeys] = useState<boolean | null>(null);
  const [claimWhitelistData, setClaimWhitelistData] = useState(null);

  const [blockchainKeyBalances, setBlockchainKeyBalances] = useState<Record<number, number>>({});
  const [avaxBlockchainKeyBalances, setAvaxBlockchainKeyBalances] = useState<
    Record<number, number>
  >({});
  const [enclaveBlockchainKeyBalances, setEnclaveBlockchainKeyBalances] = useState<
    Record<number, number>
  >({});

  const [walletData, setWalletData] =
    useState<{ address: string; keys: Record<number, number> } | null>(null); // Store wallet data

  const [redeemedRequestIds, setRedeemedRequestIds] = useState<string[]>([]);
  const [accessToken, setAccessToken] = useState('');
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const [collectionDetail, setCollectionDetail] = useState<any>();
  const [enclvaeCollectionDetail, setEnclaveCollectionDetail] = useState<any>();
  const [avaxCollectionDetail, setAvaxCollectionDetail] = useState<any>();
  const [sidebarItems, setSidebarItems] = useState([]);
  const [avaxKeysCountTotal, setAvaxKeysCountTotal] = useState(0);
  const [user, setUser] = useState<any>(null);

  const tabs: { value: string; label: string; icon: Icons }[] = [
    {
      value: 'keys',
      label: 'Keys',
      icon: 'keyS',
    },
    { value: 'rewards', label: 'Rewards', icon: 'giftS' },
  ];

  useEffect(() => {
    if (!collectionDetail) return;
    let whitelist;
    const stageOrder = collectionDetail?.stages?.active?.[0]?.order;
    switch (stageOrder) {
      case 1:
        whitelist = whitelistSeason1;
        break;
      // case 2:
      //   whitelist = whitelistSeason2;
      //   break;
      // case 3:
      //   whitelist = whitelistSeason3;
      //   break;
      // case 4:
      //   whitelist = whitelistSeason4;
      //   break;
      // case 5:
      //   whitelist = whitelistSeason5;
      //   break;
      default:
        whitelist = null;
    }

    if (address && whitelist) {
      setClaimWhitelistData(whitelist[address.toLowerCase()]);
    }
  }, [address, collectionDetail]);

  const getEnclaveKeyClaimStages = () => {
    sendRequest(
      {
        url: `collections/key-claiming-stages/enclave`,
        method: 'GET',
      },
      (data: any) => {
        setEnclaveCollectionDetail(data);
      },
    );
  };

  const getAvaxKeyClaimStages = () => {
    sendRequest(
      {
        url: `collections/key-claiming-stages/avax`,
        method: 'GET',
      },
      (data: any) => {
        setAvaxCollectionDetail(data);
      },
    );
  };

  const getCollectionDetail = () => {
    sendRequest(
      {
        url: `collections`,
        method: 'GET',
      },
      (data: any) => {
        setCollectionDetail(data);

        setActiveProject(data.filter((collection: any) => collection.slug === 'avax')[0]);
      },
    );
  };

  useEffect(() => {
    if (address || addressMultiversX || user?.twitterUsername) {
      getCollectionDetail();
      getEnclaveKeyClaimStages();
      getAvaxKeyClaimStages();
    }
  }, [address, addressMultiversX, user?.twitterUsername]);

  useEffect(() => {
    const jwtString = localStorage.getItem('jwt');
    if (jwtString) {
      const data = JSON.parse(jwtString);
      const accessToken = data.token;

      sendRequest(
        {
          url: `user/user-info`,
          accessToken,
        },
        (data: any) => {
          setUser(data);
        },
      );
    }
  }, []);

  // useEffect(() => {
  //   if (address) {
  //     getWalletKeysData();
  //     getRedeemedRequestsHandler();
  //   }
  // }, [address]);

  const handleCopyLink = () => {
    if (user) {
      const appUrl = `${window.location.origin}/referral?code=${user.referralCode}&referralApp=Mvx`; // The URL to be copied to clipboard
      navigator.clipboard
        .writeText(appUrl) // Copy the URL to clipboard
        .then(() => {
          setIsCopied(true); // Set copied status
          setTimeout(() => setIsCopied(false), 2000); // Reset copied status after 2 seconds
          toast.success('Link copied');
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
        });
    }
  };

  const getWalletKeysData = async () => {
    if (address) {
      sendRequest(
        {
          url: `get-wallet-data/${address}`, // Example endpoint
          method: 'GET',
        },
        async (response: any) => {
          setWalletData({ address: response.address, keys: response.keys });
        },
      );
    }
  };

  const getRedeemedRequestsHandler = async () => {
    if (address) {
      sendRequest(
        {
          url: `codes/redeemed/${address}`, // Example endpoint
          method: 'GET',
        },
        async (response: any) => {
          const requestIds = response.map((item: { requestId: string }) => item.requestId);
          setRedeemedRequestIds(requestIds);
        },
      );
    }
  };

  const parentSetRedemedRequest = (requestId: string, voucherNumber: string) => {
    setRedeemedRequestIds(prevIds => [...prevIds, requestId]);

    setRedeemedVouchers(prevState => ({
      ...prevState,
      [requestId]: voucherNumber, // Store voucherNumber with requestId as the key
    }));

    // setLoggedIn(true)
    // setAccessToken(token)
  };

  const copyToClipboard = (text: string, successMessage: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(successMessage);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  const fetchBlockchainKeyBalances = async () => {
    if (!address) return;

    const contractConfig = activeProject?.stages?.filter((stage: any) => stage.order === 1)[0]
      ?.metadata[environment];
    if (!contractConfig || Object.keys(contractConfig).length === 0) return;

    const updatedBlockchainKeys: Record<number, number> = {};
    setLoadingKeys(true);
    // Fetch the balance for each key (0 to 4)
    for (let keyIndex = 0; keyIndex < 3; keyIndex++) {
      const balance = await readKeyBalance(address, keyIndex, contractConfig);
      updatedBlockchainKeys[keyIndex] = balance;
    }
    setLoadingKeys(false);
    console.log('profile page updatedBlockchainKeys', updatedBlockchainKeys);

    setBlockchainKeyBalances(updatedBlockchainKeys);
  };

  const fetchEnclaveKeyBalances = async () => {
    if (!address) return;

    const contractConfig = enclvaeCollectionDetail?.stages?.active[0]?.metadata[environment];
    if (!contractConfig || Object.keys(contractConfig).length === 0) return;
    console.log('cccccccccccccccontractConfig', enclvaeCollectionDetail?.network.RPCUrl);
    setKeyIdsLoading(true);
    const provider = new ethers.providers.JsonRpcProvider(enclvaeCollectionDetail?.network.RPCUrl);
    const contract = new ethers.Contract(
      contractConfig.LOOTY_TOKEN_LOTTERY.address,
      contractConfig.LOOTY_TOKEN_LOTTERY.abi,
      provider,
    );
    const currentSeason = await contract.currentSeason();
    setContractCurrentSeason(currentSeason);
    let ids = [];
    if (currentSeason === 0) { // 0 means 1 when get from contract 
      ids = [0, 1, 2];
    } else if (currentSeason === 1) {
      ids = [3, 4, 5];
    } else if (currentSeason === 2) {
      ids = [6, 7, 8];
    } else if (currentSeason === 3) {
      ids = [9, 10, 11];
    } else if (currentSeason === 4) {
      ids = [12, 13, 14];
    }

    setKeyIds(ids);
    setKeyIdsLoading(false);

    const updatedBlockchainKeys: Record<number, number> = {};
    setLoadingKeys(true);

    for (const keyIndex of ids) {
      const balance = await readKeyBalance(address, keyIndex, contractConfig);
      updatedBlockchainKeys[keyIndex] = balance;
    }

    setLoadingKeys(false);
    console.log('profile page updatedBlockchainKeys', updatedBlockchainKeys);

    setEnclaveBlockchainKeyBalances(updatedBlockchainKeys);
  };

  const fetchAvaxKeyBalances = async () => {
    if (!address) return;

    const contractConfig = avaxCollectionDetail?.stages?.active[0]?.metadata[environment];
    if (!contractConfig || Object.keys(contractConfig).length === 0) return;

    setLoadingAvaxKeys(true);
    const ids = [0, 1, 2, 3, 4];
    setKeyIds(ids);
    console.log('LoadingAvaxKeys true');

    const updatedBlockchainKeys: Record<number, number> = {};

    for (const keyIndex of ids) {
      const balance = await readKeyBalance(address, keyIndex, contractConfig);
      updatedBlockchainKeys[keyIndex] = balance;
    }

    console.log('LoadingAvaxKeys false');
    setLoadingAvaxKeys(false);
    console.log('profile page updatedBlockchainKeys', updatedBlockchainKeys);

    setAvaxBlockchainKeyBalances(updatedBlockchainKeys);
  };

  useEffect(() => {
    if (address && isConnected && activeProject) {
      if (activeProject?.slug === 'arena') {
        fetchBlockchainKeyBalances();
      } else if (activeProject?.slug === 'enclave') {
        fetchEnclaveKeyBalances();
      } else if (activeProject?.slug === 'avax') {
        fetchAvaxKeyBalances();
      }
    }
  }, [address, isConnected, activeProject]);

  const readKeyBalance = async (walletAddress: string, keyIndex: number, config: any) => {
    try {
      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      console.log('RPC Profile', activeProject?.network.RPCUrl);
      const provider = new ethers.providers.JsonRpcProvider(activeProject?.network.RPCUrl);
      const contract = new ethers.Contract(
        config.LOOTY_KEY.address,
        config.LOOTY_KEY.abi,
        provider,
      );
      const balance = await contract.balanceOf(walletAddress, keyIndex);

      return Number(balance);
    } catch (error) {
      console.error('Error reading balance:', error);
      return 0;
    }
  };

  const redeemHandler = (requestId: string) => {
    setIsSuccessRedeemedModalOpen(true);
    setSelectedRequest(requestId);
  };

  const viewCOdeHandler = (requestId: string) => {
    setSelectedRequest(requestId);
    if (!loggedIn) signInWithMetaMask(requestId);
    else {
      redeemVoucherHandler(requestId, accessToken);
    }
  };

  const [redeemedVouchers, setRedeemedVouchers] = useState<{ [key: string]: string }>({});
  const redeemVoucherHandler = async (selectedRequest: string, token: string) => {
    sendRequest(
      {
        url: `codes/redeem/${selectedRequest}`, // Example endpoint
        method: 'PATCH',
        // payload: { selectedRequest },
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Api-Key': process.env.REACT_APP_API_KEY as string,
        },
      },
      async (response: any) => {
        setRedeemedVouchers(prevState => ({
          ...prevState,
          [selectedRequest]: response.voucherNumber, // Store voucherNumber with requestId as the key
        }));
      },
    );
  };

  const requestNonce = async (address: string) => {
    const response = await fetch('http://localhost:3000/auth/request-nonce', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ walletAddress: address }),
    });
    const data = await response.json();
    return data.nonce;
  };

  const verifySignature = async (address: string, signature: string) => {
    const response = await fetch('http://localhost:3000/auth/verify-signature', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ walletAddress: address, signature }),
    });
    const data = await response.json();
    return data;
  };

  const signInWithMetaMask = async (requestId: string) => {
    if (!window.ethereum) {
      alert('MetaMask not installed!');
      return;
    }

    const { ethereum } = window;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = await provider.getSigner();
    const address = await signer.getAddress();
    // setWalletAddress(address);

    // Request the nonce from the backend
    const nonce = await requestNonce(address);

    // Sign the exact nonce message
    const signature = await signer.signMessage(nonce);

    // Verify the signature with the backend
    const { success, token, id } = await verifySignature(address, signature);
    if (success) {
      setLoggedIn(true);
      // setIsViewCode(!isViewCode)
      setAccessToken(token);
      redeemVoucherHandler(requestId, token);
    }
  };

  const handleSidebarItem = (item: any) => {
    console.log('item', item);
    setActiveSidebar(item.slug);
    setActiveProject(item);
  };

  const renderContent = () => {
    if (activeSidebar === 'arena') {
      if (activeTab === 'keys') {
        return (
          <div className="w-full grid gap-[17px] grid-cols-[repeat(auto-fit,minmax(280px,1fr))] justify-between">
            {(() => {
              const ids = whitelistTypedP1[address?.toLowerCase()]?.ids;
              const totalKeyBalance = Object.values(blockchainKeyBalances).reduce(
                (sum, value) => sum + value,
                0,
              );

              console.log('idssss', ids);

              if (isLoadingKeys) {
                return (
                  <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 sm:h-[400px] lg:h-[400px] uppercase col-span-full">
                    <SyncLoader color="#B73FFF" size={10} />
                  </div>
                );
              } else if (totalKeyBalance !== 0) {
                return Object.entries(blockchainKeyBalances)
                  .filter(([keyIndex, value]) => value > 0)
                  .map(([keyIndex, balance], index) => (
                    <div key={index} className="p-5 rounded-md bg-grayscale-800">
                      <img
                        src={`/assets/images/arenaKeys/${keyIndex}.png`}
                        alt={`${KEY_NAMES[Number(keyIndex) as keyof typeof KEY_NAMES]}`}
                        className="rounded-md mb-[17px]"
                      />
                      <div className="space-y-[17px]">
                        <h5 className="uppercase text-brand-primary-light">
                          {KEY_NAMES[Number(keyIndex) as keyof typeof KEY_NAMES]}
                        </h5>
                        <div className="w-full border-b-2 border-grayscale-700 my-[17px]" />
                        <div className="flex justify-between items-center">
                          <div>
                            <div className="mb-[4px] text-xs text-grayscale-300">Ready to use:</div>
                            <div className="font-semibold text-grayscale-100">{balance ?? 0}</div>
                          </div>
                          <Button
                            onClick={() => navigate(`/arena/openbox/${activeProject?.id}`)}
                            size="sm"
                            type="primary"
                          >
                            Open box
                          </Button>
                        </div>
                      </div>
                    </div>
                  ));
              } else {
                return (
                  <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 min-h-[182px] sm:min-h-[400px] lg:min-h-[400px] uppercase col-span-full">
                    No keys found
                  </div>
                );
              }
            })()}
          </div>
        );
      } else {
        return (
          <div className="flex justify-start w-full">
            <ArenaRewardCard activeProject={activeProject} />
          </div>
        );
      }
    } else if (activeSidebar === 'avax') {
      if (activeTab === 'keys') {
        return (
          <div className="w-full grid gap-[17px] grid-cols-[repeat(auto-fit,minmax(280px,1fr))] justify-between">
            {(() => {
              if (isLoadingAvaxKeys) {
                return (
                  <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 sm:h-[400px] lg:h-[400px] uppercase col-span-full">
                    <SyncLoader color="#B73FFF" size={10} />
                  </div>
                );
              } else if (
                keyIds.length > 0 &&
                !isLoadingAvaxKeys &&
                Object.values(avaxBlockchainKeyBalances).reduce((acc, value) => acc + value, 0) > 0
              ) {
                return [0, 1, 2, 3, 4].map((keyIndex, index) => (
                  <div key={index} className="p-5 rounded-md bg-grayscale-800">
                    <img
                      src={`/assets/images/keys/${keyIndex}.png`}
                      alt={`${KEY_NAMES[keyIndex as keyof typeof KEY_NAMES] ?? `Key ${keyIndex}`}`}
                      className="rounded-md mb-[17px]"
                    />
                    <div className="space-y-[17px]">
                      <h5 className="uppercase text-brand-primary-light">
                        {KEY_NAMES[keyIndex as keyof typeof KEY_NAMES] ?? `Key ${keyIndex}`}
                      </h5>
                      <div className="w-full border-b-2 border-grayscale-700 my-[17px]" />
                      <div className="flex justify-between items-center">
                        <div>
                          <div className="mb-[4px] text-xs text-grayscale-300">Ready to use:</div>
                          <div className="font-semibold text-grayscale-100">
                            {isLoadingKeys ? (
                              <SyncLoader color="#B73FFF" size={5} />
                            ) : (
                              avaxBlockchainKeyBalances[keyIndex] ?? 0
                            )}
                          </div>
                        </div>
                        <Button
                          onClick={() => navigate(`/avax/openbox/${activeProject?.id}`)}
                          size="sm"
                          type="primary"
                          disabled={
                            isLoadingKeys || (avaxBlockchainKeyBalances[keyIndex] ?? 0) === 0
                          }
                        >
                          Open box
                        </Button>
                      </div>
                    </div>
                  </div>
                ));
              } else {
                return (
                  <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 min-h-[182px] sm:min-h-[400px] lg:min-h-[400px] uppercase col-span-full">
                    No keys found
                  </div>
                );
              }
            })()}
          </div>
        );
      } else {
        return (
          // <div className="w-full grid gap-[17px] grid-cols-[repeat(auto-fit,minmax(280px,1fr))] justify-between">

          <div className="w-full flex items-center justify-start gap-10 flex-wrap">
            <AvaxRewardCard activeProject={avaxCollectionDetail} />
          </div>
        );
      }
    } else if (activeSidebar === 'enclave') {
      if (activeTab === 'keys') {
        return (
          <div className="w-full grid gap-[17px] grid-cols-[repeat(auto-fit,minmax(280px,1fr))] justify-between">
            {(() => {
              if (keyIdsLoading) {
                return (
                  <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 sm:h-[400px] lg:h-[400px] uppercase col-span-full">
                    <SyncLoader color="#B73FFF" size={10} />
                  </div>
                );
              } else if (keyIds.length > 0) {
                return keyIds.map((keyIndex, index) => (
                  <div key={index} className="p-5 rounded-md bg-grayscale-800">
                    <img
                      src={`${
                        contractCurrentSeason === 0
                          ? `/assets/images/arenaKeys/${keyIndex}.png`
                          : `/assets/images/keys/s${Number(contractCurrentSeason) +1}/${keyIndex}.png`
                      }`}
                      alt={`${
                        ENCLAVE_KEY_NAMES[keyIndex as keyof typeof ENCLAVE_KEY_NAMES] ??
                        `Key ${keyIndex}`
                      }`}
                      className="rounded-md mb-[17px]"
                    />
                    <div className="space-y-[17px]">
                      <h5 className="uppercase text-brand-primary-light">
                        {ENCLAVE_KEY_NAMES[keyIndex as keyof typeof ENCLAVE_KEY_NAMES] ??
                          `Key ${keyIndex}`}
                      </h5>
                      <div className="w-full border-b-2 border-grayscale-700 my-[17px]" />
                      <div className="flex justify-between items-center">
                        <div>
                          <div className="mb-[4px] text-xs text-grayscale-300">Ready to use:</div>
                          <div className="font-semibold text-grayscale-100">
                            {isLoadingKeys ? (
                              <SyncLoader color="#B73FFF" size={5} />
                            ) : (
                              enclaveBlockchainKeyBalances[keyIndex] ?? 0
                            )}
                          </div>
                        </div>
                        <Button
                          onClick={() => navigate(`/enclave/openbox/${activeProject?.id}`)}
                          size="sm"
                          type="primary"
                          disabled={
                            isLoadingKeys || (enclaveBlockchainKeyBalances[keyIndex] ?? 0) === 0
                          }
                        >
                          Open box
                        </Button>
                      </div>
                    </div>
                  </div>
                ));
              } else {
                return (
                  <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 min-h-[182px] sm:min-h-[400px] lg:min-h-[400px] uppercase col-span-full">
                    No keys found
                  </div>
                );
              }
            })()}
          </div>
        );
      } else {
        return (
          <div className="flex justify-start w-full">
            <EnclaveRewardCard activeProject={enclvaeCollectionDetail} />
          </div>
        );
      }
    } else {
      return (
        <div className="w-full grid gap-[17px] 2xl:grid-cols-3 xl:grid-cols-2 lg:grid-cols-1 justify-between">
          <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 min-h-[182px] sm:min-h-[400px] lg:min-h-[400px] uppercase col-span-full">
            No {activeTab === 'keys' ? 'keys' : 'rewards'} found
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <SuccessRedeemedModal
        isOpen={isSuccessRedeemedModalOpen}
        selectedRequest={selectedRequest}
        onClose={() => setIsSuccessRedeemedModalOpen(false)}
        parentSetRedemedRequest={parentSetRedemedRequest}
      />

      <section className="mb-[36px] lg:mb-[51px]">
        <div className="flex justify-end items-center">
          {user?.isReferralPending && (
            <button
              onClick={handleCopyLink}
              className="flex items-center gap-2 p-2 lg:px-4 lg:py-[8px] text-sm rounded-md bg-grayscale-700 w-fit text-grayscale-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.5 2H7.50009H8.5H8.50009V4.00001H9.50003H10.4999H10.5V11H10.4999V11H3.49994V11V10V9.00005H1.5V9V8.00005V3V2H2.5ZM7.50009 4.00001V3H2.5V8.00005H3.49994V5.00001V4.00001H4.49994H7.50009ZM4.49994 9.00005V10H9.50003V5.00001H4.49994V8.00005H4.5V9.00005H4.49994Z"
                  fill="#EEE0F9"
                />
              </svg>
              <span className="hidden lg:block">Referral Link</span>
            </button>
          )}
        </div>
        <div className="flex flex-col justify-between lg:items-end lg:flex-row gap-[36px]">
          <div className="flex gap-6 items-center">
            <IconSvg icon="userPlaceholderXl" className="w-[60px] h-[60px] lg:w-fit lg:h-fit" />

            <div>
              <div className="mb-2 text-sm text-grayscale-400">My profile</div>

              <div className="flex gap-[15px] items-center">
                <h3 className="text-brand-primary-light">
                  {/* {address ? `${address.slice(0, 6)}...${address.slice(-4)}` : 'Not connected'} */}
                  {/* {user?.xportalWalletAddress
                    ? `${user.xportalWalletAddress.slice(0, 6)}...${user.xportalWalletAddress.slice(
                        -4,
                      )}`
                    : user?.walletAddress
                    ? `${user.walletAddress.slice(0, 6)}...${user.walletAddress.slice(-4)}`
                    : user?.twitterUsername
                    ? `@${user.twitterUsername}`
                    : 'Not connected'} */}
                  {address
                    ? `${address.slice(0, 6)}...${address.slice(-4)}`
                    : addressMultiversX
                    ? `${addressMultiversX.slice(0, 6)}...${addressMultiversX.slice(-4)}`
                    : user?.twitterUsername
                    ? `@${user.twitterUsername}`
                    : 'Not connected'}
                </h3>

                {address && (
                  <IconSvg
                    icon="copyM"
                    className="cursor-pointer text-grayscale-400"
                    onClick={() => copyToClipboard(address, 'Address copied to clipboard')}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="flex gap-6">
            <div>
              <div className="mb-2 text-xs lg:text-sm text-grayscale-400 lg:text-end">
                Total Keys
              </div>

              <div className="text-lg font-semibold text-grayscale-100 lg:text-end">
                {activeSidebar === 'arena' &&
                  Object.values(blockchainKeyBalances).reduce((acc, value) => acc + value, 0)}
                {activeSidebar === 'avax' &&
                  Object.values(avaxBlockchainKeyBalances).reduce((acc, value) => acc + value, 0)}
                {activeSidebar === 'enclave' &&
                  Object.values(enclaveBlockchainKeyBalances).reduce(
                    (acc, value) => acc + value,
                    0,
                  )}
              </div>
            </div>

            <div className="border-l-2 h-[59px] border-grayscale-700"></div>

            <div>
              <div className="mb-2 text-xs lg:text-sm text-grayscale-400 lg:text-end">
                Your Referrals
              </div>

              <div className="text-lg font-semibold text-grayscale-100 lg:text-end">
                {user?.referredUsersCount}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="flex px-5 py-3 mb-2.5 lg:mb-10 rounded-md bg-grayscale-800">
          <div className="flex gap-2">
            {tabs.map(tab => (
              <div
                key={tab.value}
                onClick={() => setActiveTab(tab.value)}
                className={`px-4 py-2 rounded cursor-pointer select-none flex items-center gap-2 ${
                  activeTab === tab.value ? 'bg-grayscale-600' : ''
                }`}
              >
                <IconSvg
                  icon={tab.icon}
                  className={activeTab === tab.value ? 'text-grayscale-200' : 'text-gray-300'}
                />

                <div
                  className={`text-xs lg:text-sm font-semibold ${
                    activeTab === tab.value ? 'text-grayscale-200' : 'text-gray-300'
                  }`}
                >
                  {tab.label}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-between gap-2.5 lg:gap-[18px] lg:flex-row flex-col">
          <div className="min-w-[387px] flex lg:flex-col gap-2.5">
            {collectionDetail &&
              collectionDetail?.map((item, idx) => {
                return (
                  (item.slug === 'avax' || item.slug === 'arena' || item.slug === 'enclave') && (
                    <div
                      key={idx}
                      onClick={() => {
                        handleSidebarItem(item);
                      }}
                      className={`py-[15px] px-[15px] lg:px-5 rounded-md bg-grayscale-800 flex gap-5 items-center min-w-fit ${
                        activeSidebar === item.slug ? 'border-2 border-brand-primary' : ''
                      } cursor-pointer`}
                    >
                      <div className="bg-grayscale-700 w-[48px] h-[48px] lg:w-[60px] lg:h-[60px] rounded-[5px] overflow-hidden">
                        <img src={item.logo} alt={item.name} className="w-full h-full" />
                      </div>

                      <h5 className="uppercase text-brand-primary-light max-w-[210px] truncate lg:block hidden">
                        {item.name}
                      </h5>
                    </div>
                  )
                );
              })}
          </div>

          {collectionDetail && renderContent()}
        </div>
      </section>
    </>
  );
}

export default ProfileKey;
