import { useEffect, useState } from 'react';
import { ListboxOptions, Listbox, ListboxButton, ListboxOption } from '@headlessui/react';
import IconSvg from './ui/IconSvg';
import { useAccount } from 'wagmi';
import { useHttp } from 'src/hooks';
import { Network } from './cards/types/types';
import { useGetAccountInfo, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks/account';
import { useWebWalletLogin } from '@multiversx/sdk-dapp/hooks';
import { disconnect } from '@wagmi/core';
import { config } from 'src/providers/config';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

type ChooseNetworkProps = {
  selectedNetwork: {
    id?: string;
    chainId?: number | null;
    networkCurrency?: string;
  } | null;
  setSelectedNetwork: React.Dispatch<
    React.SetStateAction<{
      id?: string;
      chainId?: number | null;
      networkCurrency?: string;
    } | null>
  >;
  handleLogOutMVX: () => void; // Pass slug to handle the click
};

const ChooseNetwork = ({
  selectedNetwork,
  setSelectedNetwork,
  handleLogOutMVX,
}: ChooseNetworkProps) => {
  const { address, isConnected } = useAccount();
  const { sendRequest } = useHttp();
  const restrictedRoutes = ['/leaderboards/multiversx'];
  const location = useLocation();
  const [showAvaxOption, setShowAvaxOption] = useState(true);

  const navigate = useNavigate();
  const [networks, setNetworks] = useState<Network[]>([]);
  const { isLoggedIn: isLoggedInMultiversX } = useGetLoginInfo();
  const { address: addressMultiversX } = useGetAccountInfo();

  const [initiateLogin] = useWebWalletLogin({
    callbackRoute: window.location.pathname,
    nativeAuth: false,
  });

  const getNetworks = () => {
    sendRequest(
      {
        url: 'networks',
        method: 'GET',
      },
      (data: any) => {
        setNetworks(data);
      },
    );
  };

  // Fetch networks when the address changes
  useEffect(() => {
    getNetworks();
  }, []);

  // const handleNetworkChange = (network: Network) => {
  //   setSelectedNetwork(network);
  //   if (network.networkCurrency.toLowerCase() === 'avax') {
  //     // Trigger Metamask login
  //     const openConnectModal = window.ethereum ? () => window.ethereum.enable() : null;
  //     openConnectModal && openConnectModal();
  //   } else if (network.networkCurrency.toLowerCase() === 'egld') {
  //     // Trigger MultiversX login
  //     initiateLogin();
  //   }
  // };
  const handleNetworkChange = async (network: Network) => {
    try {
      if (network.networkCurrency.toLowerCase() === 'avax') {
        // Trigger Metamask login
        if (window.ethereum) {
          await window.ethereum.enable();
          setSelectedNetwork(network);
        } else {
          toast.error(
            'Metamask is not detected. Please install the browser extension to proceed.',
            { autoClose: 8000 },
          );
        }
      } else if (network.networkCurrency.toLowerCase() === 'egld') {
        // Trigger MultiversX login
        setSelectedNetwork(network);
        if (isLoggedInMultiversX) {
          navigate('/leaderboards/multiversx');
          return;
        }
        initiateLogin();
      }
    } catch (error) {
      console.error('Connection rejected or failed:', error);
    }
  };

  const handleSignOut = async () => {
    const jwtString = localStorage.getItem('jwt');
    const data = JSON.parse(jwtString);
    if (data.isMvxLoggedIn && data.isMvxLoggedIn === true) {
      localStorage.setItem(
        'jwt',
        JSON.stringify({ token: data.token, isXLoggedIn: false, isMvxLoggedIn: true }),
      );
    } else {
      localStorage.removeItem('jwt');
    }

    await disconnect(config);
    setSelectedNetwork(null);
    navigate('/');
  };

  // useEffect(() => {
  //   if (
  //     isLoggedInMultiversX &&
  //     networks &&
  //     networks.length > 0 &&
  //     restrictedRoutes.includes(location.pathname)
  //   ) {
  //     const selectedNetwork = networks.find(nw => nw.networkCurrency === 'EGLD');
  //     console.log('useEffect eglddddddddddddddddd');
  //     setSelectedNetwork({ networkCurrency: selectedNetwork?.networkCurrency });
  //     return;
  //   }

  //   if (address && !restrictedRoutes.includes(location.pathname) && networks && networks.length > 0) {
  //     console.log('useEffect address');
  //     const selectedNetwork = networks.find(nw => nw.networkCurrency === 'AVAX');
  //     setSelectedNetwork({ networkCurrency: selectedNetwork?.networkCurrency });
  //     return;
  //   }
  //   setSelectedNetwork(null);

  // }, [isLoggedInMultiversX, networks, location.pathname, address]);

  useEffect(() => {
    if (networks && networks.length > 0) {
      if (restrictedRoutes.includes(location.pathname)) {
        // Case 1: On a restricted route (/leaderboards/multiversx)
        if (isLoggedInMultiversX) {
          const selectedNetwork = networks.find(nw => nw.networkCurrency === 'EGLD');
          if (selectedNetwork) {
            console.log('useEffect: EGLD selected on restricted route');
            setSelectedNetwork({ networkCurrency: selectedNetwork.networkCurrency });
            return;
          }
        }
      } else {
        // Case 2: On other routes (e.g., Home, Profile)
        if (address) {
          const selectedNetwork = networks.find(nw => nw.networkCurrency === 'AVAX');
          if (selectedNetwork) {
            console.log('useEffect: AVAX selected on other routes');
            setSelectedNetwork({ networkCurrency: selectedNetwork.networkCurrency });
            return;
          }
        }
      }

      // Case 3: If no AVAX connection but MultiversX is connected, default to EGLD
      if (isLoggedInMultiversX) {
        const selectedNetwork = networks.find(nw => nw.networkCurrency === 'EGLD');
        if (selectedNetwork) {
          console.log('useEffect: EGLD selected as fallback');
          setSelectedNetwork({ networkCurrency: selectedNetwork.networkCurrency });
          return;
        }
      }
    }

    // Case 4: No connection or networks available
    console.log('useEffect: No network selected');
    setSelectedNetwork(null);
  }, [isLoggedInMultiversX, networks, location.pathname, address]);

  useEffect(() => {
    const handleResize = () => {
      // setShowAvaxOption(window.innerWidth >= 1024);
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSignOutMetaMask = async () => {
    console.log('disconnecting');
    await disconnect(config);
    setSelectedNetwork(null);

    const jwtString = localStorage.getItem('jwt');
    const jdata = JSON.parse(jwtString);
    console.log('while metamask logout', jdata);

    localStorage.setItem(
      'jwt',
      JSON.stringify({
        token: jdata.token,
        isMLoggedIn: false,
        isXLoggedIn: jwtString && jdata.isXLoggedIn === true,
        isMvxLoggedIn: jwtString && jdata.isMvxLoggedIn === true,
      }),
    );

    if(!jdata.isXLoggedIn && !jdata.isMvxLoggedIn) {
      localStorage.removeItem('jwt')
    }
    window.location.href = '/';
  };
  return (
    <>
      <Listbox value={selectedNetwork} onChange={handleNetworkChange}>
        <ListboxButton
          className={`flex justify-between items-center space-x-2 rounded-md group lg:bg-grayscale-800 bg-grayscale-900 lg:rounded-[11px] data-[open]:rounded-b-[0px] h-fit ${
            selectedNetwork?.networkCurrency ? 'w-[114px] px-2 py-0.5' : 'px-4 py-2'
          } `}
        >
          <div className="text-base font-semibold text-center cursor-pointer text-grayscale-200">
            {selectedNetwork?.networkCurrency ? (
              <div className="inline-flex gap-1 items-center">
                {selectedNetwork?.networkCurrency?.toLowerCase() === 'egld' && (
                  <IconSvg icon={'egldS'} />
                )}
                {selectedNetwork?.networkCurrency?.toLowerCase() === 'avax' && (
                  <IconSvg icon={'avaxS'} />
                )}

                <span className="text-xs text-grayscale-200 leading-[16px]">
                  {selectedNetwork?.networkCurrency}
                </span>
              </div>
            ) : (
              'Connect wallet'
            )}
          </div>

          <IconSvg
            icon="arrowDownS"
            className="text-grayscale-200 group-data-[open]:rotate-180 transition-all duration-300"
          />
        </ListboxButton>

        <ListboxOptions
          anchor={{ to: 'bottom', gap: -3 }}
          className={`flex overflow-hidden flex-col cursor-pointer z-[60] group w-[var(--button-width)] lg:bg-grayscale-800 bg-grayscale-900 rounded-[11px] data-[open]:rounded-t-[0px]`}
        >
          {networks
            .filter(
              network =>
                network.networkCurrency.toLowerCase() === 'egld' ||
                (network.networkCurrency.toLowerCase() === 'avax' && showAvaxOption),
              //  && !restrictedRoutes.includes(location.pathname)),
            )
            .reverse()
            .map(network => (
              <ListboxOption
                key={network.id}
                value={network}
                disabled={
                  network.networkCurrency.toLowerCase() === 'avax' && window.innerWidth < 1024
                }
                className={`flex gap-1 items-center space-x-1 ${
                  selectedNetwork?.networkCurrency === network.networkCurrency
                    ? 'px-2 py-2'
                    : 'px-2 py-2'
                }`}
              >
                {network.networkCurrency?.toLowerCase() === 'egld' && <IconSvg icon={'egldS'} />}
                {network.networkCurrency?.toLowerCase() === 'avax' && <IconSvg icon={'avaxS'} />}
                <div className="text-xs text-grayscale-400 flex space-x-2 items-center">
                  <div>{network.networkCurrency}</div>
                  {network.networkCurrency?.toLowerCase() === 'avax' &&
                    window.innerWidth < 1024 && (
                      <div className="text-[10px] text-yellow-400">(Desktop only)</div>
                    )}
                </div>

                {/* <span className="text-xs text-grayscale-400">{network.networkCurrency}</span> */}
              </ListboxOption>
            ))}
            {/* <ListboxOption
              key="btc"
              value="btc"
              onClick={() => window.open('https://omegaone.io/', '_blank')}
              className="flex gap-1 space-x-1 items-center px-2 py-2 cursor-pointer"
            >
              <IconSvg icon={'btcS'} />
              <div className="text-xs text-grayscale-400 flex space-x-2 items-center">
                <div>BTC</div>
              </div>
            </ListboxOption> */}

        </ListboxOptions>
      </Listbox>

      {isLoggedInMultiversX && addressMultiversX && (
        <Listbox value={null}>
          <ListboxButton className="group px-4 flex justify-between items-center space-x-2 py-2 rounded-[11px] lg:bg-grayscale-800 bg-grayscale-900 data-[open]:rounded-b-[0px]">
            <div className="font-semibold text-center cursor-pointer text-grayscale-200">
              {addressMultiversX?.slice(0, 6)}...{addressMultiversX?.slice(-4)}
            </div>
          </ListboxButton>

          <ListboxOptions
            anchor={{ to: 'bottom', gap: -3 }}
            className={`flex z-50 flex-col cursor-pointer group w-[var(--button-width)] bg-grayscale-800 rounded-[11px] data-[open]:rounded-t-[0px]`}
          >
            <ListboxOption
              className="px-4 py-2 cursor-pointer"
              onClick={() => {
                handleLogOutMVX(); // Logout MultiversX
              }}
            >
              <span className="text-xs text-grayscale-200">Logout MultiversX</span>
            </ListboxOption>
          </ListboxOptions>
        </Listbox>
      )}
      {address && (
        // && !restrictedRoutes.includes(location.pathname)
        <Listbox value={null}>
          <ListboxButton className="group px-4 flex justify-between items-center space-x-2 py-2 rounded-[11px] lg:bg-grayscale-800 bg-grayscale-900 data-[open]:rounded-b-[0px]">
            <div className="font-semibold text-center cursor-pointer text-grayscale-200">
              {address?.slice(0, 6)}...{address?.slice(-4)}
            </div>
          </ListboxButton>

          <ListboxOptions
            anchor={{ to: 'bottom', gap: -3 }}
            className={`flex z-50 flex-col cursor-pointer group w-[var(--button-width)] bg-grayscale-800 rounded-[11px] data-[open]:rounded-t-[0px]`}
          >
            <ListboxOption
              className="px-4 py-2 cursor-pointer"
              onClick={() => {
                handleSignOutMetaMask();
              }}
            >
              <span className="text-xs text-grayscale-200">Logout Metamask</span>
            </ListboxOption>
          </ListboxOptions>
        </Listbox>
      )}
    </>
  );
};

export default ChooseNetwork;
