import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import SyncLoader from 'react-spinners/SyncLoader';
import { useAccount } from 'wagmi';

interface Props {
  activeProject: any;
}

const environment = process.env.REACT_APP_ENVIRONMENT;

function EnclaveRewardCard({ activeProject }: Props) {
  const { address } = useAccount();
  const [balanceOfArenaToken, setBalanceOfArenaToken] = useState<string>('0');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchBalance = async () => {
    
  
      const phase1Config = activeProject?.stages?.active[0]?.metadata[environment] || activeProject?.stages?.expired[0]?.metadata[environment];

      if(!phase1Config) return;

      try {
        setLoading(true);
        const provider = new ethers.providers.JsonRpcProvider(activeProject?.network.RPCUrl);
  
        const contract1 = new ethers.Contract(
          phase1Config.LOOTY_TOKEN_LOTTERY.address,
          phase1Config.LOOTY_TOKEN_LOTTERY.abi,
          provider,
        );
        const eventFilter = contract1.filters.Claimed(address);
        const fromBlock = 1;
        const toBlock = 'latest';
  
        const events = await contract1.queryFilter(eventFilter, fromBlock, toBlock);
  
        console.log('Fetched Events:', events);
  
        // Correct summation using BigNumber
        const totalRewardBalance = events.reduce((sum, event) => {
          const amount = event.args?.amount; // Ensure correct access to the event arguments
          if (amount) {
            return sum.add(amount); // Use BigNumber addition
          }
          return sum;
        }, ethers.BigNumber.from(0)); // Initialize sum as BigNumber(0)
  
        // Format the balance for readability
        const formattedBalance = ethers.utils.formatUnits(totalRewardBalance, 6); // Adjust decimals as needed
        console.log('Total Reward Balance:', formattedBalance);
  
        setBalanceOfArenaToken(formattedBalance);
      } catch (error) {
        console.error('Error fetching balance:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchBalance();
  }, [activeProject, address, environment]);
  


  return (
    <>
      {loading ? (
        <div className="border-2 border-grayscale-700 p-5 w-full rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 min-h-[182px] sm:min-h-[400px] lg:min-h-[400px] uppercase col-span-full">
          <SyncLoader color="#B73FFF" size={10} />
        </div>
      ) : Number(balanceOfArenaToken) > 0 ? (
        <div className="p-5 rounded-md bg-grayscale-800 space-y-[17px] max-w-[280px]">
          <img src="/assets/images/enclave-reward-card.png" alt="" className="w-auto" />

          <div className="py-3 rounded-md bg-grayscale-900">
            <h5 className="text-lg font-normal text-center text-brand-primary-light">
              {loading ? 'Loading...' : `${balanceOfArenaToken} USDT`}
            </h5>
          </div>
        </div>
      ) : (
        <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 min-h-[182px] sm:min-h-[400px] lg:min-h-[400px] uppercase w-full">
          No rewards found
        </div>
      )}
    </>
  );
}

export default EnclaveRewardCard;
