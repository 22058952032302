import { ethers, utils } from 'ethers';
import { useEffect, useState } from 'react';
import SyncLoader from 'react-spinners/SyncLoader';
import { useHttp } from 'src/hooks';
import { getArenaBalanceOf } from 'src/utilities/looty';
import { useAccount } from 'wagmi';

interface Props {
  activeProject: any;
}

const environment = process.env.REACT_APP_ENVIRONMENT;

function ArenaRewardCard({ activeProject }: Props) {
  const { address } = useAccount();
  const [detailOpen, setDetailOpen] = useState(false);
  const [balanceOfArenaToken, setBalanceOfArenaToken] = useState<string>('0');
  const [loading, setLoading] = useState<boolean>(true);
  const { sendRequest } = useHttp();
  const [collection, setCollection] = useState<any>();

  useEffect(() => {
    const fetchBalance = async () => {
      if (activeProject?.slug !== 'arena') {
        return;
      }
  
      const phase1Config = activeProject?.stages?.filter((stage: any) => stage.order === 1)[0]
        .metadata[environment];
      try {
        setLoading(true);
        const provider = new ethers.providers.JsonRpcProvider(activeProject?.network.RPCUrl);
  
        const contract1 = new ethers.Contract(
          phase1Config.LOOTY_TOKEN_LOTTERY.address,
          phase1Config.LOOTY_TOKEN_LOTTERY.abi,
          provider,
        );
        const eventFilter = contract1.filters.Claimed(address);
        const fromBlock = 1;
        const toBlock = 'latest';
  
        const events = await contract1.queryFilter(eventFilter, fromBlock, toBlock);
  
        console.log('Fetched Events:', events);
  
        // Correct summation using BigNumber
        const totalRewardBalance = events.reduce((sum, event) => {
          const amount = event.args?.amount; // Ensure correct access to the event arguments
          if (amount) {
            return sum.add(amount); // Use BigNumber addition
          }
          return sum;
        }, ethers.BigNumber.from(0)); // Initialize sum as BigNumber(0)
  
        // Format the balance for readability
        const formattedBalance = ethers.utils.formatUnits(totalRewardBalance, 18); // Adjust decimals as needed
        console.log('Total Reward Balance:', formattedBalance);
  
        setBalanceOfArenaToken(formattedBalance);
      } catch (error) {
        console.error('Error fetching balance:', error);
      } finally {
        setLoading(false);
      }
    };
  
    if (activeProject?.slug === 'arena') fetchBalance();
  }, [activeProject, address, environment]);
  

  const getCollectionDetailById = () => {
    sendRequest(
      {
        url: `collections/${activeProject?.id}`,
        method: 'GET',
      },
      (data: any) => {
        setCollection(data);
      },
    );
  };

  useEffect(() => {
    getCollectionDetailById();
  }, [activeProject?.id]);

  const toggleDetail = () => {
    setDetailOpen(!detailOpen);
  };

  return (
    <>
      {loading ? (
        <div className="border-2 border-grayscale-700 p-5 w-full rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 min-h-[182px] sm:min-h-[400px] lg:min-h-[400px] uppercase col-span-full">
          <SyncLoader color="#B73FFF" size={10} />
        </div>
      ) : Number(balanceOfArenaToken) > 0 ? (
        <div className="p-5 rounded-md bg-grayscale-800 space-y-[17px]">
          <img src="/assets/images/arena-reward-card.png" alt="" className="w-full" />

          <div className="py-3 rounded-md bg-grayscale-900">
            <h5 className="text-lg font-normal text-center text-brand-primary-light">
              {loading ? 'Loading...' : `${balanceOfArenaToken} Arena`}
            </h5>
          </div>
        </div>
      ) : (
        <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 min-h-[182px] sm:min-h-[400px] lg:min-h-[400px] uppercase w-full">
          No rewards found
        </div>
      )}

      {/* {detailOpen && (
        <div className="w-full h-fit">
          <div className="text-lg text-grayscale-300 font-bold flex items-center gap-[17px] mb-8">
            <div className="cursor-pointer" onClick={toggleDetail}>
              The Arena rewards
            </div>

            <IconSvg icon="arrowRightM" className="text-grayscale-300" />

            <div>3ttWr...4rfwe</div>
          </div>

          <div className="flex justify-between items-center mb-10">
            <div className="flex gap-6 items-center">
              <div className="w-[109px] h-[109px] rounded-md">
                <img src="/assets/images/arena-logo.png" alt="" className="w-full h-full" />
              </div>

              <div className="space-y-1">
                <div className="text-sm leading-none text-grayscale-500">UNLOCKED</div>

                <h4 className="uppercase text-brand-primary-light">0 the arena</h4>

                <div className="flex gap-1 text-grayscale-200">
                  <div>37.500 The Arena</div>

                  <div>
                    (<span className="underline text-brand-primary">ty2E1...uue8W</span>)
                  </div>
                </div>
              </div>
            </div>

            <Button size="m" type="disabled">
              Claim
            </Button>
          </div>

          <div className="flex gap-4 justify-between mb-10">
            <img src="/assets/images/profile-graph.png" alt="" />

            <div className="space-y-10 w-[30%]">
              <div className="space-y-2">
                <div className="text-xs text-grayscale-400">Type</div>

                <div className="text-[24px] text-grayscale-100">Vesting</div>
              </div>

              <div className="space-y-2">
                <div className="text-xs text-grayscale-400">Start time</div>

                <div className="text-[24px] text-grayscale-100">
                  November 15 2024, <span className="text-grayscale-500">1:34pm</span>
                </div>
              </div>

              <div className="space-y-2">
                <div className="text-xs text-grayscale-400">End time</div>

                <div className="text-[24px] text-grayscale-100">
                  December 15 2024, <span className="text-grayscale-500">1:34pm</span>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-10 w-full border-b border-grayscale-700"></div>

          <div className="grid gap-10 grid-cols-[repeat(auto-fit,minmax(341px,1fr))]">
            <div className="space-y-2">
              <div className="text-xs text-grayscale-400">Claimed</div>

              <div className="text-[24px] text-grayscale-100 flex items-center gap-2">
                <img
                  src="/assets/images/arena-logo.png"
                  alt=""
                  className="w-6 h-6 bg-center rounded-full"
                />

                <span>0 ARENA ($0)</span>
              </div>
            </div>

            <div className="space-y-2">
              <div className="text-xs text-grayscale-400">Unlocked</div>

              <div className="text-[24px] text-grayscale-100 flex items-center gap-2">
                <img
                  src="/assets/images/arena-logo.png"
                  alt=""
                  className="w-6 h-6 bg-center rounded-full"
                />

                <span>0 ARENA ($0)</span>
              </div>
            </div>

            <div className="space-y-2">
              <div className="text-xs text-grayscale-400">Total</div>

              <div className="text-[24px] text-grayscale-100 flex items-center gap-2">
                <img
                  src="/assets/images/arena-logo.png"
                  alt=""
                  className="w-6 h-6 bg-center rounded-full"
                />

                <div className="text-[24px] text-grayscale-100">
                  37.500 ARENA <span className="text-grayscale-500">($261.49)</span>
                </div>
              </div>
            </div>

            <div className="space-y-2">
              <div className="text-xs text-grayscale-400">Unlock rate</div>

              <div className="text-[24px] text-grayscale-100">Bi-monthly</div>
            </div>

            <div className="space-y-2">
              <div className="text-xs text-grayscale-400">Unlock per bi-monthly</div>

              <div className="text-[24px] text-grayscale-100 flex items-center gap-2">
                <img
                  src="/assets/images/arena-logo.png"
                  alt=""
                  className="w-6 h-6 bg-center rounded-full"
                />

                <div className="text-[24px] text-grayscale-100">
                  9375 ARENA <span className="text-grayscale-500">($65.37)</span>
                </div>
              </div>
            </div>

            <div className="space-y-2">
              <div className="text-xs text-grayscale-400">Next unlock</div>

              <div className="text-[24px] text-grayscale-100 flex items-center gap-2">
                <img
                  src="/assets/images/arena-logo.png"
                  alt=""
                  className="w-6 h-6 bg-center rounded-full"
                />

                <div className="text-[24px] text-grayscale-100">
                  November 30 2024, <span className="text-grayscale-500">1:34pm</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}

export default ArenaRewardCard;
