import IconSvg, { Icons } from 'src/components/common/ui/IconSvg';
import { StackedCarousel, ResponsiveContainer } from 'react-stacked-center-carousel';
import React, { useEffect, useRef, useState } from 'react';
import { slideProp } from 'react-stacked-center-carousel/dist/interfaces';
import { ethers } from 'ethers';
import { useNavigate, useParams } from 'react-router-dom';
import { useHttp } from 'src/hooks';
import { Collection } from 'src/components/common/cards/types/types';
import { useAccount, useSwitchChain, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import { toast } from 'react-toastify';
import Button from 'src/components/button/Button';
import { rewardEnclaveCardData } from 'src/dummydata';
import EnclaveBanner from './components/EnclaveBanner';
import RewardCard from 'src/components/common/cards/RewardCard';
import SyncLoader from 'react-spinners/SyncLoader';
import EnclaveClaimNftLotteryModal from './components/EnclaveClaimNftLotteryModal';

const environment = process.env.REACT_APP_ENVIRONMENT;
// const KEY_NAMES = {
//   0: 'Bronze Key',
//   1: 'Silver Key',
//   2: 'Gold Key',
//   3: 'Platinum Key',
//   4: 'Diamond Key',
// } as const;

const KEY_NAMES = {
  0: 'Silver Key',
  1: 'Gold Key',
  2: 'Platinum Key',
  3: 'Silver Key',
  4: 'Gold Key',
  5: 'Platinum Key',
  6: 'Silver Key',
  7: 'Gold Key',
  8: 'Platinum Key',
  9: 'Silver Key',
  10: 'Gold Key',
  11: 'Platinum Key',
  12: 'Silver Key',
  13: 'Gold Key',
  14: 'Platinum Key',
} as const;

const data = rewardEnclaveCardData.map((reward, index) => {
  return {
    cover: reward.image,
    name: reward.name,
  };
});

interface RewardEvent {
  user: string;
  requestId: string;
  nftId?: string;
}

function EnclaveOpenbox() {
  const { address, isConnected, chainId } = useAccount();
  const { switchChain } = useSwitchChain();

  const { sendRequest } = useHttp();
  const { collectionName } = useParams();
  const [keyIds, setKeyIds] = useState<number[]>([]);

  const [collectionDetail, setCollectionDetail] = useState<Collection>();
  const [selectedKey, setSelectedKey] = useState<number | null>(null);
  const [tokenClaimedBalance, setTokenClaimedBalance] = useState<any>(null);

  const [userLotteryData, setUserLotteryData] = useState<any>(null);
  const [isClaimingNft, setIsClaimingNft] = useState(false);

  const [isPauseNftContract, setIsPauseNftContract] = useState<boolean | null>(null);
  const [contractCurrentSeason, setContractCurrentSeason] = useState<number | null>(null);

  const [isLoadingKeys, setLoadingKeys] = useState<boolean | null>(null);

  const [isRerolling, setIsRerolling] = useState(false);

  const [isRerollingNft, setIsRerollingNft] = useState(false);

  const [loadingOpenBoxBtn, setLoadingOpenBoxBtn] = useState(false);

  const [blockchainKeyBalances, setBlockchainKeyBalances] = useState<Record<number, number>>({});

  const stackedCarouselRef = useRef<StackedCarousel>(); // Ref to StackedCarousel
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0); // Keep track of active slide
  const [spinning, setSpinning] = useState<boolean>(false); // Track if the carousel is spinning
  const spinIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [selectedNft, setSelectedNft] =
    useState<{ cover: string; name: string; credits: number } | null>(null);

  const {
    data: hashOpenbox,
    writeContract: openLootboxContractWrite,
    isPending: isOpening,
    error: errorLootbox,
    isError: isError,
  } = useWriteContract();
  const {
    isLoading: isConfirmingOpenBox,
    isSuccess: isConfirmedOpenBox,
    data: receiptOpenBox,
    error: errorLootBoxTxnReceipt,
  } = useWaitForTransactionReceipt({
    hash: hashOpenbox,
  });

  const {
    data: hashClaimNft,
    writeContract: claimNftContractWrite,
    isPending: isOpeningNftLottery,
    isError: isErrorClaimNft,
  } = useWriteContract();

  const {
    isLoading: isConfirmingClaimNft,
    isSuccess: isConfirmedClaimNft,
    data: receiptClaimNft,
  } = useWaitForTransactionReceipt({
    hash: hashClaimNft,
  });

  useEffect(() => {
    console.log('isConfirmedClaimNft', isConfirmedClaimNft);
    console.log('isConfirmingClaimNft', isConfirmingClaimNft);
    console.log('receiptClaimNft', receiptClaimNft);
    console.log('isErrorClaimNft', isErrorClaimNft);
  }, [isConfirmingClaimNft, isConfirmedClaimNft]);

  const {
    data: hashReroll,
    writeContract: RerollContractWrite,
    isPending: isPendingReroll,
    isError: isErrorReroll,
  } = useWriteContract();

  const {
    isLoading: isConfirmingReroll,
    isSuccess: isConfirmedReroll,
    data: receiptReroll,
  } = useWaitForTransactionReceipt({
    hash: hashReroll,
  });

  useEffect(() => {
    console.log('================================');
    console.log('isConfirmingReroll', isConfirmingReroll);
    console.log('isConfirmedReroll', isConfirmedReroll);
    console.log('receiptReroll', receiptReroll);
    console.log('isPendingReroll', isPendingReroll);
    console.log('isErrorReroll', isErrorReroll);
    console.log('================================');
  }, [isConfirmedReroll, isConfirmingReroll]);

  const {
    data: hashSetApprovedForAll,
    writeContract: writeApprovelForAll,
    isPending: isPendingApprovalForAll,
  } = useWriteContract();

  const {
    isLoading: isConfirmingApprovalForAll,
    isSuccess: isConfirmedApprovalForAll,
    data: receiptApprovalForAll,
  } = useWaitForTransactionReceipt({
    hash: hashSetApprovedForAll,
  });

  useEffect(() => {
    console.log('isConfirmedOpenBox', isConfirmedOpenBox);
    console.log('isConfirmingOpenBox', isConfirmingOpenBox);
    console.log('errorLootbox', errorLootbox);
    console.log('errorLootBoxTxnReceipt', errorLootBoxTxnReceipt);

    console.log('receiptOpenBox', receiptOpenBox);
    console.log('isError', isError);
    if (isConfirmedOpenBox && receiptOpenBox) {
      getUserData(); // Update user data after the transaction
      // handleOpenBox();
    }
    if (isError || errorLootBoxTxnReceipt) {
      setLoadingOpenBoxBtn(false); // Stop loader on error
      stopSpin(0);
      toast.error('Execution reverted for an unknown reason.');
    }

    console.log('isErrorReroll', isErrorReroll);
    if (isErrorReroll) {
      setLoadingOpenBoxBtn(false); // Stop loader on error
      setIsRerollingNft(false);
    }

    console.log('isErrorClaimNft', isErrorClaimNft);
    if (isErrorClaimNft) {
      setLoadingOpenBoxBtn(false); // Stop loader on error
      setIsClaimingNft(false);
    }
  }, [
    isConfirmingOpenBox,
    isConfirmedOpenBox,
    receiptOpenBox,
    isError,
    isErrorReroll,
    errorLootBoxTxnReceipt,
    isErrorClaimNft,
  ]);

  useEffect(() => {
    if (address && isConnected && collectionDetail) {
      getUserData();
      fetchBlockchainKeyBalances();
    }
  }, [collectionDetail, address, isConnected]);

  useEffect(() => {
    if (address && isConnected) {
      getCollectionDetail();
    }
  }, [address, isConnected, collectionName, chainId]);

  const handleCardClick = (index: number) => {
    setSelectedKey(index);
  };

  const getCollectionDetail = async () => {
    await sendRequest(
      {
        url: `collections/key-claiming-stages/enclave`,
        method: 'GET',
      },
      (data: any) => {
        console.log('data', data);
        setCollectionDetail(data);
        switchChain({ chainId: Number(data?.network.chainId) });
      },
    );
  };

  const fetchBlockchainKeyBalances = async () => {
    if (!address) return;

    const contractConfig = collectionDetail?.stages?.active[0]?.metadata[environment];
    if (!contractConfig) return;

    const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
    const contract = new ethers.Contract(
      contractConfig.LOOTY_TOKEN_LOTTERY.address,
      contractConfig.LOOTY_TOKEN_LOTTERY.abi,
      provider,
    );
    const currentSeason = await contract.currentSeason();
    console.log('cccccurrentSeason', currentSeason)

    let ids = [];
    if (currentSeason === 0) { // 0 means 1 when get from contract 
      ids = [0, 1, 2];
    } else if (currentSeason === 1) {
      ids = [3, 4, 5];
    } else if (currentSeason === 2) {
      ids = [6, 7, 8];
    } else if (currentSeason === 3) {
      ids = [9, 10, 11];
    } else if (currentSeason === 4) {
      ids = [12, 13, 14];
    }

    setKeyIds(ids);

    const updatedBlockchainKeys: Record<number, number> = {};
    setLoadingKeys(true);

    for (const keyIndex of ids) {
      const balance = await readKeyBalance(address, keyIndex, contractConfig);
      console.log('aaaaaaaaaaaaa', keyIndex);
      updatedBlockchainKeys[keyIndex] = balance;
    }

    setLoadingKeys(false);

    console.log('updatedBlockchainKeys', updatedBlockchainKeys);

    setBlockchainKeyBalances(updatedBlockchainKeys);
  };

  const readKeyBalance = async (walletAddress: string, keyIndex: number) => {
    const contractConfig = collectionDetail?.stages?.active[0]?.metadata[environment];
    console.log('readKeyBalance contractConfig', contractConfig);

    try {
      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);

      const contract = new ethers.Contract(
        contractConfig.LOOTY_KEY.address,
        contractConfig.LOOTY_KEY.abi,
        provider,
      );
      const balance = await contract.balanceOf(walletAddress, keyIndex);
      console.log(`keyBalance ${Number(balance)} || keyIndex: ${keyIndex}`);
      return Number(balance);
    } catch (error) {
      console.error('Error reading balance:', error);
      return 0;
    }
  };

  const getUserData = async () => {
    try {
      const contractConfig = collectionDetail?.stages?.active[0]?.metadata[environment];
      if(!contractConfig) return;
      console.log('collectionDetail?.network', collectionDetail?.network)
      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
      const contract = new ethers.Contract(
        contractConfig.LOOTY_TOKEN_LOTTERY.address,
        contractConfig.LOOTY_TOKEN_LOTTERY.abi,
        provider,
      );
      console.log('contractcontractConfig', contractConfig)
      console.log('contract', contract)
      const contractCurrentSeason = await contract.currentSeason();
      console.log('contractCurrentSeason', contractCurrentSeason)
      setContractCurrentSeason(contractCurrentSeason);
      const userData = await contract.userLotteryData(address);
      const isPause = await contract.paused();
      console.log('userLotteryData', userData);
      setIsPauseNftContract(isPause);
      setUserLotteryData(userData);
      if (userLotteryData?.state === 2) setTokenClaimedBalance(userData?.candidateTokenAmount);
    } catch (error) {
      console.error('Error reading balance:', error);
    }
  };

  const openLootbox = async (nKeys: number) => {
    const phase1Config = collectionDetail?.stages?.active[0]?.metadata[environment];

    if (!isConnected) {
      toast.error('Please connect your wallet before opening the box.');
      return;
    }

    if (selectedKey === null) {
      toast.error('Please select a key to open the box.');
      return;
    }
    console.log('selectedKey', selectedKey);
    console.log('blockchainKeyBalances', blockchainKeyBalances);
    if (blockchainKeyBalances[selectedKey] === 0) {
      toast.error('No keys available to open this box.');
      return;
    }

    if (isPauseNftContract) {
      toast.error('Contract is Paused, contact admin');
      return;
    }

    try {
      console.log(`startLottery.... ${selectedKey}...`);
      startSpin(); // Start the carousel spin

      setLoadingOpenBoxBtn(true);
      await openLootboxContractWrite({
        address: phase1Config.LOOTY_TOKEN_LOTTERY.address,
        abi: phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        functionName: 'startLottery',
        args: [selectedKey, nKeys],
      });
    } catch (error) {
      setLoadingOpenBoxBtn(false);
      stopSpin(0);
      console.log('Error opening Lootbox:', error);
      toast.error('Failed to open Lootbox.');
    }
  };

  useEffect(() => {
    if (collectionDetail) {
      const contractConfig = collectionDetail?.stages?.active[0]?.metadata[environment];

      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
      const contract = new ethers.Contract(
        contractConfig.LOOTY_TOKEN_LOTTERY.address,
        contractConfig.LOOTY_TOKEN_LOTTERY.abi,
        provider,
      );

      const onClaimed = (user: string, rewardAmount: ethers.BigNumber) => {
        console.log(
          'claimed userLotterydata =>',
          userLotteryData?.candidateTokenAmount?.toString(),
        );

        if (user.toLowerCase() === address?.toLowerCase()) {
          console.log('Claimed event detected =>', rewardAmount.toString());
          getUserData();
          setTokenClaimedBalance(rewardAmount.toString());
          stopSpin(0);
          toast.success('USDC Claimed Successfully');
        }
      };

      contract.on('Claimed', onClaimed);

      return () => {
        contract.off('Claimed', onClaimed);
      };
    }
  }, [address, collectionDetail]);

  const claimNft = async () => {
    const contractConfig = collectionDetail?.stages?.active[0]?.metadata[environment];
    if (!contractConfig) return;
    if (!isConnected) {
      toast.error('Please connect your wallet before claiming the NFT.');
      return;
    }

    try {
      setIsClaimingNft(true); // Start loader
      await claimNftContractWrite({
        address: contractConfig.LOOTY_TOKEN_LOTTERY.address,
        abi: contractConfig.LOOTY_TOKEN_LOTTERY.abi,
        functionName: 'claimReward',
      });
    } catch (error) {
      console.log('Error Claiming NFT:', error);
      toast.error('Failed to Claim NFT.');
      setIsClaimingNft(false);
    }
  };

  useEffect(() => {
    if (collectionDetail) {
      const contractConfig = collectionDetail?.stages?.active[0]?.metadata[environment];
      if (!contractConfig) return;
      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
      const contract = new ethers.Contract(
        contractConfig.LOOTY_TOKEN_LOTTERY.address,
        contractConfig.LOOTY_TOKEN_LOTTERY.abi,
        provider,
      );

      const onRolled = (
        user: string,
        requestId: ethers.BigNumber,
        nftContract: string,
        nftId: ethers.BigNumber,
      ) => {
        if (user.toLowerCase() === address?.toLowerCase()) {
          getUserData();
        }
      };

      contract.on('Rolled', onRolled);

      return () => {
        contract.off('Rolled', onRolled);
      };
    }
  }, [address, collectionDetail]);

  const rerollNft = async () => {
    const contractConfig = collectionDetail?.stages?.active[0]?.metadata[environment];
    if (!contractConfig) return;
    if (!isConnected) {
      toast.error('Please connect your wallet before re-rolling the NFT.');
      return;
    }

    try {
      setIsRerolling(true);
      setIsRerollingNft(true);
      startSpin();
      await RerollContractWrite({
        address: contractConfig.LOOTY_TOKEN_LOTTERY.address,
        abi: contractConfig.LOOTY_TOKEN_LOTTERY.abi,
        functionName: 'reroll',
      });
    } catch (error) {
      console.log('Error Re-rolling NFT:', error);
      toast.error('Failed to re-roll NFT.');
      setIsRerollingNft(false);
    }
  };

  const startSpin = () => {
    console.log('startSpin().......');
    if (spinIntervalRef.current) return; // Prevent multiple intervals
    console.log('startSpin() cross');
    setSpinning(true);
    spinIntervalRef.current = setInterval(() => {
      if (stackedCarouselRef.current) {
        stackedCarouselRef.current.goNext(); // Move to next slide
      }
    }, 350); // Spin with 350ms delay
  };

  const stopSpin = (targetCredits: number) => {
    console.log('stopSpin()......', targetCredits);
    console.log('spinning', spinning);
    // if (!spinning) return;
    console.log('spinIntervalRef.current', spinIntervalRef.current);
    if (spinIntervalRef.current) {
      clearInterval(spinIntervalRef.current); // Clear the interval
      spinIntervalRef.current = null;
    }

    setSpinning(false);

    // Find the slide with the matching credits
    const targetSlideIndex = data.findIndex(item => item.credits === targetCredits);

    if (targetSlideIndex !== -1 && stackedCarouselRef.current) {
      const steps = (targetSlideIndex + data.length - activeSlideIndex) % data.length;
      stackedCarouselRef.current.swipeTo(steps); // Swipe to the selected NFT slide
    }
  };

  useEffect(() => {
    if (collectionDetail) {
      const contractConfig = collectionDetail?.stages?.active[0]?.metadata[environment];
      if (!contractConfig) return;

      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
      const contract = new ethers.Contract(
        contractConfig.LOOTY_TOKEN_LOTTERY.address,
        contractConfig.LOOTY_TOKEN_LOTTERY.abi,
        provider,
      );

      const onFulfilled = (user: string, requestId: ethers.BigNumber, amount: ethers.BigNumber) => {
        console.log('onFulfilled................');
        if (user.toLowerCase() === address?.toLowerCase()) {
          setTokenClaimedBalance(amount);
          const randomIndex = Math.floor(Math.random() * data.length);
          const selectedItem = data[randomIndex];
          setSelectedNft(selectedItem);
          getUserData();
          fetchBlockchainKeyBalances();
        }
      };
      console.log('onFulfilled calling...........');
      contract.on('Fulfilled', onFulfilled);

      return () => {
        contract.off('Fulfilled', onFulfilled);
      };
    }
  }, [address, collectionDetail]);

  useEffect(() => {
    // console.log('isRerolling123', isRerolling)
    console.log('userLotteryData?.state =>', userLotteryData?.state);
    console.log(
      'userLotteryData?.candidateTokenAmount =>',
      userLotteryData?.candidateTokenAmount?.toString(),
    );

    if ((loadingOpenBoxBtn && userLotteryData?.state === 0) || userLotteryData?.state === 2) {
      const randomIndex = Math.floor(Math.random() * data.length);
      const selectedItem = data[randomIndex];
      setSelectedNft(selectedItem);

      stopSpin(selectedItem.credits);
      setIsRerolling(true);
      setIsClaimingNft(false);
      setLoadingOpenBoxBtn(false);
      getUserData();
      fetchBlockchainKeyBalances();

      setIsRerollingNft(false);
    }
  }, [userLotteryData?.state]);

  const handleOpenBox = () => {
    if (address) {
      sendRequest(
        {
          url: `user/add-open-box-points/${address}`,
          method: 'POST',
        },
        (data: any) => {
          console.log(data);
        },
      );
    }
  };

  const connectAvax = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.enable();
      } else {
        toast.error('Metamask is not detected. Please install the browser extension to proceed.', {
          autoClose: 8000,
        });
      }
    } catch (error) {
      console.error('Connection rejected or failed:', error);
    }
  };

  return (
    <>
      <EnclaveClaimNftLotteryModal
        isOpen={isRerolling || Number(userLotteryData?.state) === 2}
        onClose={() => setIsRerolling(false)}
        rerollsLeft={userLotteryData?.rerollsLeft}
        userState={userLotteryData?.state}
        userDataSeason={userLotteryData?.season}
        nftId={tokenClaimedBalance}
        claimNft={claimNft}
        isClaimingNft={isClaimingNft}
        rerollNft={rerollNft}
        isRerollingNft={isRerollingNft}
        selectedNft={selectedNft}
        contractCurrentSeason={contractCurrentSeason}
      />
      <section className="mb-[36px] xl:mb-[40px]">
        {window.innerWidth > 1024 ? (
          <EnclaveBanner collection={collectionDetail} contractCurrentSeason={contractCurrentSeason} />
        ) : (
          <div className="w-full bg-gray-800 rounded-md">
            <img
              src={'/assets/images/enclave-banner.png'}
              className="w-full h-auto max-h-[418px]"
            />
          </div>
        )}
      </section>

      <section className="pb-[24px] mb-[24px] border-b border-grayscale-700 lg:hidden">
        <div className="flex gap-2.5 items-center  mb-4">
          <div className="w-[60px] h-[60px] lg:w-[109px] lg:h-[109px] rounded-md">
            <img
              src={'/assets/images/enclave-logo.png'}
              className="object-contain w-full h-full rounded-md"
            />
          </div>

          <div className="space-y-[8px]">
            <h3 className="uppercase text-brand-primary-light 2xl:text-[32px] text-[17px]">
              Season {contractCurrentSeason === 0 ? '1': Number(contractCurrentSeason) + 1}
            </h3>

            <div>
              <div className="flex gap-[9px] items-center">
                <a href="https://www.enclave.market" target="_blank" rel="noopener noreferrer">
                  <IconSvg
                    icon="webM"
                    className="text-grayscale-200 w-[12px] h-[12px] lg:w-fit lg:h-fit"
                  />
                </a>

                <a href="https://x.com/enclavemarkets" target="_blank" rel="noopener noreferrer">
                  <IconSvg
                    icon="twitterM"
                    className="text-grayscale-200 w-[12px] h-[12px] lg:w-fit lg:h-fit"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-3">
          <div className="bg-grayscale-800 bg-opacity-70 py-3 pr-[12px] pl-[18px] rounded-md">
            <div className="mb-2 text-xs text-grayscale-200 text-end">Value of Rewards</div>

            <div className="text-lg text-grayscale-100">≈ $20,000</div>
          </div>

          <div className="bg-grayscale-800 bg-opacity-70 py-3 pr-[12px] pl-[18px] rounded-md">
            <div className="mb-2 text-xs text-grayscale-200 text-end">Minted Keys</div>

            <div className="text-lg text-grayscale-100">
              <div className="flex">
                <div>0</div>
                <div>/2206</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-[72px]">
        <div className="mb-10">
          <ResponsiveContainer
            carouselRef={stackedCarouselRef}
            render={(parentWidth, carouselRef) => {
              const currentVisibleSlide = window.innerWidth > 1024 ? 7 : 3;

              return (
                <StackedCarousel
                  ref={carouselRef}
                  slideComponent={Card}
                  slideWidth={window.innerWidth > 1024 ? 268 : 197}
                  carouselWidth={parentWidth}
                  data={data.reverse()}
                  currentVisibleSlide={currentVisibleSlide}
                  maxVisibleSlide={window.innerWidth > 1024 ? 7 : 3}
                  disableSwipe
                />
              );
            }}
          />
        </div>

        <div className="space-y-[32px] flex flex-col items-center justify-center">
          {!address ? (
            <Button onClick={() => connectAvax()} type="secondary" size="m">
              Connect Wallet
            </Button>
          ) : (
            <>
              <div className="flex lg:gap-[18px] gap-[10px] items-center justify-center flex-wrap">
                {keyIds.map((keyIndex, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      blockchainKeyBalances[keyIndex] > 0 && handleCardClick(Number(keyIndex))
                    }
                    className={`w-[224px] lg:w-[268px] rounded-md bg-grayscale-800 lg:p-[15px] lg:pb-[17px] p-[10px] cursor-pointer flex lg:flex-col gap-[18px] lg:items-start items-center lg:justify-start ${
                      selectedKey === Number(keyIndex) && blockchainKeyBalances[keyIndex] > 0
                        ? 'border-2 border-purple-500'
                        : ''
                    }`}
                  >
                    <img
                      src={`${contractCurrentSeason === 0 ? `/assets/images/arenaKeys/${keyIndex}.png`: `/assets/images/keys/s${Number(contractCurrentSeason)+1}/${keyIndex}.png`}`}
                      className="lg:w-[238px] lg:h-[238px] w-[46px] h-[46px] rounded"
                      alt={`Key ${keyIndex}`}
                    />

                    <div className="space-y-[9px]">
                      <h5 className="uppercase text-[#FAFAFA] lg:text-[18px] text-[14px]">
                        {KEY_NAMES[keyIndex as keyof typeof KEY_NAMES] ?? `Key ${keyIndex}`}
                      </h5>

                      <div className="text-xs text-gray-300 space-x-[7px]">
                        <span>Ready to use:</span>
                        <span className="font-semibold pl-[7px]">
                          {isLoadingKeys ? (
                            <SyncLoader color="#B73FFF" size={5} />
                          ) : (
                            blockchainKeyBalances[keyIndex] ?? 0
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="text-sm text-center text-grayscale-200 lg:text-base">
                <p className="mb-4 lg:mb-0">
                  Open box using your keys and get rewards from your favorite project.
                </p>

                <p className="mb-4">
                  Your NFT will be burned once you use your first key. With each key, you get one
                  reward, but some keys allow <br className="hidden lg:block" /> re-roll for a
                  chance at another reward.
                </p>

                  <p className="mb-4 lg:font-bold">
                    Silver keys don&apos;t include re-rolls, Gold keys include 1 re-roll and
                    Platinum keys include 2 re-rolls. <br className="hidden lg:block" /> After each
                    spin, you can either keep your reward or try again until your re-roll limit is
                    reached.
                  </p>

                <p>Remember, only one reward can be claimed per key!</p>
              </div>
            </>
          )}

            <div className="mx-auto w-fit">
              {loadingOpenBoxBtn ? (
                <SyncLoader color="#B73FFF" size={10} />
              ) : (
                <div className="flex flex-wrap gap-4 justify-center items-center">
                  <Button
                    size="m"
                    type="primary"
                    classNames={`${
                      blockchainKeyBalances[Number(selectedKey)] < 1
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    }`}
                    disabled={blockchainKeyBalances[Number(selectedKey)] < 1}
                    onClick={() => openLootbox(1)}
                  >
                    Open box
                  </Button>

                  <Button
                    size="m"
                    type="primary"
                    classNames={`${
                      blockchainKeyBalances[Number(selectedKey)] < 3
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    }`}
                    disabled={blockchainKeyBalances[Number(selectedKey)] < 3}
                    onClick={() => openLootbox(3)}
                  >
                    Open 3 boxes
                  </Button>

                  <Button
                    size="m"
                    type="primary"
                    classNames={`${
                      blockchainKeyBalances[Number(selectedKey)] < 10
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    }`}
                    disabled={blockchainKeyBalances[Number(selectedKey)] < 10}
                    onClick={() => openLootbox(10)}
                  >
                    Open 10 boxes
                  </Button>
                </div>
              )}
            </div>
        </div>
      </section>

      <section>
        <div className="mb-[15px] lg:mb-[36px]">
          <h3 className="mb-2 text-brand-primary-light">Rewards</h3>

          <div className="text-grayscale-200">
            The better the key, the more rewards you can get!
          </div>
        </div>

        <div className="grid gap-[18px] grid-cols-[repeat(auto-fit,minmax(326px,1fr))]">
          {rewardEnclaveCardData.map((event, index) => (
            <RewardCard
              key={index}
              name={event?.name}
              estimatedValue={event?.estimatedValue}
              rarity={event?.rarity}
              image={event?.image}
              size="medium"
              showRarity={false}
            />
          ))}
        </div>
      </section>
    </>
  );
}

export default EnclaveOpenbox;

const Card = React.memo(function (props: slideProp) {
  const { data, dataIndex } = props;
  const { cover, name, icon } = data[dataIndex];

  return (
    <div className="w-[197px] lg:w-[268px] h-[197px] lg:h-[268px] rounded-md bg-grayscale-800 p-[15px]">
      <div
        className="relative w-full h-full rounded bg-grayscale-700"
        style={{ backgroundImage: `url(${cover})`, backgroundSize: 'cover' }}
      >
        <div className="bg-grayscale-800 rounded py-1 px-2.5 absolute top-2.5 left-2.5 font-semibold text-xs text-gray-200 flex gap-1.5 items-center">
          <div>{name} </div>
        </div>
      </div>
    </div>
  );
});
