import IconSvg from './common/ui/IconSvg';

const StatusPill = ({ status, size = 'sm' }: { status: string; size?: 'sm' | 'xs' }) => {
  status = status.toLowerCase();
  const iconName =
    status === 'expired'
      ? 'expired'
      : status === 'live'
      ? size === 'xs'
        ? 'liveS'
        : 'live2M'
      : size === 'xs'
      ? 'comingSoonS'
      : 'comingSoon2M';

  const colorClasses =
    status === 'expired'
      ? 'bg-system-error-dark border-system-error'
      : status === 'live'
      ? 'bg-system-success-dark border-system-success'
      : 'bg-system-warning-dark border-system-warning';

  return (
    <div
      className={`py-1 px-3 leading-none rounded-md flex gap-1.5 items-center border w-fit ${colorClasses}`}
    >
      <IconSvg icon={iconName} />
      <span className={`${size === 'sm' ? 'text-sm' : 'text-xss'} text-grayscale-100 capitalize`}>
        {status}
      </span>
    </div>
  );
};

export default StatusPill;
