import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';
import { Collection } from 'src/components/common/cards/types/types';
import IconSvg, { Icons } from 'src/components/common/ui/IconSvg';
import ProgressBar from 'src/components/common/ui/ProgressBar';
import SyncLoader from 'react-spinners/SyncLoader';
import StatusPill from 'src/components/StatusPill';
import Countdown from 'src/components/common/Countdown';

interface Props {
  index?: number;
  arenaTotalMintedKeys?: string;
  collection: Collection;
  handleClick: (collection: Collection, buttonType: 'mint' | 'openbox' | 'checkReward') => void;
}

function HomeEarnItem({ index, collection, handleClick, arenaTotalMintedKeys }: Props) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false); // State to toggle accordion
  const navigate = useNavigate();
  const getIconName = (networkCurrency: string): Icons => {
    switch (networkCurrency) {
      case 'AVAX':
        return 'avaxM';
      case 'ETH':
        return 'ethM';
      case 'BNB':
        return 'bnbM';
      case 'EGLD':
        return `egld`;
      case 'SOL':
        return 'solM';
      case 'SUI':
        return 'suiM';
      default:
        return 'ethM'; // Default icon if currency is not listed
    }
  };

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen); // Toggle the accordion state
  };

  const iconName = getIconName(collection.network.networkCurrency);

  const isDesktop = window.innerWidth > 1024;

  return (
    <>
      {isDesktop ? (
        <div
          className={`hidden lg:grid grid-cols-10 px-5 py-[15px] rounded-md mb-2.5 last-of-type:mb-0 items-center justify-between cursor-pointer ${
            isAccordionOpen ? 'bg-grayscale-900 border-2 border-grayscale-700' : 'bg-grayscale-800'
          }`}
          onClick={!collection?.isEarnBlured ? toggleAccordion : undefined}
        >
          <div className="flex col-span-3 gap-x-5 items-center text-sm text-grayscale-300">
            <img
              src={
                !collection?.isEarnBlured
                  ? collection.logo
                  : `/assets/images/blur-logo-${index}.png`
              }
              className="w-[60px] h-[60px] bg-cover bg-center"
            />

            <h5 className="text-brand-primary-light max-w-[148px] !leading-[22px] rounded-[5px]">
              {!collection?.isEarnBlured ? (
                collection.name
              ) : (
                <img
                  src={'/assets/images/blur-collection-name-sm.png'}
                  alt="blur collection name"
                />
              )}
            </h5>
          </div>

          <div className="col-span-2">
            {!collection?.isEarnBlured ? (
              <>
                <p className="text-lg font-semibold text-grayscale-100 mb-1.5">
                  ≈ {collection.slug === 'avax' ? '' : '$'}
                  {collection?.rewardsTotalValue
                    ? Number(collection?.rewardsTotalValue).toLocaleString()
                    : ''}{' '}
                  {collection.slug === 'avax' ? 'AVAX' : ''}
                </p>

                <p className="text-[11px] text-grayscale-500">rewards total value</p>
              </>
            ) : (
              <img src={'/assets/images/blur-info.png'} alt="blur rewards total value" />
            )}
          </div>

          <div className="col-span-2">
            {!collection?.isEarnBlured ? (
              <>
                <div className="flex items-center gap-[9px] mb-1.5">
                  {/* <p className="text-lg font-semibold text-grayscale-100">from</p> */}
                  <IconSvg icon={iconName} />
                  {/* <p className="text-lg font-semibold text-grayscale-100">0,000</p> */}
                </div>

                <p className="text-[11px] text-grayscale-500">{collection?.network?.networkName}</p>
              </>
            ) : (
              <img src={'/assets/images/blur-info-2.png'} alt="blur price" />
            )}
          </div>

          <div className="col-span-1 text-sm text-grayscale-300">
            {(() => {
              const liveStage = collection?.stages?.find(
                stage => stage.isLive || stage.isLiveKeyClaim,
              );
              const soonStage = collection?.stages?.find(
                stage => stage.isSoon || stage.isSoonKeyClaim,
              );
              const expiredStage = collection?.stages?.find(
                stage => stage.isExpired || stage.isExpiredKeyClaim,
              );

              if (liveStage) {
                return <StatusPill status="live" size="xs" />;
              } else if (soonStage) {
                return <StatusPill status="soon" size="xs" />;
              } else if (expiredStage) {
                return <StatusPill status="expired" size="xs" />;
              } else {
                return null;
              }
            })()}
          </div>

          <div className="flex col-span-2 justify-end items-center">
            {!collection?.isEarnBlured ? (
              <button
                onClick={toggleAccordion}
                className={`flex items-center p-2 text-xs rounded-md gap-x-2 w-[107px] justify-center ${
                  isAccordionOpen
                    ? 'bg-brand-primary-light text-grayscale-800'
                    : 'bg-grayscale-700 text-grayscale-200'
                }`}
              >
                <span>{isAccordionOpen ? 'Show less' : 'Show all'}</span>

                <IconSvg
                  icon="arrowDownS"
                  className={`transition-transform duration-300 ${
                    isAccordionOpen ? 'rotate-180 text-grayscale-800' : 'text-brand-primary-light'
                  }`}
                />
              </button>
            ) : (
              <div className="h-[25px] w-[115px] bg-grayscale-700"></div>
            )}
          </div>
        </div>
      ) : (
        <div
          className={`p-2.5 rounded-md mb-2.5 last-of-type:mb-0 items-center cursor-pointer border-2 lg:hidden ${
            isAccordionOpen
              ? 'bg-grayscale-900 border-grayscale-700'
              : 'bg-grayscale-800 border-transparent'
          }`}
        >
          <div className="flex justify-between items-center">
            <div className="flex gap-x-5 justify-between items-center text-grayscale-300">
              <img
                src={
                  !collection?.isEarnBlured
                    ? collection.logo
                    : `/assets/images/blur-logo-${index}.png`
                }
                className="w-[32px] h-[32px] bg-cover bg-center"
              />

              <h5 className="text-brand-primary-light !leading-[22px] rounded-[5px] text-sm">
                {!collection?.isEarnBlured ? (
                  collection.name
                ) : (
                  <img
                    src={'/assets/images/blur-collection-name-sm.png'}
                    alt="blur collection name"
                  />
                )}
              </h5>
            </div>

            <div className="flex col-span-2 justify-end items-center">
              {!collection?.isEarnBlured ? (
                <button
                  onClick={toggleAccordion}
                  className={`flex items-center p-2 text-xs rounded-md gap-x-2 w-[107px] justify-center ${
                    isAccordionOpen
                      ? 'bg-brand-primary-light text-grayscale-800'
                      : 'bg-grayscale-700 text-grayscale-200'
                  }`}
                >
                  <span>{isAccordionOpen ? 'Show less' : 'Show all'}</span>

                  <IconSvg
                    icon="arrowDownS"
                    className={`transition-transform duration-300 ${
                      isAccordionOpen ? 'rotate-180 text-grayscale-800' : 'text-brand-primary-light'
                    }`}
                  />
                </button>
              ) : (
                <div className="h-[25px] w-[115px] bg-grayscale-700"></div>
              )}
            </div>
          </div>

          <div className="border border-grayscale-700 my-2.5 w-full"></div>

          <div className="flex justify-between items-center">
            <div>
              {!collection?.isEarnBlured ? (
                <>
                  <p className="font-semibold text-grayscale-100 mb-1.5">
                    ≈ $
                    {collection?.rewardsTotalValue
                      ? Number(collection?.rewardsTotalValue).toLocaleString()
                      : ''}
                  </p>

                  <p className="text-xss text-grayscale-500">rewards total value</p>
                </>
              ) : (
                <img src={'/assets/images/blur-info.png'} alt="blur rewards total value" />
              )}
            </div>

            <div>
              {!collection?.isEarnBlured ? (
                <>
                  <IconSvg icon={iconName} className=" mb-1.5" />

                  <p className="text-xss text-grayscale-500">{collection?.network?.networkName}</p>
                </>
              ) : (
                <img src={'/assets/images/blur-info-2.png'} alt="blur price" />
              )}
            </div>

            <div>
              {(() => {
                const liveStage = collection?.stages?.find(
                  stage => stage.isLive || stage.isLiveKeyClaim,
                );
                const soonStage = collection?.stages?.find(
                  stage => stage.isSoon || stage.isSoonKeyClaim,
                );
                const expiredStage = collection?.stages?.find(
                  stage => stage.isExpired || stage.isExpiredKeyClaim,
                );

                if (liveStage) {
                  return <StatusPill status="live" size="xs" />;
                } else if (soonStage) {
                  return <StatusPill status="soon" size="xs" />;
                } else if (expiredStage) {
                  return <StatusPill status="expired" size="xs" />;
                } else {
                  return null;
                }
              })()}
            </div>
          </div>
        </div>
      )}

      {isAccordionOpen && (
        <>
          {collection.stages
            .filter(st => st?.isShow)
            .slice()
            .sort((a, b) => {
              if (a.isLive && !b.isLive) return -1;
              if (!a.isLive && b.isLive) return 1;

              if (a.isLiveKeyClaim && !b.isLiveKeyClaim) return -1;
              if (!a.isLiveKeyClaim && b.isLiveKeyClaim) return 1;

              if (a.isExpired && !b.isExpired) return 1;
              if (!a.isExpired && b.isExpired) return -1;

              return b.order - a.order;
            })
            .map((stage, index) => (
              <>
                {isDesktop ? (
                  <div
                    key={index}
                    className="hidden lg:grid grid-cols-10 px-5 py-[15px] rounded-md bg-grayscale-900 mb-2.5 last-of-type:mb-0 items-center justify-between border-2 border-grayscale-700"
                  >
                    <div className="flex col-span-3 gap-x-5 items-center text-sm text-grayscale-300">
                      <img src={collection.logo} className="w-[60px] h-[60px]" />
                      <h5 className="text-brand-primary-light max-w-[145px] !leading-[22px]">
                        {stage?.stageName}
                      </h5>
                    </div>

                    {collection.slug !== 'enclave' ? (
                      <div className="col-span-2">
                        <>
                          <p className="text-lg font-semibold text-grayscale-100 mb-1.5">
                            ≈ {collection.slug === 'avax' ? '' : '$'}
                            {collection?.rewardsTotalValue
                              ? Number(collection?.rewardsTotalValue).toLocaleString()
                              : ''}{' '}
                            {collection.slug === 'avax' ? 'AVAX' : ''}
                          </p>
                          <p className="text-[11px] text-grayscale-500">rewards total value</p>
                        </>
                      </div>
                    ) : stage?.isExpired && stage?.isLiveKeyClaim ? (
                      <div className="col-span-2">
                        <>
                          <p className="text-lg font-semibold text-grayscale-100 mb-1.5">
                            ≈ ${stage?.mintPrice ? Number(stage?.mintPrice).toLocaleString() : ''}
                          </p>
                          <p className="text-[11px] text-grayscale-500">rewards total value</p>
                        </>
                      </div>
                    ) : (
                      <div className="col-span-2"></div>
                    )}

                    {collection.slug === 'enclave' ||
                    collection.slug === 'avax' ||
                    collection.slug === 'multiversx' ? (
                      <div>
                        <>
                          <IconSvg icon={iconName} className=" mb-1.5" />

                          <p className="text-xss text-grayscale-500">
                            {collection?.network?.networkName}
                          </p>
                        </>
                      </div>
                    ) : null}

                    {(collection.slug === 'multiversx' ||
                      collection.slug === 'enclave' ||
                      collection.slug === 'avax') && (
                      <>
                        <div className="col-span-1"></div>

                        <div className="col-span-1 text-sm text-grayscale-300">
                          {stage.isLive ? (
                            <div className="flex flex-col gap-y-2">
                              <StatusPill status="live" size="xs" />
                              <Countdown stage={stage} size="small" />
                            </div>
                          ) : stage.isSoon ? (
                            <StatusPill status="soon" size="xs" />
                          ) : (
                            <StatusPill
                              status={
                                stage.isLiveKeyClaim
                                  ? 'live'
                                  : stage.isSoonKeyClaim
                                  ? 'soon'
                                  : 'expired'
                              }
                              size="xs"
                            />
                          )}
                        </div>
                      </>
                    )}

                    {stage?.isLive && (
                      <>
                        <div className="flex col-span-2 justify-end items-center">
                          <Button
                            onClick={() =>
                              collection.slug === 'multiversx' || collection.slug === 'enclave'
                                ? navigate(`/leaderboards/${collection.slug}`)
                                : navigate(`/${collection.slug}/${collection.id}/${stage?.id}`)
                            }
                            type="primary"
                            size="xs"
                            classNames="!min-w-[74px]"
                          >
                            {collection.slug === 'multiversx' || collection.slug === 'enclave'
                              ? 'Earn'
                              : 'Claim'}
                          </Button>
                        </div>
                      </>
                    )}

                    {stage?.isExpired &&
                      stage?.isLiveKeyClaim &&
                      (collection.slug === 'multiversx' || collection.slug === 'enclave') && (
                        <div className="flex col-span-2 justify-end items-center">
                          <Button
                            onClick={() =>
                              navigate(`/${collection.slug}/${collection.id}/${stage?.id}`)
                            }
                            type="primary"
                            size="xs"
                            classNames="!min-w-[74px]"
                          >
                            Claim
                          </Button>
                        </div>
                      )}

                    {stage?.isSoon && collection.slug === 'enclave' && (
                      <>
                        <div className="flex col-span-2 justify-end items-center">
                          <Button type="ghost" disabled size="xs" classNames="!min-w-[74px]">
                            Claim
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div
                    key={index}
                    className={`p-2.5 rounded-md mb-2.5 last-of-type:mb-0 items-center cursor-pointer border-2 lg:hidden ${
                      isAccordionOpen
                        ? 'bg-grayscale-900 border-grayscale-700'
                        : 'bg-grayscale-800 border-transparent'
                    }`}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex gap-x-5 justify-between items-center text-grayscale-300">
                        <img
                          src={collection.logo}
                          className="w-[32px] h-[32px] bg-cover bg-center"
                        />

                        <h5 className="text-brand-primary-light !leading-[22px] rounded-[5px] text-sm">
                          {stage?.stageName}
                        </h5>
                      </div>

                      {stage?.isLive && (
                        <Button
                          onClick={() =>
                            collection.slug === 'multiversx' || collection.slug === 'enclave'
                              ? navigate(`/leaderboards/${collection.slug}`)
                              : navigate(`/${collection.slug}/${collection.id}/${stage?.id}`)
                          }
                          type="primary"
                          size="xs"
                          classNames="!min-w-[74px]"
                        >
                          {collection.slug === 'multiversx' || collection.slug === 'enclave'
                            ? 'Earn'
                            : 'Claim'}
                        </Button>
                      )}

                      {stage?.isExpired &&
                        stage?.isLiveKeyClaim &&
                        (collection.slug === 'multiversx' || collection.slug === 'enclave') && (
                          <Button
                            onClick={() =>
                              navigate(`/${collection.slug}/${collection.id}/${stage?.id}`)
                            }
                            type="primary"
                            size="xs"
                            classNames="!min-w-[74px]"
                          >
                            Claim
                          </Button>
                        )}

                      {stage?.isSoon && collection.slug === 'enclave' && (
                        <>
                          <div className="flex col-span-2 justify-end items-center">
                            <Button type="ghost" disabled size="xs" classNames="!min-w-[74px]">
                              Claim
                            </Button>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="border border-grayscale-700 my-2.5 w-full"></div>

                    <div className="flex justify-between items-center">
                      {collection.slug !== 'enclave' ? (
                        <>
                          <div>
                            <p className="font-semibold text-grayscale-100 mb-1.5">
                              ≈ {collection.slug === 'avax' ? '' : '$'}
                              {collection?.rewardsTotalValue
                                ? Number(collection?.rewardsTotalValue).toLocaleString()
                                : ''}{' '}
                              {collection.slug === 'avax' ? 'AVAX' : ''}
                            </p>

                            <p className="text-xss text-grayscale-500">rewards total value</p>
                          </div>
                        </>
                      ) : stage?.isExpired && stage?.isLiveKeyClaim ? (
                        <>
                          <div>
                            <p className="font-semibold text-grayscale-100 mb-1.5">
                              ≈ ${stage?.mintPrice ? Number(stage?.mintPrice).toLocaleString() : ''}
                            </p>

                            <p className="text-xss text-grayscale-500">rewards total value</p>
                          </div>
                        </>
                      ) : (
                        <div className="col-span-2"></div>
                      )}

                      {(collection.slug === 'multiversx' ||
                        collection.slug === 'enclave' ||
                        collection.slug === 'avax') && (
                        <div>
                          {(() => {
                            const liveStage = stage.isLive || stage.isLiveKeyClaim;
                            const soonStage = stage.isSoon || stage.isSoonKeyClaim;
                            const expiredStage = stage.isExpired || stage.isExpiredKeyClaim;

                            if (liveStage) {
                              return <StatusPill status="live" size="xs" />;
                            } else if (soonStage) {
                              return <StatusPill status="soon" size="xs" />;
                            } else if (expiredStage) {
                              return <StatusPill status="expired" size="xs" />;
                            } else {
                              return null;
                            }
                          })()}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            ))}
        </>
      )}
    </>
  );
}

export default HomeEarnItem;
