import { useEffect, useRef, useState } from 'react';

import { useNavigate, useLocation, useParams } from 'react-router-dom';
import IconSvg from 'src/components/common/ui/IconSvg';
import EnclavePointsCard, { CardStatus } from './components/EnclavePointsCard';
import { enclaveMainPrizesData, EnclavePointsCardData, enclavePointsCardData } from 'src/dummydata';
import EnclaveClaimBtn from './components/EnclaveClaimBtn';
import LeaderBoardCard from './components/LeaderboardCard';
import { useHttp, useNotification } from 'src/hooks';
import { toast } from 'react-toastify';
import { useWebWalletLogin } from '@multiversx/sdk-dapp/hooks';
import { useGetLoginInfo } from '@multiversx/sdk-dapp/hooks/account';
import { getSliderSettings } from '../home/Home';
import Slider from 'react-slick';
import { allowedTwitterUsers } from 'src/allowedTwitterUsers';
import CustomConnectWallet from 'src/components/common/modal/CustomConnectWallet';
import { useAccount } from 'wagmi';
import Button from 'src/components/button/Button';
import EnclaveRewardCard from './components/EnclaveRewardCard';
import CountdownTimer from './common/countDownTimer';

interface TopUser {
  walletAddress: string;
  totalPoints: number;
  rank: number;
  key?: string;
}

interface TimeRemaining {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export default function EnclaveDetail() {
  const { collectionSlug } = useParams();
  const navigate = useNavigate();
  const { sendRequest, error: isError } = useHttp();
  const notify = useNotification();
  const [searchQuery, setSearchQuery] = useState('');
  const [user, setUser] = useState<any>(null);
  const { state } = useLocation();
  // const hasHistory = state?.from || document.referrer;
  const [activeTab, setActiveTab] = useState('all');
  const [address, setAddress] = useState('');
  const [currentSlug, setCurrentSlug] = useState('');
  const [isEnterLoading, setIsEnterLoading] = useState(false);
  const [twitterConnected, setIsTwitterConnected] = useState(false);
  const [isOpenReferralModal, setIsOpenReferralModal] = useState(false);

  const [activeLeaderBoardTab, setActiveLeaderBoardTab] = useState('users');
  const [updatedCards, setUpdatedCards] = useState<EnclavePointsCardData[]>(enclavePointsCardData);
  const [topUsers, setTopUsers] = useState<TopUser[]>([]);
  const [accessToken, setAccessToken] = useState('');
  const { address: isMetamaskConnected, isConnected } = useAccount();

  const [isCopied, setIsCopied] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isLoggedIn: isLoggedInMultiversX } = useGetLoginInfo();
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 3;
  const cardContainerRef = useRef<HTMLDivElement | null>(null);

  const [stages, setStages] = useState<any>();

  const tabs: { value: string; label: string; count?: number }[] = [
    { value: 'all', label: 'All' },
    {
      value: 'to-do',
      label: 'To Do',
      count: enclavePointsCardData.filter(card => card.status === 'To Do').length,
    },
    {
      value: 'done',
      label: 'Done',
      count: enclavePointsCardData.filter(card => card.status === 'Done').length,
    },
  ];

  const leaderBoardTabs: { value: string; label: string }[] = [
    { value: 'users', label: 'Participants' },
    // { value: 'namecoins', label: 'Namecoins' },
  ];

  useEffect(() => {
    if (collectionSlug) fetchActiveSeason();
  }, [collectionSlug]);

  const fetchActiveSeason = async () => {
    if (!collectionSlug) return;

    sendRequest(
      {
        url: `collections/active-season/${collectionSlug}`,
        method: 'GET',
      },
      (data: any) => {
        setStages(data);
      },
    );
  };

  useEffect(() => {
    console.log('isMetamaskConnected', !isMetamaskConnected);
    // if (!isMetamaskConnected) {
    //   setIsModalOpen(true);
    // } else {
    //   setIsModalOpen(false);
    // }
  }, [isMetamaskConnected]);

  const getCompletedChallenges = (address: string, collectionSlug: string) => {
    setIsEnterLoading(true);
    console.log('dddd', accessToken);
    if (accessToken && collectionSlug) {
      sendRequest(
        {
          url: `user/challenges/completed/${collectionSlug}`,
          method: 'GET',
          accessToken,
        },
        (data: any) => {
          updateCardData(data.challengeData);
          setTopUsers(data.topUsers);
          setIsEnterLoading(false);
        },
      );
    }
  };

  const handleUserPendingStatus = (type: string) => {
    const jwtString = localStorage.getItem('jwt');

    const parsedJwt = jwtString ? JSON.parse(jwtString) : null;
    if (parsedJwt.token) {
      setAccessToken(parsedJwt.token);
      sendRequest(
        {
          url: `user/update-pending-status`,
          accessToken: parsedJwt.token,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          payload: { type: type },
        },
        (data: any) => {
          setIsEnterLoading(false);
          if (parsedJwt.token && collectionSlug) {
            getUserInfo();
            getCompletedChallenges(address, collectionSlug);
          }
        },
      );
    }
  };

  const updateCardData = (completedChallenges: any[]) => {
    const updated = enclavePointsCardData.map(card => {
      const challenge = completedChallenges.find(challenge => challenge.slug === card.slug);
      if (challenge) {
        return {
          ...card,
          completed: challenge.completed,
          status: challenge.completed ? 'Done' : 'To Do',
          isClaimed: challenge.completed,
        };
      }
      return card;
    });

    setUpdatedCards(updated);
  };

  useEffect(() => {
    if (isError && isError.includes('Unable to refresh the access token')) {
      const jwtString = localStorage.getItem('jwt');
      const jdata = JSON.parse(jwtString);
      console.log('while x logout', jdata);

      localStorage.setItem(
        'jwt',
        JSON.stringify({
          token: jdata.token,
          isXLoggedIn: false,
          isMLoggedIn: jwtString && jdata.isMLoggedIn === true,
          isMvxLoggedIn: jwtString && jdata.isMvxLoggedIn === true,
        }),
      );
      notify(`Session expired. Please log in again.`, 'error');
      setTimeout(() => {
        window.location.href = '/';
      }, 500);
    }
  }, [isError]);

  useEffect(() => {
    console.log('window.location.href', accessToken);
    console.log('window.location.href', collectionSlug);

    if (accessToken && collectionSlug) {
      getCompletedChallenges(address, collectionSlug);
    }
  }, [window.location.href, accessToken, collectionSlug]);

  const handleConnectX = () => {
    // setIsEnterLoading(true);
    setCurrentSlug('connect-x');

    // if (address) {
    sendRequest(
      {
        url: `twitter/login`,
        method: 'GET',
      },
      (data: any) => {
        if (data) {
          // window.open(data, '_blank');
          window.location.href = data;
        }
      },
    );
    // }
    console.log('Connect X button clicked');
    // Add your logic here
  };

  const handleTwitterFollow = (slug: string) => {
    if (address) {
      sendRequest(
        {
          url: `twitter/follow-general/${slug}`,
          accessToken,
        },
        (data: any) => {
          setIsEnterLoading(false);
          if (address && collectionSlug) {
            getCompletedChallenges(address, collectionSlug);
          }
        },
      );
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.get('scrollToEarn') === '1' && cardContainerRef.current) {
      // Scroll to the container
      cardContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

      // Remove 'scrollToEarn' from the search parameters
      params.delete('scrollToEarn');
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.replaceState({}, '', newUrl);
    }

    return () => {
      console.log('...');
    };
  }, []);

  useEffect(() => {
    const jwtString = localStorage.getItem('jwt');
    if (jwtString && !user?.isEnclaveReferralPending) {
      const data = JSON.parse(jwtString);
      const accessToken = data.token;

      sendRequest(
        {
          url: `user/set-enclave-status`,
          accessToken,
          method: 'POST',
        },
        (data: any) => {
          setUser(data);
        },
      );
    }
  }, []);

  const handleWatchVideo = (slug: string) => {
    console.log('handleWatchVideo => slug', slug);
    const params = new URLSearchParams(window.location.search);
    const page = params.get('page') || currentPage;
    window.location.href = `/video/${slug}?page=${page}`;
  };

  // const handleEnter = async (slug: string) => {
  //   setCurrentSlug(slug);

  //   const jwtString = localStorage.getItem('jwt');

  //   const parsedJwt = jwtString ? JSON.parse(jwtString) : null;

  //    // Check if it's a wallet-related task
  //    const walletTasks = [
  //     'connect-enclave-wallet',
  //     'complete-kyc-enclave',
  //     'first-deposit',
  //     'trade-perpetuals',
  //     // 'use-alpha-strategies'
  //   ];

  //     // Handle Wallet tasks
  //     if (walletTasks.includes(slug)) {
  //       if (!parsedJwt || !parsedJwt.isMLoggedIn) {
  //         setIsModalOpen(true)
  //         return;
  //       } else {
  //         window.open(here url, '_blank', 'noopener,noreferrer')
  //       }
  //     }

  //   if (!jwtString) {
  //     handleConnectX();
  //   } else {
  //     handleCardClick(slug);
  //   }
  // };

  const handleEnter = async (slug: string) => {
    setCurrentSlug(slug);

    const jwtString = localStorage.getItem('jwt');
    const parsedJwt = jwtString ? JSON.parse(jwtString) : null;

    // Mapping of slugs to URLs
    const urlMapping: Record<string, string> = {
      'connect-enclave-wallet': 'https://trade.enclave.market/account/overview',
      'complete-kyc-enclave': 'https://trade.enclave.market/account/overview',
      'first-deposit': 'https://trade.enclave.market/account/overview',
      'trade-perpetuals': 'https://trade.enclave.market/perps',
    };

    // Check if the slug is in walletTasks
    if (Object.keys(urlMapping).includes(slug)) {
      if (!parsedJwt || !parsedJwt.isMLoggedIn) {
        setIsModalOpen(true);
        return;
      } else {
        // Open the corresponding URL
        window.open(urlMapping[slug], '_blank', 'noopener,noreferrer');
      }
    }
    console.log('parsedJwt, ', parsedJwt);
    // Handle other cases
    if (!parsedJwt?.isXLoggedIn) {
      handleConnectX();
    } else {
      handleCardClick(slug);
    }
  };

  const handleCopyLink = () => {
    if (user) {
      const appUrl = `${window.location.origin}/referral?code=${user.referralCode}&referralApp=Enclave`; // The URL to be copied to clipboard
      navigator.clipboard
        .writeText(appUrl) // Copy the URL to clipboard
        .then(() => {
          setIsCopied(true); // Set copied status
          setTimeout(() => setIsCopied(false), 2000); // Reset copied status after 2 seconds
          toast.success('Link copied');
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
        });
    }
  };

  useEffect(() => {
    // Check if a token is already present on page load
    const jwtString = localStorage.getItem('jwt');
    if (jwtString) {
      const data = JSON.parse(jwtString);
      const token = data.token;
      console.log('jwtString: token', token);
      setAccessToken(token);
      setIsTwitterConnected(data.isXLoggedIn);
    }
  }, []);

  const handleCardClick = (slug: string) => {
    switch (slug) {
      case 'follow-enclave-market-on-x':
        handleTwitterFollow('follow-enclave-market-on-x');
        break;
      case 'follow-wellsda2-on-x':
        handleTwitterFollow('follow-wellsda2-on-x');
        break;
      case 'follow-pwirtjes-on-x':
        handleTwitterFollow('follow-pwirtjes-on-x');
        break;
      case 'enclave-alpha-strats':
        handleWatchVideo(slug);
        break;
      case 'your-first-deposit-and-trade':
        handleWatchVideo(slug);
        break;
      case 'verifying-your-account':
        handleWatchVideo(slug);
        break;
      case 'connect-enclave-wallet':
        handleUserPendingStatus('connectEnclaveWalletPending');
        break;

      case 'complete-kyc-enclave':
        handleUserPendingStatus('completeKycEnclavePending');
        break;

      case 'first-deposit':
        handleUserPendingStatus('enclaveFirstDepositPending');
        break;

      case 'trade-perpetuals':
        handleUserPendingStatus('enclaveTradePerpetualsPending');
        break;

      case 'use-alpha-strategies':
        handleUserPendingStatus('enclaveUseAlphaStratsPending');
        break;

      case 'enclave-referral':
        handleUserPendingStatus('isEnclaveReferralPending');
        break;

      default:
        console.log('No action defined for this slug');
        break;
    }
  };

  const getUserInfo = () => {
    console.log('uuserinfo: accessToken', accessToken);
    sendRequest(
      {
        url: `user/user-info`,
        accessToken,
      },
      (data: any) => {
        setUser(data);
        console.log('userdata', user);
        setAddress(data.id);
      },
    );
  };
  useEffect(() => {
    console.log('uuserinfo: accessToken', accessToken);
    accessToken && getUserInfo();
  }, [accessToken]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const pageFromUrl = params.get('page');
    if (pageFromUrl) {
      setCurrentPage(Number(pageFromUrl));
    }
  }, []);

  // Function to filter cards based on the active tab
  const filterCards = (cards: EnclavePointsCardData[]) => {
    return cards.filter(card => {
      switch (activeTab) {
        case 'to-do':
          return card.status === 'To Do';
        case 'done':
          return card.status === 'Done';
        case 'all':
        default:
          return true;
      }
    });
  };

  // Calculate filtered cards
  const filteredCards = filterCards(updatedCards);

  // Determine current cards based on window width
  const currentCards =
    window.innerWidth > 1024
      ? filteredCards // Show all cards if width > 1024
      : filteredCards.slice((currentPage - 1) * cardsPerPage, currentPage * cardsPerPage);

  // Handle page change
  // const handlePageChange = (page: number) => {
  //   setCurrentPage(page);
  //   if (cardContainerRef.current) {
  //     cardContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //   }
  // };
  const handlePageChange = (page: number) => {
    setCurrentPage(page);

    const params = new URLSearchParams(window.location.search);
    params.set('page', page.toString());
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState({}, '', newUrl);

    if (cardContainerRef.current) {
      cardContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleTaskTabChange = (tab: string) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(filteredCards.length / cardsPerPage);

  const sliderSettings = getSliderSettings(
    7,
    {
      1200: 2,
      1000: 1,
    },
    false,
    true,
    window.innerWidth < 1024
      ? {
          right: '-9px',
          left: '-9px',
        }
      : {},
  );

  const connectAvax = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.enable();
        setIsModalOpen(false);
        console.log('ccccccc', currentSlug);
        handleCardClick(currentSlug);
      } else {
        toast.error('Metamask is not detected. Please install the browser extension to proceed.', {
          autoClose: 8000,
        });
      }
    } catch (error) {
      console.error('Connection rejected or failed:', error);
    }
  };

  return (
    <>
      {/* {hasHistory && (
        <button className="flex items-center gap-1.5 mb-[15px]" onClick={() => navigate(-1)}>
          <IconSvg icon="arrowLeftM" className="text-grayscale-400" />
          <span className="text-sm text-grayscale-400">Go Back</span>
        </button>
      )} */}
      <CustomConnectWallet
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        handleEnter={() => connectAvax()}
      />

      {/* <ReferFriendModal
        isOpen={isOpenReferralModal}
        onClose={() => {
          setIsOpenReferralModal(false);
        }}
        handleCopyLink={() => handleCopyLink()}
      /> */}

      <section className="mb-[40px]">
        <div className="flex justify-between items-center">
          <div className="flex gap-4 justify-between items-center mb-6 lg:gap-5 lg:justify-start">
            <div
              className="lg:w-[109px] lg:h-[109px] w-[48px] h-[48px] rounded-md"
              style={{
                backgroundImage: 'url(/assets/images/enclave-logo.png)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            ></div>

            <div className="space-y-[12px]">
              <div className="flex gap-[13px] items-end">
                <h3 className="uppercase text-brand-primary-light w-fit text-lg lg:text-[32px] max-w-[148px] lg:max-w-fit">
                  Enclave
                </h3>

                <div className="text-xs lg:text-sm text-grayscale-200">AVAX</div>
              </div>
              {/* For desktop */}
              <div className="lg:flex hidden gap-[9px] items-center">
                <a href={'https://www.enclave.market'} target="_blank" rel="noopener noreferrer">
                  <IconSvg
                    icon="webM"
                    className="text-grayscale-400 w-[12px] h-[12px] lg:w-fit lg:h-fit"
                  />
                </a>

                <a href={'https://x.com/enclavemarkets'} target="_blank" rel="noopener noreferrer">
                  <IconSvg
                    icon="twitterM"
                    className="text-grayscale-400 w-[12px] h-[12px] lg:w-fit lg:h-fit"
                  />
                </a>
                {/* {user &&
              <button
                className="flex gap-1 items-center text-grayscale-400"
                onClick={handleCopyLink}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-[12px] h-[12px] lg:w-fit lg:h-fit"
                >
                  <path d="M20 12l-8 8-8-8M4 12l8-8 8 8" />
                </svg>
                <span className="text-xs lg:text-sm">{isCopied ? 'Link copied!' : 'Invite'}</span>
              </button>
              }  */}
              </div>
            </div>
            {/* For mobile */}
            <div className="lg:hidden flex gap-[9px] items-center self-end">
              <a href={'https://www.enclave.market/'} target="_blank" rel="noopener noreferrer">
                <IconSvg icon="webM" className="w-6 h-6 text-grayscale-400 lg:w-fit lg:h-fit" />
              </a>

              <a href={'https://x.com/enclavemarkets'} target="_blank" rel="noopener noreferrer">
                <IconSvg icon="twitterM" className="w-6 h-6 text-grayscale-400 lg:w-fit lg:h-fit" />
              </a>
            </div>
          </div>
        </div>

        <p className="mb-6 text-sm text-grayscale-300 lg:text-base">
          Let&apos;s explore Enclave! Dive into our latest campaign, where engaging with a series of
          tasks throughout the Enclave ecosystem unlocks exceptional rewards. Stay active and climb
          the leaderboard to access weekly prizes. Consistency is your ticket to the top—keep up the
          momentum to secure your spot each week!
        </p>

        <div className="flex lg:flex-row flex-col gap-4">
          <div className="lg:w-[336px] w-full bg-grayscale-800 rounded-md lg:p-4 p-6 space-y-[8px]">
            <div className="flex items-center justify-center w-[44px] h-[44px] bg-grayscale-900 rounded-sm">
              <IconSvg icon="dollarM" className="text-grayscale-400" />
            </div>

            <div className="text-sm text-grayscale-400 lg:text-base">
              Total Value of Rewards Across All Seasons
            </div>

            <div className="text-grayscale-100 lg:text-[24px] text-lg font-bold">≈ $135,000</div>
          </div>

          {stages?.last && (
            <div className="lg:w-[336px] w-full bg-grayscale-800 rounded-md lg:p-4 p-6 space-y-[8px]">
              <div className="flex items-center justify-between">
                <div className="flex items-center justify-center w-[44px] h-[44px] bg-grayscale-900 rounded-sm">
                  <svg
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="44" height="44" rx="4" fill="black" />
                    <path d="M19 13H25.8V14H19V13Z" fill="#ADADAD" />
                    <path d="M18 15V14H19V15H18Z" fill="#ADADAD" />
                    <path d="M17 16V15H18V16H17Z" fill="#ADADAD" />
                    <path d="M17 21.2H16V16H17V21.2Z" fill="#ADADAD" />
                    <path d="M18 22.2002H17V21.2002H18V22.2002Z" fill="#ADADAD" />
                    <path d="M19 23.2002H18V22.2002H19V23.2002Z" fill="#ADADAD" />
                    <path d="M20.8 27.0002H19.8V24.2002H19V23.2002H20.8V27.0002Z" fill="#ADADAD" />
                    <path d="M20.8008 28V27H21.8008V28H20.8008Z" fill="#ADADAD" />
                    <path d="M20.8008 30H19.8008V28H20.8008V30Z" fill="#ADADAD" />
                    <path d="M24.0008 30H20.8008V31H24.0008V30Z" fill="#ADADAD" />
                    <path
                      d="M24 30H25V24.2H25.8V23.2H26.8V22.2H27.8V21.2H28.8V16H27.8V15H26.8V14H25.8V15H26.8V16H27.8V21.2H26.8V22.2H25.8V23.2H24V30Z"
                      fill="#ADADAD"
                    />
                    <path
                      d="M23.3996 16.6001H21.3996V17.4001H20.5996V19.4001H21.3996V20.2001H23.3996V19.4001H24.1996V17.4001H23.3996V16.6001Z"
                      fill="#ADADAD"
                    />
                  </svg>
                </div>
                {stages?.last.keyClaimingStatus === 'Live' && (
                  <div className="py-1 px-3 leading-none rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="liveS" />
                    <span className="text-xss text-grayscale-100">Live</span>
                  </div>
                )}

                {stages?.last.keyClaimingStatus === 'Soon' && (
                  <div className="py-1 px-3 leading-none rounded-md bg-system-warning-dark border-system-warning flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="comingSoonS" />
                    <span className="text-xss text-grayscale-100">Soon</span>
                  </div>
                )}
                {stages?.last.keyClaimingStatus === 'Expired' && (
                  <div className="py-1 px-3 leading-none rounded-md bg-system-error-dark border-system-error flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="expired" />
                    <span className="text-xss text-grayscale-100">Expired</span>
                  </div>
                )}
              </div>

              <div className="text-sm text-grayscale-400 lg:text-base">
                {stages?.last?.stageName} - Keys Claiming:
              </div>

              <div className="text-grayscale-100 lg:text-[24px] text-lg font-bold">
                {stages?.last.keyClaimingStatus === 'Soon' && (
                  <div className="flex flex-col  space-y-2">
                    <p>
                      <CountdownTimer targetDate={stages?.last?.keyClaimStartDate} />
                    </p>
                    {/* <p className="text-xs font-normal text-start text-[#ADADAD]">
                      Season 1 Tasks concluded on Feb 26, 2025.
                    </p> */}
                  </div>
                )}

                {stages?.last.keyClaimingStatus === 'Live' && (
                  <div className="flex flex-col  space-y-2">
                    <p>
                      <CountdownTimer targetDate={stages?.last?.keyClaimEndDate} />
                    </p>
                    <div className="float-left">
                      <Button
                        type="primary"
                        size="xs"
                        onClick={() =>
                          navigate(`/enclave/${stages?.last?.collectionId}/${stages?.last?.id}`)
                        }
                      >
                        Claim Keys
                      </Button>
                    </div>
                  </div>
                )}

                {stages?.last.keyClaimingStatus === 'Expired' && (
                  <div className="flex flex-col  space-y-2">
                    <p>
                      {new Date(stages?.last?.keyClaimEndDate).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                      })}
                    </p>
                    {/* <p className="text-xs font-normal text-start text-[#ADADAD]">
                      Season 1 Tasks concluded on Feb 26, 2025.
                    </p> */}
                  </div>
                )}
              </div>
            </div>
          )}

          {stages?.active && (
            <div className="lg:w-[336px] w-full bg-grayscale-800 rounded-md lg:p-4 p-6 space-y-[8px]">
              <div className="flex items-center justify-between">
                <div className="flex items-center justify-center w-[44px] h-[44px] bg-grayscale-900 rounded-sm">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M19 3H5V5H3V19H5V21H19V19H21V5H19V3ZM19 5V19H5V5H19ZM11 7H13V13H17V15L13 15H11V7Z"
                      fill="#ADADAD"
                    />
                  </svg>
                </div>
                {stages?.active.status === 'Live' && (
                  <div className="py-1 px-3 leading-none rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="liveS" />
                    <span className="text-xss text-grayscale-100">Live</span>
                  </div>
                )}

                {stages?.active.status === 'Soon' && (
                  <div className="py-1 px-3 leading-none rounded-md bg-system-warning-dark border-system-warning flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="comingSoonS" />
                    <span className="text-xss text-grayscale-100">Soon</span>
                  </div>
                )}

                {stages?.active.status === 'Expired' && (
                  <div className="py-1 px-3 leading-none rounded-md bg-system-error-dark border-system-error flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="expired" />
                    <span className="text-xss text-grayscale-100">Expired</span>
                  </div>
                )}
              </div>

              <div className="text-sm text-grayscale-400 lg:text-base">
                {stages?.active.stageName} - Time Remaining:
              </div>

              <div className="text-grayscale-100 lg:text-[24px] text-lg font-bold">
                {stages?.active.status === 'Live' ? (
                  <div className="flex flex-col  space-y-2">
                    <p>
                      <CountdownTimer targetDate={stages?.active?.endTimestamp} />
                    </p>
                  </div>
                ) : null}

                {stages?.active.status === 'Soon' ? (
                  <CountdownTimer targetDate={stages?.last?.endTimestamp} />
                ) : null}
              </div>
            </div>
          )}

          {stages && !stages.active && stages.next && (
            <div className="lg:w-[336px] w-full bg-grayscale-800 rounded-md lg:p-4 p-6 space-y-[8px]">
              <div className="flex items-center justify-between">
                <div className="flex items-center justify-center w-[44px] h-[44px] bg-grayscale-900 rounded-sm">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M19 3H5V5H3V19H5V21H19V19H21V5H19V3ZM19 5V19H5V5H19ZM11 7H13V13H17V15L13 15H11V7Z"
                      fill="#ADADAD"
                    />
                  </svg>
                </div>
                {stages?.next.status === 'Live' && (
                  <div className="py-1 px-3 leading-none rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="liveS" />
                    <span className="text-xss text-grayscale-100">Live</span>
                  </div>
                )}

                {stages?.next.status === 'Soon' && (
                  <div className="py-1 px-3 leading-none rounded-md bg-system-warning-dark border-system-warning flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="comingSoonS" />
                    <span className="text-xss text-grayscale-100">Soon</span>
                  </div>
                )}

                {stages?.next.status === 'Expired' && (
                  <div className="py-1 px-3 leading-none rounded-md bg-system-error-dark border-system-error flex gap-1.5 items-center border w-fit">
                    <IconSvg icon="expired" />
                    <span className="text-xss text-grayscale-100">Expired</span>
                  </div>
                )}
              </div>

              <div className="text-sm text-grayscale-400 lg:text-base">
                {stages?.next.stageName} - Time Remaining:
              </div>

              <div className="text-grayscale-100 lg:text-[24px] text-lg font-bold">
                {stages?.next.status === 'Soon' ? (
                  <CountdownTimer targetDate={stages?.next?.startTimestamp} />
                ) : null}
              </div>
            </div>
          )}
        </div>
      </section>

      <div className="border-t-2 border-grayscale-700 mb-[40px]" ref={cardContainerRef}></div>

      <section className="mb-[40px]">
        <div className="space-y-[10px] mb-[32px]">
          <h3 className="uppercase text-brand-primary-light text-[32px]">
            Earn points, unlock rewards!
          </h3>

          <p className="text-sm text-grayscale-200">
            Complete fun tasks to climb the leaderboard and reap exclusive prizes through loot
            boxes!
          </p>
        </div>

        <div className="flex justify-between items-center px-5 py-3 mb-5 rounded-md lg:mb-10 bg-grayscale-800">
          <div className="flex gap-2">
            {tabs.map(tab => (
              <div
                key={tab.value}
                onClick={() => handleTaskTabChange(tab.value)}
                className={`px-4 py-2 rounded cursor-pointer select-none ${
                  activeTab === tab.value ? 'bg-grayscale-600' : ''
                }`}
              >
                <div
                  className={`text-xs lg:text-sm font-semibold ${
                    activeTab === tab.value ? 'text-grayscale-200' : 'text-gray-300'
                  }`}
                >
                  {tab.label}{' '}
                  {tab.count
                    ? `(${
                        updatedCards.filter(card => {
                          switch (tab.value) {
                            case 'to-do':
                              return card.status === 'To Do';
                            case 'done':
                              return card.status === 'Done';
                            case 'all':
                            default:
                              return true;
                          }
                        }).length
                      })`
                    : ''}
                </div>
              </div>
            ))}
          </div>

          <div className="hidden items-center space-x-4 lg:flex">
            {/* {isAllowedUser && (
              <button
                onClick={handleCopyLink}
                className="flex items-center gap-2 p-2 lg:px-4 lg:py-[8px] text-sm rounded-md bg-grayscale-700 w-fit text-grayscale-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.5 2H7.50009H8.5H8.50009V4.00001H9.50003H10.4999H10.5V11H10.4999V11H3.49994V11V10V9.00005H1.5V9V8.00005V3V2H2.5ZM7.50009 4.00001V3H2.5V8.00005H3.49994V5.00001V4.00001H4.49994H7.50009ZM4.49994 9.00005V10H9.50003V5.00001H4.49994V8.00005H4.5V9.00005H4.49994Z"
                    fill="#EEE0F9"
                  />
                </svg>
                <span className="hidden lg:block">Referral Link</span>
              </button>
            )} */}

            {/* )} */}
            <EnclaveClaimBtn
              status="claimable"
              onClick={() => getCompletedChallenges(address, collectionSlug)}
            />
          </div>
        </div>

        <div className="grid gap-[18px] grid-cols-[repeat(auto-fit,minmax(320px,1fr))]">
          {currentCards.map((card, index) => (
            <EnclavePointsCard
              key={card.slug}
              status={card.status as CardStatus}
              bgImage={card.bgImage}
              phase={card.phase}
              pointsToEarn={card.pointsToEarn}
              title={card.title}
              description={card.description}
              slug={card.slug}
              user={user}
              currentSlug={currentSlug}
              onEnter={handleEnter}
              handleConnectX={handleConnectX}
              onClaim={() => console.log('onClaim')}
              isClaimed={card?.isClaimed}
              twitterConnected={twitterConnected}
              isEnterLoading={isEnterLoading}
              isLoggedInMultiversX={isLoggedInMultiversX}
              handleCopyLink={handleCopyLink}
            />
          ))}

          {currentCards.length === 0 && (
            <div className="px-5 py-[15px] rounded-md items-center bg-grayscale-800 h-[90px] flex">
              <h5 className="text-brand-primary-light">No tasks found</h5>
            </div>
          )}

          {/* {activeTab === 'all' && window.innerWidth > 1024 && (
            <>
              <img src="/assets/images/tasks/blur.png" className="h-full" />
              <img src="/assets/images/tasks/blur.png" className="h-full" />
            </>
          )} */}
        </div>

        {/* Pagination buttons (only for width <= 1024) */}
        {window.innerWidth <= 1024 && totalPages > 1 && (
          <div className="flex justify-end mt-[18px] gap-1">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`h-[40px] w-[40px] flex items-center justify-center text-xs rounded border ${
                  currentPage === index + 1
                    ? 'bg-grayscale-800 text-white border-transparent'
                    : 'border-grayscale-800 text-grayscale-500'
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}
      </section>

      <div className="border-t-2 border-grayscale-700 mb-[40px]"></div>

      <section className="mb-[40px]">
        <div className="space-y-[10px] mb-[32px]">
          <h3 className="text-brand-primary-light text-[32px]">Leaderboard</h3>

          <p className="text-sm text-grayscale-200">
            Complete tasks, earn points and secure your spot at the top for exclusive loot box
            prizes!
          </p>
        </div>

        <div className="flex justify-between items-center px-5 py-3 mb-3 rounded-md bg-grayscale-800">
          <div className="flex gap-2">
            {leaderBoardTabs.map(tab => (
              <div
                key={tab.value}
                onClick={() => setActiveLeaderBoardTab(tab.value)}
                className={`px-4 py-2 rounded cursor-pointer select-none ${
                  activeLeaderBoardTab === tab.value ? 'bg-grayscale-600' : ''
                }`}
              >
                <div
                  className={`text-xs lg:text-sm font-semibold ${
                    activeLeaderBoardTab === tab.value ? 'text-grayscale-200' : 'text-gray-300'
                  }`}
                >
                  {tab.label}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div>
          {topUsers.filter(user => user.walletAddress.toLowerCase().includes(searchQuery)).length >
            0 && (
            <div className="grid grid-cols-2 mb-3 lg:grid-cols-15">
              <div className="col-span-1 pl-5 text-sm lg:col-span-2 text-grayscale-300">Rank</div>

              <div className="col-span-1 pl-4 text-sm lg:col-span-11 text-grayscale-300 text-end lg:text-start">
                Username
              </div>

              <div className="hidden col-span-full pr-5 text-sm lg:col-span-2 text-grayscale-300 text-end lg:block">
                Points
              </div>
            </div>
          )}

          {topUsers
            .sort((a, b) => (a.key === 'YOU' ? -1 : b.key === 'YOU' ? 1 : a.rank - b.rank))
            .filter(user => user.walletAddress.toLowerCase().includes(searchQuery))
            .map(user => (
              <LeaderBoardCard
                key={user.rank}
                place={user.rank}
                loggedUserRank={user.loggedUserRank}
                wallet={user.walletAddress}
                points={user.totalPoints}
                isCurrentUser={user.key === 'YOU'}
              />
            ))}

          {topUsers.filter(user => user.walletAddress.toLowerCase().includes(searchQuery))
            .length === 0 && (
            <div className="px-5 py-[15px] rounded-md items-center bg-grayscale-800 h-[90px] flex">
              <h5 className="text-brand-primary-light">No participants found</h5>
            </div>
          )}
        </div>
      </section>

      <div className="border-t-2 border-grayscale-700 mb-[40px]"></div>

      <section className="mb-[40px]">
        <div className="space-y-[10px] lg:mb-[32px] mb-[24px]">
          <h3 className="text-brand-primary-light text-[32px]">Weekly rewards</h3>

          <p className="text-sm text-grayscale-200">
            Each week unlocks more rewards to be claimed!
          </p>
        </div>

        {window.innerWidth > 1024 ? (
          <div className="grid gap-[18px] grid-cols-[repeat(auto-fit,minmax(326px,1fr))]">
            {enclaveMainPrizesData.map((card, index) => (
              <EnclaveRewardCard
                key={index}
                name={card.name}
                estimatedValue={card.estimatedValue}
                rarity={card.rarity}
                image={card.image}
                size="medium"
                blurName={true}
              />
            ))}
          </div>
        ) : (
          <Slider {...sliderSettings}>
            {enclaveMainPrizesData.map((card, index) => (
              <div className="!block mx-auto px-3" key={index}>
                <EnclaveRewardCard
                  key={index}
                  name={card.name}
                  estimatedValue={card.estimatedValue}
                  rarity={card.rarity}
                  image={card.image}
                  size="medium"
                  blurName={true}
                />
              </div>
            ))}
          </Slider>
        )}
      </section>
    </>
  );
}
