import { useState, useEffect } from "react";
import PropTypes from "prop-types";

const useCountdown = (targetDate) => {
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(targetDate));

  useEffect(() => {
    if (!targetDate) return;
    
    const interval = setInterval(() => {
      setTimeLeft(getTimeLeft(targetDate));
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return formatTimeLeft(timeLeft);
};

const getTimeLeft = (targetDate) => {
  const now = new Date().getTime();
  const countdownTime = new Date(targetDate).getTime();
  const diff = countdownTime - now;

  if (diff <= 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

  return {
    days: Math.floor(diff / (1000 * 60 * 60 * 24)),
    hours: Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((diff % (1000 * 60)) / 1000),
  };
};

const formatTimeLeft = ({ days, hours, minutes, seconds }) => {
  return `${days}d : ${hours}h : ${minutes}m : ${seconds}s`;
};

const CountdownTimer = ({ targetDate }) => {
  const countdown = useCountdown(targetDate);

  return (
    <div className="text-grayscale-100 lg:text-[24px] text-lg font-bold">
      {countdown}
    </div>
  );
};

CountdownTimer.propTypes = {
  targetDate: PropTypes.string.isRequired,
};

export default CountdownTimer;