import React, { useEffect, useRef, useState } from 'react';
import { ethers } from 'ethers';
import { useHttp } from 'src/hooks';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import IconSvg from 'src/components/common/ui/IconSvg';
import { slideProp } from 'react-stacked-center-carousel/dist/interfaces';
import { StackedCarousel, ResponsiveContainer } from 'react-stacked-center-carousel';
import AvaxCollectionBanner from './components/AvaxCollectionBanner';
import { Collection } from 'src/components/common/cards/types/types';
import { useAccount, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import Button from 'src/components/button/Button';
import SyncLoader from 'react-spinners/SyncLoader';

import LootyNftAbi from '../../../contracts/NFTLottery.json';
import { avaxRewardCardData } from 'src/dummydata';
import AvaxClaimNftLotteryModal from './components/AvaxClaimNftLotteryModal';
import RewardCard from 'src/components/common/cards/RewardCard';
import rewardContracts from '../../../contracts/avax/AvaxRewardContract.json';
import AvaxRewardModal from "./components/AvaxRewardModal.tsx";

const environment = process.env.REACT_APP_ENVIRONMENT;

const KEY_NAMES = {
  0: 'Bronze Key',
  1: 'Silver Key',
  2: 'Gold Key',
  3: 'Platinum Key',
  4: 'Diamond Key',
} as const;

const data = avaxRewardCardData.map((reward, index) => {
  return {
    cover: reward.image,
    name: reward.name,
  };
});

interface RewardEvent {
  user: string;
  requestId: string;
  nftId?: string;
}

function AvaxOpenbox() {
  const { address, isConnected } = useAccount();
  const { sendRequest } = useHttp();
  const { collectionName } = useParams();

  const [collectionDetail, setCollectionDetail] = useState<Collection>();
  const [keyIds, setKeyIds] = useState<number[]>([0, 1, 2, 3, 4]);

  const [selectedKey, setSelectedKey] = useState<number | null>(null);
  const [rewardClaimedData, setRewardClaimedData] = useState<any>(null);

  const [userLotteryData, setUserLotteryData] = useState<any>(null);
  const [userLotteryData2, setUserLotteryData2] = useState<any>(null);
  const [isClaimingNft, setIsClaimingNft] = useState(false);

  const [isPauseNftContract, setIsPauseNftContract] = useState<boolean | null>(null);
  const [isLoadingKeys, setLoadingKeys] = useState<boolean | null>(null);

  const [isClaimNftModal, setIsClaimNftModal] = useState(false);
  const [isRerolling, setIsRerolling] = useState(false);

  const [isRerollingNft, setIsRerollingNft] = useState(false);
  const [isSuccessRedeemedModalOpen, setIsSuccessRedeemedModalOpen] = useState(false);

  const [loadingOpenBoxBtn, setLoadingOpenBoxBtn] = useState(false);

  const [blockchainKeyBalances, setBlockchainKeyBalances] = useState<Record<number, number>>({});

  const stackedCarouselRef = useRef<StackedCarousel>(); // Ref to StackedCarousel
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0); // Keep track of active slide
  const [spinning, setSpinning] = useState<boolean>(false); // Track if the carousel is spinning
  const spinIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [selectedNft, setSelectedNft] =
    useState<{ cover: string; name: string; credits: number } | null>(null);

  const {
    data: hashOpenbox,
    writeContract: openLootboxContractWrite,
    isPending: isOpening,
    error: errorLootbox,
    isError: isError,
  } = useWriteContract();
  const {
    isLoading: isConfirmingOpenBox,
    isSuccess: isConfirmedOpenBox,
    data: receiptOpenBox,
    error: errorLootBoxTxnReceipt,
  } = useWaitForTransactionReceipt({
    hash: hashOpenbox,
  });

  const {
    data: hashClaimNft,
    writeContract: claimNftContractWrite,
    isPending: isOpeningNftLottery,
    isError: isErrorClaimNft,
  } = useWriteContract();

  const {
    isLoading: isConfirmingClaimNft,
    isSuccess: isConfirmedClaimNft,
    data: receiptClaimNft,
  } = useWaitForTransactionReceipt({
    hash: hashClaimNft,
  });

  useEffect(() => {
    console.log('isConfirmedClaimNft', isConfirmedClaimNft);
    console.log('isConfirmingClaimNft', isConfirmingClaimNft);
    console.log('receiptClaimNft', receiptClaimNft);
    console.log('isErrorClaimNft', isErrorClaimNft);
    if (isConfirmedClaimNft) {
      // setIsClaimingNft(false);
    }
  }, [isConfirmingClaimNft, isConfirmedClaimNft]);

  const {
    data: hashReroll,
    writeContract: RerollContractWrite,
    isPending: isPendingReroll,
    isError: isErrorReroll,
  } = useWriteContract();

  const {
    isLoading: isConfirmingReroll,
    isSuccess: isConfirmedReroll,
    data: receiptReroll,
  } = useWaitForTransactionReceipt({
    hash: hashReroll,
  });

  useEffect(() => {
    console.log('================================');
    console.log('isConfirmingReroll', isConfirmingReroll);
    console.log('isConfirmedReroll', isConfirmedReroll);
    console.log('receiptReroll', receiptReroll);
    console.log('isPendingReroll', isPendingReroll);
    console.log('isErrorReroll', isErrorReroll);
    console.log('================================');
  }, [isConfirmedReroll, isConfirmingReroll]);

  const {
    data: hashSetApprovedForAll,
    writeContract: writeApprovelForAll,
    isPending: isPendingApprovalForAll,
  } = useWriteContract();

  const {
    isLoading: isConfirmingApprovalForAll,
    isSuccess: isConfirmedApprovalForAll,
    data: receiptApprovalForAll,
  } = useWaitForTransactionReceipt({
    hash: hashSetApprovedForAll,
  });

  useEffect(() => {
    console.log('isConfirmedOpenBox', isConfirmedOpenBox);
    console.log('isConfirmingOpenBox', isConfirmingOpenBox);
    console.log('errorLootbox', errorLootbox);
    console.log('errorLootBoxTxnReceipt', errorLootBoxTxnReceipt);

    console.log('receiptOpenBox', receiptOpenBox);
    if (isConfirmedOpenBox && receiptOpenBox) {
      // setLoadingOpenBoxBtn(false); // Ensure loading state is reset
      // stopSpin(0); // Ensure spinning stops
      getUserData(); // Update user data after the transaction
      // handleOpenBox();
    }
    console.log('isError', isError);
    if (isError || errorLootBoxTxnReceipt) {
      setLoadingOpenBoxBtn(false); // Stop loader on error
      stopSpin(0);
      toast.error('Execution reverted for an unknown reason.');
    }

    console.log('isErrorReroll', isErrorReroll);
    if (isErrorReroll) {
      setLoadingOpenBoxBtn(false); // Stop loader on error
      setIsRerollingNft(false);
    }

    console.log('isErrorClaimNft', isErrorClaimNft);
    if (isErrorClaimNft) {
      setLoadingOpenBoxBtn(false); // Stop loader on error
      setIsClaimingNft(false);
    }
  }, [
    isConfirmingOpenBox,
    isConfirmedOpenBox,
    receiptOpenBox,
    isError,
    isErrorReroll,
    errorLootBoxTxnReceipt,
    isErrorClaimNft,
  ]);

  const handleCardClick = (index: number) => {
    setSelectedKey(index);
  };

  const getCollectionDetail = async () => {
    await sendRequest(
      {
        url: `collections/key-claiming-stages/avax`,
        method: 'GET',
      },
      (data: any) => {
        setCollectionDetail(data);
      },
    );
  };

  const openRedeemedModal = () => {
    setIsClaimNftModal(false);
    setIsSuccessRedeemedModalOpen(true);
  };

  const fetchBlockchainKeyBalances = async () => {
    if (!address) return;

    const updatedBlockchainKeys: Record<number, number> = {};
    setLoadingKeys(true);
    // Fetch the balance for each key (0 to 4)
    for (let keyIndex = 0; keyIndex < 5; keyIndex++) {
      const balance = await readKeyBalance(address, keyIndex);
      updatedBlockchainKeys[keyIndex] = balance;
    }
    setLoadingKeys(false);

    console.log('updatedBlockchainKeys', updatedBlockchainKeys);

    setBlockchainKeyBalances(updatedBlockchainKeys);
  };

  useEffect(() => {
    if (address && isConnected && collectionDetail) {
      fetchBlockchainKeyBalances();
      getUserData();
      getUserData2('on load');

      // getUserData();
    }
  }, [collectionDetail, address, isConnected]);

  useEffect(() => {
    if (address && isConnected) {
      getCollectionDetail();
    }
  }, [address, isConnected, collectionName]);

  useEffect(() => {
    if (userLotteryData2) {
      console.log('data2', userLotteryData2.state)
    }
  }, [userLotteryData2]);

  const readKeyBalance = async (walletAddress: string, keyIndex: number) => {
    const phase1Config = collectionDetail?.stages?.active[0]?.metadata[environment];
    console.log('phase1Config', phase1Config);
    if (!phase1Config || Object.keys(phase1Config).length === 0) return;

    try {
      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);

      const contract = new ethers.Contract(
        phase1Config.LOOTY_KEY.address,
        phase1Config.LOOTY_KEY.abi,
        provider,
      );
      console.log('contract', contract);
      console.log('walletAddress', walletAddress);
      console.log('keyIndex', keyIndex);
      const balance = await contract.balanceOf(walletAddress, keyIndex);
      console.log(`keyBalance ${Number(balance)} || keyIndex: ${keyIndex}`);
      return Number(balance);
    } catch (error) {
      console.error('Error reading balance:', error);
      return 0;
    }
  };

  const getUserData = async () => {
    try {
      const phase1Config = collectionDetail?.stages?.active[0]?.metadata[environment];
      if (!phase1Config || Object.keys(phase1Config).length === 0) return;

      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
      const contract = new ethers.Contract(
        phase1Config.LOOTY_TOKEN_LOTTERY.address,
        phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        provider,
      );
      const userData = await contract.userLotteryData(address);
      const isPause = await contract.paused();
      console.log('getUserData() userLotteryData', userData);
      console.log('getUserData() candidateRewardTypeIndex', userData?.candidateRewardTypeIndex);
      setIsPauseNftContract(isPause);

      setUserLotteryData(userData);
      if (userData?.state === 2) {
        const rewardType = await contract.rewardTypes(userData?.candidateRewardTypeIndex);
        console.log('getUserData() rewardType', rewardType);
        setRewardClaimedData({
          user: address,
          rewardContract: rewardType.rewardContract,
          tokenIdOrAmount: rewardType.isNFT
            ? userData?.candidateTokenIdIfNFT
            : rewardType.amountIfToken,
          isNFT: rewardType.isNFT,
        });
      }
    } catch (error) {
      console.error('Error reading balance:', error);
    }
  };
  const getUserData2 = async (loc: string) => {
    try {
      const phase1Config = collectionDetail?.stages?.active[0]?.metadata[environment];
      if (!phase1Config || Object.keys(phase1Config).length === 0) return;

      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
      const contract = new ethers.Contract(
        phase1Config.LOOTY_TOKEN_LOTTERY.address,
        phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        provider,
      );
      const userData = await contract.userLotteryData(address);
      console.log('data2 loc', loc, userData.state)
      setUserLotteryData2(userData);

      const isPause = await contract.paused();
      setIsPauseNftContract(isPause);
    } catch (error) {
      console.error('Error reading user data:', error);
    }
  };

  const openLootbox = async () => {
    if (!isConnected) {
      toast.error('Please connect your wallet before opening the box.');
      return;
    }

    if (selectedKey === null) {
      toast.error('Please select a key to open the box.');
      return;
    }

    console.log('selectedKey', selectedKey);
    console.log('blockchainKeyBalances', blockchainKeyBalances);
    if (blockchainKeyBalances[selectedKey] === 0) {
      toast.error('No keys available to open this box.');
      return;
    }

    if (isPauseNftContract) {
      toast.error('Contract is Paused, contact admin');
      return;
    }
    const contractConfig = collectionDetail?.stages?.active[0]?.metadata[environment];
    if (!contractConfig || Object.keys(contractConfig).length === 0) return;

    try {
      console.log('selectedKey', selectedKey);
      console.log('contractConfig', contractConfig);
      console.log(`startLottery......`);
      startSpin(); // Start the carousel spin

      setLoadingOpenBoxBtn(true);
      await openLootboxContractWrite({
        address: contractConfig.LOOTY_TOKEN_LOTTERY.address,
        abi: contractConfig.LOOTY_TOKEN_LOTTERY.abi,
        functionName: 'startLottery',
        args: [selectedKey],
      });
      // }
      //  else {
      //   console.log('writeApprovelForAll')
      //   await writeApprovelForAll({
      //     address:phase1Config.LOOTY_KEY.address,
      //     abi:phase1Config.LOOTY_KEY.abi,
      //     functionName: 'setApprovalForAll',
      //     args: [process.env.REACT_APP_LOOTY_AVAX_CONTRACT_ADDRESS, true],
      //   });
      // }
    } catch (error) {
      setLoadingOpenBoxBtn(false);
      stopSpin(0);
      console.log('Error opening Lootbox:', error);
      toast.error('Failed to open Lootbox.');
    }
  };

  useEffect(() => {
    if (address && collectionDetail) {
      const phase1Config = collectionDetail?.stages?.active[0]?.metadata[environment];
      if (!phase1Config || Object.keys(phase1Config).length === 0) return;

      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
      const contract = new ethers.Contract(
        phase1Config.LOOTY_TOKEN_LOTTERY.address,
        phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        provider,
      );

      const onClaimed = async (user: string, requestId: string) => {
        console.log(
          `onClaimed, user: ${user}, requestId: ${requestId}, userLotteryData: ${userLotteryData}`,
        );
        if (user.toLowerCase() === address?.toLowerCase() && userLotteryData) {
          const rewardType = await contract.rewardTypes(userLotteryData?.candidateRewardTypeIndex);
          setRewardClaimedData({
            user,
            requestId,
            rewardContract: rewardType.rewardContract,
            tokenIdOrAmount: rewardType.isNFT
              ? userLotteryData?.candidateTokenIdIfNFT
              : rewardType.amountIfToken,

            isNFT: rewardType.isNFT,
          });

          if (rewardType.isNFT) {
            toast.success('NFT Claimed Successfully');
          }
          //  else {
          //   toast.success(
          //     `Claimed ${ethers.utils.formatUnits(rewardType.amountIfToken, 18)} ${
          //       rewardContracts && rewardContracts[rewardType?.rewardContract?.toLowerCase()]
          //         ? rewardContracts[rewardType?.rewardContract?.toLowerCase()]?.collectionName
          //         : ''
          //     }`,
          //   );
          // }

          getUserData();
          getUserData2('on claimed');
          console.log('nft deb: Claimed')
          stopSpin(0);
        }
      };

      contract.on('Claimed', onClaimed);

      return () => {
        contract.off('Claimed', onClaimed);
      };
    }
  }, [address, collectionDetail, userLotteryData]);

  const claimNft = async () => {
    const phase1Config = collectionDetail?.stages?.active[0]?.metadata[environment];
    if (!phase1Config || Object.keys(phase1Config).length === 0) return;

    console.log('claimNft');
    if (!isConnected) {
      toast.error('Please connect your wallet before claiming the NFT.');
      return;
    }

    // if (Number(userLotteryData?.state) === 2) {
    //   setIsClaimNftModal(false);
    // }

    try {
      console.log('phase1Config', phase1Config);
      setIsClaimingNft(true); // Start loader
      await claimNftContractWrite({
        address: phase1Config.LOOTY_TOKEN_LOTTERY.address,
        abi: phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        functionName: 'claimReward',
      });
    } catch (error) {
      console.log('Error Claiming NFT:', error);
      toast.error('Failed to Claim NFT.');
      setIsClaimingNft(false);
    }
  };

  useEffect(() => {
    if (address && collectionDetail) {
      const phase1Config = collectionDetail?.stages?.active[0]?.metadata[environment];
      if (!phase1Config || Object.keys(phase1Config).length === 0) return;

      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
      const contract = new ethers.Contract(
        phase1Config.LOOTY_TOKEN_LOTTERY.address,
        phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        provider,
      );

      const onRolled = (user: string, requestId: ethers.BigNumber) => {
        console.log(
          `onRolled, user: ${user}, requestId: ${requestId}, userLotteryData: ${userLotteryData}`,
        );

        if (user.toLowerCase() === address?.toLowerCase()) {
          getUserData();
          getUserData2('on Rolled');
          // fetchBlockchainKeyBalances();
          // setIsRerollingNft(false);
        }
      };

      contract.on('Rolled', onRolled);

      return () => {
        contract.off('Rolled', onRolled);
      };
    }
  }, [address, collectionDetail]);

  const rerollNft = async () => {
    const phase1Config = collectionDetail?.stages?.active[0]?.metadata[environment];
    if (!phase1Config || Object.keys(phase1Config).length === 0) return;

    if (!isConnected) {
      toast.error('Please connect your wallet before re-rolling the NFT.');
      return;
    }

    try {
      setIsRerolling(true);
      setIsRerollingNft(true);
      startSpin();
      await RerollContractWrite({
        address: phase1Config.LOOTY_TOKEN_LOTTERY.address,
        abi: phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        functionName: 'reroll',
      });
    } catch (error) {
      console.log('Error Re-rolling NFT:', error);
      toast.error('Failed to re-roll NFT.');
      setIsRerollingNft(false);
    }
  };

  const startSpin = () => {
    console.log('startSpin().......');
    if (spinIntervalRef.current) return; // Prevent multiple intervals
    console.log('startSpin() cross');
    setSpinning(true);
    spinIntervalRef.current = setInterval(() => {
      if (stackedCarouselRef.current) {
        stackedCarouselRef.current.goNext(); // Move to next slide
      }
    }, 350); // Spin with 350ms delay
  };

  const stopSpin = (targetCredits: number) => {
    console.log('stopSpin()......', targetCredits);
    console.log('spinning', spinning);
    // if (!spinning) return;
    console.log('spinIntervalRef.current', spinIntervalRef.current);
    if (spinIntervalRef.current) {
      console.log('tututtartara');
      clearInterval(spinIntervalRef.current); // Clear the interval
      spinIntervalRef.current = null;
    }

    console.log('setSpinning(false)');
    setSpinning(false);

    // Find the slide with the matching credits
    const targetSlideIndex = data.findIndex(item => item.credits === targetCredits);
    console.log('targetSlideIndex', targetSlideIndex);

    if (targetSlideIndex !== -1 && stackedCarouselRef.current) {
      console.log('aaayooooo');
      const steps = (targetSlideIndex + data.length - activeSlideIndex) % data.length;
      console.log('stpes', steps);
      stackedCarouselRef.current.swipeTo(steps); // Swipe to the selected NFT slide
    }
  };

  useEffect(() => {
    if (address && collectionDetail) {
      const phase1Config = collectionDetail?.stages?.active[0]?.metadata[environment];
      if (!phase1Config || Object.keys(phase1Config).length === 0) return;

      const provider = new ethers.providers.JsonRpcProvider(collectionDetail?.network.RPCUrl);
      const contract = new ethers.Contract(
        phase1Config.LOOTY_TOKEN_LOTTERY.address,
        phase1Config.LOOTY_TOKEN_LOTTERY.abi,
        provider,
      );

      const onFulfilled = async (user: string, requestId: string) => {
        console.log(
          `onFulfilled, user: ${user}, requestId: ${requestId}, userLotteryData: ${userLotteryData}`,
        );
        if (user.toLowerCase() === address?.toLowerCase() && userLotteryData) {
          const rewardType = await contract.rewardTypes(userLotteryData?.candidateRewardTypeIndex);
          console.log('onFullfilled rewardType', rewardType);
          setRewardClaimedData({
            user,
            requestId,
            rewardContract: rewardType.rewardContract,
            tokenIdOrAmount: rewardType.isNFT
              ? userLotteryData?.candidateTokenIdIfNFT
              : rewardType.amountIfToken,

            isNFT: rewardType.isNFT,
          });

          getUserData();
          getUserData2('on fullfilled');
          fetchBlockchainKeyBalances();
        }
      };

      console.log('onFulfilled calling...........');
      contract.on('Fulfilled', onFulfilled);

      return () => {
        contract.off('Fulfilled', onFulfilled);
      };
    }
  }, [address, collectionDetail, userLotteryData]);

  useEffect(() => {
    // console.log('isRerolling123', isRerolling)
    console.log('user state =>', userLotteryData?.state);

    if ((loadingOpenBoxBtn && userLotteryData?.state === 0) || userLotteryData?.state === 2) {
      const randomIndex = Math.floor(Math.random() * data.length);
      console.log('randomIndex', randomIndex);
      const selectedItem = data[randomIndex];
      console.log('data[randomIndex]', data[randomIndex]);
      setSelectedNft(selectedItem);

      console.log('selectedItem.credits', selectedItem.credits);
      stopSpin(selectedItem.credits);
      // toast.success('Reward Claimed Successfully');
      setIsRerolling(true);
      setIsClaimingNft(false);
      setLoadingOpenBoxBtn(false);
      getUserData();
      fetchBlockchainKeyBalances();

      setIsRerollingNft(false);
      // toast.success('Arena Token Claimed Successfully');
    }
  }, [userLotteryData?.state]);

  const handleOpenBox = () => {
    if (address) {
      sendRequest(
        {
          url: `user/add-open-box-points/${address}`,
          method: 'POST',
        },
        (data: any) => {
          console.log(data);
        },
      );
    }
    console.log('Open Box button clicked');
    // Add your logic here
  }; // Ref to StackedCarousel

  const connectAvax = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.enable();
      } else {
        toast.error('Metamask is not detected. Please install the browser extension to proceed.', {
          autoClose: 8000,
        });
      }
    } catch (error) {
      console.error('Connection rejected or failed:', error);
    }
  };

  return (
    <>
      {/*<AvaxClaimNftLotteryModal*/}
      {/*  openRedeemedModal={openRedeemedModal}*/}
      {/*  isOpen={isRerolling || Number(userLotteryData?.state) === 2}*/}
      {/*  onClose={() => setIsRerolling(false)}*/}
      {/*  userLotteryData={userLotteryData}*/}
      {/*  userState={userLotteryData?.state}*/}
      {/*  claimNft={claimNft}*/}
      {/*  isClaimingNft={isClaimingNft}*/}
      {/*  rerollNft={rerollNft}*/}
      {/*  isRerollingNft={isRerollingNft}*/}
      {/*  selectedNft={selectedNft}*/}
      {/*  rewardClaimedData={rewardClaimedData}*/}
      {/*  collectionDetail={collectionDetail}*/}
      {/*  // rerollsLeft={userLotteryData?.rerollsLeft}*/}
      {/*  rerollsLeft={userLotteryData?.rewardsLength}*/}
      {/*/>*/}

      <AvaxRewardModal
        isOpen={isRerolling || Number(userLotteryData?.state) === 2}
        onClose={() => setIsRerolling(false)}
        userLotteryData={userLotteryData2}
        collectionDetail={collectionDetail}
        rerollNft={rerollNft}
        isRerollingNft={isRerollingNft}
        isClaimingNft={isClaimingNft}
        claimNft={claimNft}
      />

      <section className="mb-[36px] xl:mb-[40px]">
        {window.innerWidth > 1024 ? (
          <AvaxCollectionBanner collection={collectionDetail} />
        ) : (
          <div className="w-full bg-gray-800 rounded-md">
            <img src={'/assets/images/avax-banner.png'} className="w-full h-auto max-h-[418px]" />
          </div>
        )}
      </section>

      <section className="pb-[24px] mb-[24px] border-b border-grayscale-700 lg:hidden">
        <div className="flex gap-2.5 items-center  mb-4">
          <div className="w-[60px] h-[60px] lg:w-[109px] lg:h-[109px] rounded-md">
            <img
              src={'/assets/images/avax-logo.png'}
              className="object-contain w-full h-full rounded-md"
            />
          </div>

          <div className="space-y-[8px]">
            <h3 className="uppercase text-brand-primary-light 2xl:text-[32px] text-[17px]">
              {collectionDetail?.name}
            </h3>

            <div>
              <div className="flex gap-[9px] items-center">
                {collectionDetail?.website && (
                  <a href={collectionDetail?.website} target="_blank" rel="noopener noreferrer">
                    <IconSvg
                      icon="webM"
                      className="text-grayscale-200 w-[12px] h-[12px] lg:w-fit lg:h-fit"
                    />
                  </a>
                )}

                {collectionDetail?.twitter && (
                  <a href={collectionDetail?.twitter} target="_blank" rel="noopener noreferrer">
                    <IconSvg
                      icon="twitterM"
                      className="text-grayscale-200 w-[12px] h-[12px] lg:w-fit lg:h-fit"
                    />
                  </a>
                )}

                {collectionDetail?.discord && (
                  <a href={collectionDetail?.discord} target="_blank" rel="noopener noreferrer">
                    <IconSvg
                      icon="discordM"
                      className="text-grayscale-200 w-[12px] h-[12px] lg:w-fit lg:h-fit"
                    />
                  </a>
                )}

                {collectionDetail?.hyperspaceLink && (
                  <a
                    href={collectionDetail?.hyperspaceLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconSvg
                      icon="hyperspaceM"
                      className="text-grayscale-200 w-[12px] h-[12px] lg:w-fit lg:h-fit"
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-3">
          <div className="bg-grayscale-800 bg-opacity-70 py-3 pr-[12px] pl-[18px] rounded-md">
            <div className="mb-2 text-xs text-grayscale-200 text-end">Value of Rewards</div>

            <div className="text-lg text-grayscale-100">≈ $2,118.0</div>
          </div>

          <div className="bg-grayscale-800 bg-opacity-70 py-3 pr-[12px] pl-[18px] rounded-md">
            <div className="mb-2 text-xs text-grayscale-200 text-end">Rewards Claimed</div>

            <div className="flex text-lg text-grayscale-100">0,000/1,350</div>
          </div>
        </div>
      </section>

      <section className="mb-[72px]">
        <div className="mb-10">
          <ResponsiveContainer
            carouselRef={stackedCarouselRef}
            render={(parentWidth, carouselRef) => {
              const currentVisibleSlide = window.innerWidth > 1024 ? 7 : 3;

              return (
                <StackedCarousel
                  ref={carouselRef}
                  slideComponent={Card}
                  slideWidth={window.innerWidth > 1024 ? 268 : 197}
                  carouselWidth={parentWidth}
                  data={data.reverse()}
                  currentVisibleSlide={currentVisibleSlide}
                  maxVisibleSlide={window.innerWidth > 1024 ? 7 : 3}
                  disableSwipe
                />
              );
            }}
          />
        </div>

        <div className="space-y-[32px] flex flex-col items-center justify-center">
          {!address ? (
            <Button onClick={() => connectAvax()} type="secondary" size="m">
              Connect Wallet
            </Button>
          ) : (
            <>
              <div className="flex lg:gap-[18px] gap-[10px] items-center justify-center flex-wrap">
                {keyIds.map((keyIndex, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      blockchainKeyBalances[keyIndex] > 0 && handleCardClick(Number(keyIndex))
                    }
                    className={`w-[224px] lg:w-[268px] rounded-md bg-grayscale-800 lg:p-[15px] lg:pb-[17px] p-[10px] cursor-pointer flex lg:flex-col gap-[18px] lg:items-start items-center lg:justify-start ${
                      selectedKey === Number(keyIndex) && blockchainKeyBalances[keyIndex] > 0
                        ? 'border-2 border-purple-500'
                        : ''
                    }`}
                  >
                    <img
                      src={`/assets/images/arenaKeys/${keyIndex}.png`}
                      className={`lg:w-[238px] lg:h-[238px] w-[46px] h-[46px] rounded ${
                        blockchainKeyBalances[keyIndex] > 0 ? '' : 'opacity-50'
                      }`}
                      alt={`Key ${keyIndex}`}
                    />

                    <div className="space-y-[9px]">
                      <h5 className="uppercase text-[#FAFAFA] lg:text-[18px] text-[14px]">
                        {KEY_NAMES[keyIndex as keyof typeof KEY_NAMES] ?? `Key ${keyIndex}`}
                      </h5>

                      <div className="text-xs text-gray-300 space-x-[7px]">
                        <span>Ready to use:</span>
                        <span className="font-semibold pl-[7px]">
                          {isLoadingKeys ? (
                            <SyncLoader color="#B73FFF" size={5} />
                          ) : (
                            blockchainKeyBalances[keyIndex] ?? 0
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="text-sm text-center text-grayscale-200 lg:text-base">
                <p className="mb-4 lg:mb-0">
                  Open box using your keys and get rewards from your favorite project.
                </p>

                <p className="mb-4">
                  Your NFT will be burned once you use your first key. With each key, you get one
                  reward, but some keys allow <br className="hidden lg:block" /> re-roll for a
                  chance at another reward.
                </p>

                <p className="mb-4 lg:font-bold">
                  Bronze keys don&apos;t include re-rolls, Silver keys include 1 re-roll, Gold
                  keys include 2 re-rolls, Platinum keys include 4 re-rolls and Diamond keys include 6 re-rolls. 
                  <br className="hidden lg:block" /> After each spin, you
                  can either keep your reward or try again until your re-roll limit is reached.
                </p>

                <p>Remember, only one reward can be claimed per key!</p>
              </div>
            </>
          )}

          <div className="mx-auto w-fit">
            {loadingOpenBoxBtn ? (
              <SyncLoader color="#B73FFF" size={10} />
            ) : (
              <div className="flex flex-wrap gap-4 justify-center items-center">
                <Button
                  size="m"
                  type="primary"
                  classNames={`${
                    (blockchainKeyBalances[Number(selectedKey)] ?? 0) < 1
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer'
                  }`}
                  disabled={(blockchainKeyBalances[Number(selectedKey)] ?? 0) < 1}
                  onClick={() => openLootbox()}
                >
                  Open box
                </Button>
              </div>
            )}
          </div>
        </div>
      </section>

      <section>
        <div className="mb-[15px] lg:mb-[36px]">
          <h3 className="mb-2 text-brand-primary-light">Rewards</h3>

          <div className="text-grayscale-200">
          The better the key, the more chances you get at rewards
          </div>
        </div>

        <div className="grid gap-[18px] grid-cols-[repeat(auto-fit,minmax(326px,1fr))]">
          {avaxRewardCardData.map((event, index) => (
            <RewardCard
              key={index}
              name={event?.name}
              image={event?.image}
              size="medium"
              showRarity={false}
            />
          ))}
        </div>
      </section>
    </>
  );
}

export default AvaxOpenbox;

const Card = React.memo(function (props: slideProp) {
  const { data, dataIndex } = props;
  const { cover, name } = data[dataIndex];

  return (
    <div className="w-[268px] h-[268px] rounded-md bg-grayscale-800 p-[15px]">
      <div
        className="w-[238px] h-[238px] bg-grayscale-700 rounded relative"
        style={{ backgroundImage: `url(${cover})`, backgroundSize: 'cover' }}
      >
        <div className="bg-grayscale-800 rounded py-1 px-2.5 absolute top-2.5 left-2.5 font-semibold text-xs text-gray-200 flex gap-1.5 items-center">
          <IconSvg icon="rareS" className="text-rarity-rare-light" />
          <div>{name} </div>
        </div>
      </div>
    </div>
  );
});
