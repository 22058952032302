import { Dialog, DialogPanel } from '@headlessui/react';
import Button from 'src/components/button/Button';
import SyncLoader from 'react-spinners/SyncLoader';
import IconSvg from 'src/components/common/ui/IconSvg';

interface Props {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  rerollsLeft: number;
  userState: number;
  userDataSeason: number;
  contractCurrentSeason: number | null;
  nftId: string;
  claimNft: () => void;
  rerollNft: () => void;
  isClaimingNft: boolean;
  isRerollingNft: boolean;
  selectedNft: { cover: string; name: string; credits: number } | null; // Add this line
}

function EnclaveClaimNftLotteryModal({
  isOpen,
  onClose,
  rerollsLeft,
  claimNft,
  isClaimingNft,
  selectedNft,
  userState,
  userDataSeason,
  contractCurrentSeason,
  isRerollingNft,
  rerollNft,
  nftId,
}: Props) {
  return (
    <Dialog open={isOpen} as="div" className="relative z-20 focus:outline-none" onClose={onClose}>
      <div className="overflow-y-auto fixed inset-0 z-10 w-screen bg-grayscale-900/50">
        <div className="flex justify-center items-center p-4 min-h-full">
          <DialogPanel
            transition
            className="relative w-full p-5 3xl:px-8 border-2 rounded-md max-w-fit bg-grayscale-800 border-grayscale-700 max-h-[95vh] overflow-y-auto no-scrollbar"
          >
            {userState === 0 && (
              <button className="absolute top-[11px] right-[13px]" onClick={() => onClose(false)}>
                <IconSvg icon="closeM" className="text-grayscale-400" />
              </button>
            )}

            <div className="max-w-[595px] space-y-6">
              <img
                src={
                  nftId
                    ? `/assets/images/enclave-reward-card.png`
                    : '/assets/images/enclave-reward-card.png'
                }
                className="max-w-[35vh] 4xl:max-w-full rounded-md w-full mx-auto"
                alt={`NFT ID#${nftId ? nftId : 'N/A'}`}
              />

              <h2 className="uppercase text-brand-primary-light text-[18px] lg:text-[32px]">
                {nftId
                  ? `${(parseFloat(nftId.toString() || '0') / Math.pow(10, 6)).toFixed(2)} USDC`
                  : <>
                      <SyncLoader color="#fff" size={5} />
                  </>}
              </h2>

              <div className="w-full border-b-2 border-grayscale-700"></div>

              <div className="flex justify-between items-center">
                {/* <div>
                  <div className="mb-[5px] text-sm text-grayscale-300">Estimated value</div>

                  <div className="font-semibold 3xl:text-lg text-grayscale-100">≈ 0,000</div>
                </div> */}

                <div>
                  <div className="mb-[5px] text-sm text-grayscale-300">Points earned</div>

                  <div className="flex items-center gap-[9px]">
                    <IconSvg icon="starS" />

                    <div className="font-semibold 3xl:text-lg text-grayscale-100">220</div>
                  </div>
                </div>

                {/* <button
                  className={`flex gap-2 items-center text-sm font-semibold rounded-md py-[6px] text-grayscale-200 px-[16px] bg-rarity-uncommon-dark h-fit`}
                >
                  <IconSvg icon="uncommonM" />
                  <span>Uncommon</span>
                </button> */}
              </div>

              <div className="flex flex-col">
                {userState !== 0 && <h5 className="text-grayscale-200 mb-[11px]">Description</h5>}

                <p className="text-grayscale-400 text-[14px] 3xl:text-[16px]">
                  {userState === 0 ? (
                    '🎉 Congratulations! Your reward has been added to your wallet.'
                  ) : (
                    <>
                      Congratulations! You&apos;ve won USDC tokens! Claim your reward to confirm the
                      transaction and receive your tokens. If your key has re-rolls, you can also
                      try your luck by re-rolling. Please note that once you re-roll, you won&apos;t
                      be able to claim this reward.
                    </>
                  )}
                </p>
              </div>

              {userState !== 0 && (
                <div
                  className="flex flex-wrap gap-y-4 gap-x-6 justify-center items-center"
                  // onClick={() => onClose(false)}
                >
                  {
                    userDataSeason >= contractCurrentSeason && (
                        <Button
                          size="m"
                          type="primary"
                          classNames="min-w-[232px] !text-base 3xl:!text-lg"
                          onClick={rerollNft}
                          disabled={isClaimingNft || isRerollingNft || rerollsLeft == 0}
                        >
                          {isRerollingNft ? (
                            <SyncLoader color="#fff" size={8} />
                          ) : (
                            `Re-roll (${rerollsLeft})`
                          )}
                        </Button>
                    ) 
                  }

                  <Button
                    size="m"
                    type="secondary"
                    classNames="min-w-[232px] !text-base 3xl:!text-lg"
                    onClick={claimNft}
                    disabled={isRerollingNft || isClaimingNft}
                  >
                    {isClaimingNft ? <SyncLoader color="#fff" size={8} /> : 'Claim'}
                  </Button>
                </div>
              )}

              {/* <div className="flex gap-1 justify-center items-center mx-auto text-center cursor-pointer text-brand-primary-light">
                <div className="underline underline-offset-2">Share on</div>

                <IconSvg icon="twitterM" className="text-brand-primary-light" />
              </div> */}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

export default EnclaveClaimNftLotteryModal;
