import React from 'react';
import IconSvg from 'src/components/common/ui/IconSvg';
import { useAccount } from 'wagmi';
import EnclaveCountdown from './EnclaveCountdown';

export default function EnclaveStageClaimSlot({ stage, hashUsed, claimWhitelistData }: any) {
  const { address } = useAccount();
  return (
    <div key={stage.id} className="space-y-3.5 mb-[25px]">
      <div
        className={`grid grid-cols-2 lg:grid-cols-3 lg:px-5 lg:py-[30px] p-5 border-2 rounded-md cursor-pointer space-y-[13px] lg:space-y-0 ${
          stage?.keyClaimingStatus === 'Live'
            ? 'border-system-success'
            : stage?.keyClaimingStatus === 'Soon'
            ? 'border-grayscale-700'
            : 'border-grayscale-700'
        }`}
      >
        <div className="lg:col-span-1 col-span-full space-y-1.5">
          <div className="text-lg font-semibold text-brand-primary-light max-w-[196px] truncate">
            {stage.stageName}
          </div>

          {stage.keyClaimingStatus === 'Live' &&
            (hashUsed ? (
              <div className="text-xss text-grayscale-300">Keys Already Claimed</div>
            ) : address && claimWhitelistData ? (
              <div className="text-xss text-grayscale-300">You&apos;re eligible</div>
            ) : (
              <div className="text-xss text-grayscale-300">You&apos;re not eligible</div>
            ))}
        </div>

        <div className="col-span-full border-t-2 border-grayscale-700 lg:hidden"></div>

        <div className="col-span-1 space-y-[7px] lg:text-center flex flex-col justify-center lg:justify-start">
          <div className="text-sm text-grayscale-300 leading-[19px]">Your keys</div>

          {stage.keyClaimingStatus === 'Live' ? (
            <div className="text-lg font-semibold text-gray-100 leading-[24px]">
              {address && claimWhitelistData
                ? claimWhitelistData.keysOfEachType?.reduce((sum, key) => sum + key, 0)
                : '-'}
            </div>
          ) : (
            <div className="text-lg font-semibold text-gray-100 leading-[24px]">-</div>
          )}
        </div>

        <div className="col-span-1 flex flex-col items-end gap-y-2.5">
          <div
            className={`py-2 px-[15px] rounded-md flex gap-1.5 items-center border w-fit ${
              stage?.keyClaimingStatus === 'Live'
                ? 'bg-system-success-dark border-system-success'
                : stage?.keyClaimingStatus === 'Soon'
                ? 'bg-system-warning-dark border-system-warning'
                : 'bg-system-error-dark border-system-error'
            }`}
          >
            <IconSvg
              icon={
                stage?.keyClaimingStatus === 'Live'
                  ? 'liveS'
                  : stage?.keyClaimingStatus === 'Soon'
                  ? 'comingSoonS'
                  : 'finishedS'
              }
            />
            <span className="text-sm text-grayscale-100 leading-[19px]">
              {stage?.keyClaimingStatus}
            </span>
          </div>

          {stage?.keyClaimingStatus === 'Live' ? (
            <div className="text-xss text-grayscale-300 leading-[14px]">
              <EnclaveCountdown stage={stage} />
            </div>
          ) : stage?.keyClaimingStatus === 'Soon' ? (
            <div className="text-xss text-grayscale-300 leading-[14px]">TBA</div>
          ) : // <div className="text-xss text-grayscale-300">Remaining: 00d 00h 00m 00s</div>
          null}
        </div>
      </div>
    </div>
  );
}
