import { Dialog, DialogPanel } from '@headlessui/react';
import { useState } from 'react';
import Button from 'src/components/button/Button';
import IconSvg from '../ui/IconSvg';

interface Props {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  handleEnter?: () => void;
}

function CustomConnectWallet({ isOpen, onClose, handleEnter }: Props) {
  return (
    
    <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={onClose}>

      <div className="fixed inset-0 z-10 w-full overflow-y-hidden bg-black/80">
        <div className="flex items-center justify-center min-h-screen p-4 flex-col">
        <DialogPanel
            transition
            className="relative w-full p-10 border-2 rounded-md max-w-fit bg-grayscale-800 border-grayscale-700 max-h-[95vh] overflow-y-auto no-scrollbar"
          >
                        <button className="absolute top-[11px] right-[13px]" onClick={() => onClose(false)}>
              <IconSvg icon="closeM" className="text-grayscale-400" />
            </button>
            <p className="text-brand-primary-light text-[32px] font-joystix font-bold text-center tracking-wide">
              CONNECT YOUR WALLET
            </p>
            <p className="text-grayscale-400 font-normal text-sm text-center mt-4">
              It looks like your wallet isn’t connected to the right network.
              <br />
              Please connect to the correct wallet to access this page.
            </p>
            <div className="mt-6 flex justify-center">
              <Button
                size="xs"
                type="primary"
                // classNames="bg-purple-500 text-white rounded-lg px-6 py-2 text-lg font-bold"
                onClick={handleEnter}
              >
                Connect Wallet
              </Button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

export default CustomConnectWallet;
