import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Collection } from 'src/components/common/cards/types/types';
import IconSvg, { Icons } from 'src/components/common/ui/IconSvg';
import { rewardEnclaveCardData } from 'src/dummydata';
import { useHttp } from 'src/hooks';
import { useAccount, useSwitchChain } from 'wagmi';
import { ethers } from 'ethers';
import StagesEnclave from './components/StagesEnclave';
import ClaimedRewardModal from 'src/components/common/modal/ClaimedRewardModal';
import RewardCard from 'src/components/common/cards/RewardCard';
import whitelistSeason1 from '../../../whitelist/enclave/season1.json';
import whitelistSeason2 from '../../../whitelist/enclave/season2.json';
import whitelistSeason3 from '../../../whitelist/enclave/season3.json';
import whitelistSeason4 from '../../../whitelist/enclave/season4.json';
import whitelistSeason5 from '../../../whitelist/enclave/season5.json';
import EnclaveMintSuccessModal from '../components/EnclaveMintSuccessModal';

const environment = process.env.REACT_APP_ENVIRONMENT;

function EnclaveMint() {
  const { address, isConnected, chainId } = useAccount();
  const [contractMetadata, setContractMetadata] = useState(null);
  const [whitelistFileData, setWhiteListFileData] = useState(null);
  const [claimWhitelistData, setClaimWhitelistData] = useState(null);

  const [showMintSuccessModal, setShowMintSuccessModal] = useState(false);
  const [isShowClaimMintSuccessModal, setShowClaimMintSuccessModal] = useState(false);

  const [arenaTotalMintedKeys, setArenaTotalMintedKeys] = useState('');
  const [arenaTotalMintedKeysLoading, setArenaTotalMintedKeysLoading] = useState(false);

  const [collection, setCollection] = useState<Collection>();
  const { sendRequest } = useHttp();
  const { switchChain } = useSwitchChain();
  const { id, stageId } = useParams();

  const getEnclaveKeyClaimStages = () => {
    sendRequest(
      {
        url: `collections/key-claiming-stages/enclave`,
        method: 'GET',
      },
      (data: any) => {
        setCollection(data);
        switchChain({ chainId: Number(data?.network.chainId) });
      },
    );
  };

  useEffect(() => {
    if (collection) setContractMetadata(collection.stages?.active[0]?.metadata[environment]);
  }, [collection, address]);

  useEffect(() => {
    if (!collection) return;
    let whitelist;
    const stageOrder = collection?.stages?.active?.[0]?.order;
    console.log('order stageOrder', stageOrder);
    console.log('order whitelistSeason2', whitelistSeason2);
    switch (stageOrder) {
      case 1:
        whitelist = whitelistSeason1;
        break;
      case 2:
        whitelist = whitelistSeason2;
        break;
      case 3:
        whitelist = whitelistSeason3;
        break;
      case 4:
        whitelist = whitelistSeason4;
        break;
      case 5:
        whitelist = whitelistSeason5;
        break;
      // Add more cases as needed
      default:
        whitelist = null;
    }

    if (address && whitelist) {
      setWhiteListFileData(whitelist);
      setClaimWhitelistData(whitelist[address.toLowerCase()]);
    }
  }, [address, collection]);

  useEffect(() => {
    getEnclaveKeyClaimStages();
  }, [id, chainId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const readMintedKeyEvents = async () => {
    console.log('readMintedKeyEvents...........................||');
    try {
      const enclaveCollection = collection;
      if (!contractMetadata) return;
      setArenaTotalMintedKeysLoading(true);

      const provider = new ethers.providers.JsonRpcProvider(enclaveCollection?.network.RPCUrl);
      console.log('eeee enclaveCollection?.network.RPCUrl', enclaveCollection?.network.RPCUrl);
      const contract1 = new ethers.Contract(
        contractMetadata.LOOTY_KEY.address,
        contractMetadata.LOOTY_KEY.abi,
        provider,
      );
      console.log('eeee contractMetadata', contractMetadata);
      console.log('eeee contract1', contract1);
      const eventFilter = contract1.filters.TransferBatch(
        null,
        '0x0000000000000000000000000000000000000000',
      );
      const fromBlock = 1;
      const toBlock = 'latest';
      console.log('eeee eventFilter', eventFilter);

      const events = await contract1.queryFilter(eventFilter, fromBlock, toBlock);

      console.log('eeee events', events);
      let count = 0;
      let ids = [];
      const currentSeason = collection?.stages?.active[0]?.order;
      if (currentSeason === 1) {
        ids = [0, 1, 2];
      } else if (currentSeason === 2) {
        ids = [3, 4, 5];
      } else if (currentSeason === 3) {
        ids = [6, 7, 8];
      } else if (currentSeason === 4) {
        ids = [9, 10, 11];
      } else if (currentSeason === 5) {
        ids = [12, 13, 14];
      }

      const mintedKeys = events.reduce((sum, event) => {
        const ev: any = event;

        for (let i = 0; i < ev?.args[4].length; i++) {
          const keyId = ev?.args[3][i].toNumber();
          if (ids.includes(keyId)) {
            count += ev?.args[4][i].toNumber();
          }
        }
        return count;
      }, 0);
      console.log('Total Minted Keys:', mintedKeys);
      setArenaTotalMintedKeys(mintedKeys);
      setArenaTotalMintedKeysLoading(false);
    } catch (error) {
      setArenaTotalMintedKeysLoading(false);
      console.error('Error initializing event listener:', error);
    }
  };
  useEffect(() => {
    if (address && isConnected && collection?.id && contractMetadata) readMintedKeyEvents();
  }, [address, isConnected, collection, contractMetadata]);

  return (
    <>
      {/* <MIntSuccessModal
        isOpen={showMintSuccessModal}
        onClose={setShowMintSuccessModal}
        mintedKeys={5}
      /> */}

      <EnclaveMintSuccessModal
        isOpen={isShowClaimMintSuccessModal}
        onClose={setShowClaimMintSuccessModal}
        season={collection?.stages?.active[0]?.order}
        mintedKeys={claimWhitelistData?.ids ?? []}
        keysOfEachType={claimWhitelistData?.keysOfEachType ?? []}
      />

      {/* <RewardInfoModal isOpen={showMintSuccessModal} onClose={setShowMintSuccessModal} /> */}
      <ClaimedRewardModal isOpen={showMintSuccessModal} onClose={setShowMintSuccessModal} />

      <section className="mb-[36px] xl:mb-[50px]">
        <div className="w-full bg-gray-800 rounded-md">
          <img src={'/assets/images/enclave-banner.png'} className="w-full h-auto max-h-[418px]" />
        </div>
      </section>

      <section className="mb-[36px] lg:mb-[72px]">
        <div className="flex flex-col justify-between xl:flex-row space-y-[36px]">
          <div className="xl:w-[44.76%]">
            <div className="flex gap-[19px] items-center mb-6">
              <img
                src={'/assets/images/enclave-logo.png'}
                className="lg:w-[96px] lg:h-[96px] w-[60px] h-[60px] bg-grayscale-800 rounded-md"
              />

              <div className="space-y-[8px] lg:space-y-[17px]">
                <h3 className="uppercase text-brand-primary-light text-lg lg:text-[32px]">
                  Enclave
                </h3>

                <div className="flex gap-[9px]">
                  {collection?.website && (
                    <IconSvg
                      icon="webM"
                      onClick={() =>
                        window.open(collection.website, '_blank', 'noopener,noreferrer')
                      }
                      className="cursor-pointer text-grayscale-400 lg:w-fit lg:h-fit w-[12px] h-[12px]"
                    />
                  )}

                  {collection?.twitter && (
                    <IconSvg
                      icon="twitterM"
                      onClick={() =>
                        window.open(collection.twitter, '_blank', 'noopener,noreferrer')
                      }
                      className="cursor-pointer text-grayscale-400 lg:w-fit lg:h-fit w-[12px] h-[12px]"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="mb-9 space-y-4 text-gray-300 lg:space-y-6">
              <p className="text-sm lg:text-base">
                Welcome to Enclave Market&apos;s Looty campaign! Our campaign is structured into
                multiple seasons, each offering progressively larger prizes. Immerse yourself in the
                world of perpetual trading and alpha vaults!
              </p>

              <p>
                We&apos;re excited to present a series of engaging tasks that will guide you through
                the Enclave ecosystem, unlocking extraordinary rewards along the way. Stay active
                and ascend the leaderboard to earn weekly prizes. Remember, consistency is
                key—maintain your momentum to secure your place at the top each week!
              </p>

              <div>
                <div className="mb-2 text-xs text-grayscale-400">Value of rewards</div>

                <div className="text-lg font-semibold text-grayscale-100">≈ $135,000</div>
              </div>
            </div>

            <div>
              <div className="mb-3 text-lg text-grayscale-100">Available keys:</div>

              {collection && collection?.stages?.active[0]?.order === 1 && (
                <div className="flex gap-[15px]">
                  <img src={`/assets/images/keys/1.png`} className="w-[96px] h-[96px] rounded" />
                  <img src={`/assets/images/keys/2.png`} className="w-[96px] h-[96px] rounded" />
                  <img src={`/assets/images/keys/3.png`} className="w-[96px] h-[96px] rounded" />
                </div>
              )}

              {collection && collection?.stages?.active[0]?.order === 2 && (
                <div className="flex gap-[15px]">
                  <img src={`/assets/images/keys/s2/3.png`} className="w-[96px] h-[96px] rounded" />
                  <img src={`/assets/images/keys/s2/4.png`} className="w-[96px] h-[96px] rounded" />
                  <img src={`/assets/images/keys/s2/5.png`} className="w-[96px] h-[96px] rounded" />
                </div>
              )}

              {collection && collection?.stages?.active[0]?.order === 3 && (
                <div className="flex gap-[15px]">
                  <img src={`/assets/images/keys/s3/6.png`} className="w-[96px] h-[96px] rounded" />
                  <img src={`/assets/images/keys/s3/7.png`} className="w-[96px] h-[96px] rounded" />
                  <img src={`/assets/images/keys/s3/8.png`} className="w-[96px] h-[96px] rounded" />
                </div>
              )}

              {collection && collection?.stages?.active[0]?.order === 4 && (
                <div className="flex gap-[15px]">
                  <img src={`/assets/images/keys/s4/9.png`} className="w-[96px] h-[96px] rounded" />
                  <img
                    src={`/assets/images/keys/s4/10.png`}
                    className="w-[96px] h-[96px] rounded"
                  />
                  <img
                    src={`/assets/images/keys/s4/11.png`}
                    className="w-[96px] h-[96px] rounded"
                  />
                </div>
              )}

              {collection && collection?.stages?.active[0]?.order === 5 && (
                <div className="flex gap-[15px]">
                  <img
                    src={`/assets/images/keys/s5/12.png`}
                    className="w-[96px] h-[96px] rounded"
                  />
                  <img
                    src={`/assets/images/keys/s5/13.png`}
                    className="w-[96px] h-[96px] rounded"
                  />
                  <img
                    src={`/assets/images/keys/s5/14.png`}
                    className="w-[96px] h-[96px] rounded"
                  />
                </div>
              )}
            </div>
          </div>

          {collection && (
            <StagesEnclave
              collection={collection}
              stageId={stageId}
              contractMetadata={contractMetadata}
              arenaTotalMintedKeys={arenaTotalMintedKeys}
              arenaTotalMintedKeysLoading={arenaTotalMintedKeysLoading}
              rewardsTotalValue={collection.rewardsTotalValue}
              setShowClaimMintSuccessModal={setShowClaimMintSuccessModal}
              setShowMintSuccessModal={setShowMintSuccessModal}
              whitelistFileData={whitelistFileData}
              claimWhitelistData={claimWhitelistData}
            />
          )}
        </div>
      </section>

      <section>
        <div className="mb-[15px] lg:mb-[36px]">
          <h3 className="mb-2 text-brand-primary-light">Rewards</h3>

          <div className="text-grayscale-200">
            The better the key, the more rewards you can get!
          </div>
        </div>

        <div className="grid gap-[18px] grid-cols-[repeat(auto-fit,minmax(326px,1fr))]">
          {rewardEnclaveCardData.map((card, index) => (
            <RewardCard
              key={index}
              name={card.name}
              estimatedValue={card.estimatedValue}
              rarity={card.rarity}
              image={card.image}
              size="medium"
              showRarity={false}
            />
          ))}
        </div>
      </section>
    </>
  );
}

export default EnclaveMint;
