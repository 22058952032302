import IconSvg from "src/components/common/ui/IconSvg";

interface Props {
  size?: 'small' | 'medium';
  name: string;
  estimatedValue?: string;
  rarity: string;
  image: string;
  blurName?: boolean;
}

const rarityToIconMap: Record<string, string> = {
  Common: 'commonM',
  Uncommon: 'uncommonM',
  Rare: 'rareM',
  Epic: 'epicM',
  Legendary: 'legendaryM',
  Mythical: 'mythicalM',
};

const rarityToColorMap: Record<string, string> = {
  Common: 'bg-grayscale-700 text-white', // Adjust colors as needed
  Uncommon: 'bg-rarity-uncommon-dark text-white',
  Rare: 'bg-rarity-rare-dark text-white',
  Epic: 'bg-rarity-epic-dark text-white',
  Legendary: 'bg-rarity-legendary-dark text-black',
  Mythical: 'bg-rarity-mythical-dark text-black',
};

function EnclaveRewardCard({
  size = 'small',
  name,
  estimatedValue,
  rarity,
  image,
  blurName = false,
}: Props) {
  const cardHeight = size === 'small' ? 430 : 444;
  const thumbnailSize = size === 'small' ? 271 : 286;

  return (
    <div className="p-[18px] border-2 border-transparent rounded-md bg-grayscale-800 hover:border-grayscale-700 hover:bg-grayscale-900 group min-w-fit">
      <div
        className="bg-grayscale-700 rounded-md mb-[17px]"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: `${thumbnailSize}px`,
          minWidth: `${thumbnailSize}px`,
        }}
      ></div>

      <h5 className="text-brand-primary-light max-w-[250px] truncate flex items-center gap-2">
        {/* {blurName && <img src="/assets/images/blur-reward-price.png" className="h-[40px]" />} */}
        {name}
      </h5>

      {/* <div className="w-full border-b-2 border-grayscale-700 my-[17px]"></div> */}

      {/* <div className="flex items-center justify-between p-1 border border-transparent rounded-md bg-grayscale-900 group-hover:border-grayscale-700">
        <div className="font-semibold text-grayscale-300 pl-3.5">Rarity</div>

        <button
          className={`py-[6px] text-grayscale-200 px-[16px] ${rarityToColorMap[rarity]} flex gap-2 font-semibold text-sm h-fit rounded-md items-center`}
        >
          <IconSvg icon={rarityToIconMap[rarity] as any} />
          <span>{rarity}</span>
        </button>
      </div> */}
    </div>
  );
}

export default EnclaveRewardCard;
