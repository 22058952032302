import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHttp, useNotification } from 'src/hooks';
import { Collection } from '../../components/common/cards/types/types.tsx';
import SyncLoader from 'react-spinners/SyncLoader';

const TwitterCallback: React.FC = () => {
  const navigate = useNavigate();
  const { sendRequest, error } = useHttp();
  const notify = useNotification();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const state = urlParams.get('state');
  const [collections, setCollections] = useState<Collection[]>([]);
  const [accessToken, setAccessToken] = useState('');

  const fetchDiscordUser = () => {
    console.log('fetchDiscordUser');
    const jwtString = localStorage.getItem('jwt');
    let token = '';
    if (jwtString) {
      const data = JSON.parse(jwtString);
      token = data.token;
      console.log('xx accessToken', token);
    }

    sendRequest(
      {
        url: `twitter/callback?code=${code}&state=${state}${
          localStorage.getItem('referralCode')
            ? `&invitedByCode=${localStorage.getItem('referralCode')}`
            : ''
        }${
          localStorage.getItem('referralApp')
            ? `&referralApp=${localStorage.getItem('referralApp')}`
            : ''
        }`,
        accessToken: token,
        method: 'GET',
      },
      (data: any) => {
        if (localStorage.getItem('referralCode')) {
          localStorage.removeItem('referralCode');
        }
        if (localStorage.getItem('referralApp')) {
          localStorage.removeItem('referralApp');
        }
        // local storage
        const jwtString = localStorage.getItem('jwt');
        const jdata = JSON.parse(jwtString);
        localStorage.setItem(
          'jwt',
          JSON.stringify({
            token: data.token,
            isXLoggedIn: true,
            isMLoggedIn: jwtString && jdata.isMLoggedIn === true,
            isMvxLoggedIn: jwtString && jdata.isMvxLoggedIn === true,
          }),
        );
        // if (jwtString && jdata.isMvxLoggedIn === true)
        //   localStorage.setItem('jwt', JSON.stringify({ token: data.token, isXLoggedIn: true, isMvxLoggedIn: true }));
        // else
        //   localStorage.setItem('jwt', JSON.stringify({ token: data.token, isXLoggedIn: true }));

        // const multi = collections.filter((collection: any) => collection.slug === 'multiversx')[0]
        // navigate(`/multiversx/leaderboard/${multi.id}`);
        navigate(`/`);
        // window.open(`/multiversx/leaderboard/${multi.id}`, '_blank');
      },
    );
  };

  useEffect(() => {
    // Check if a token is already present on page load
    const jwtString = localStorage.getItem('jwt');
    const url = new URL(window.location.href);
    const pathname = url.pathname.slice(1);
    if (jwtString) {
      console.log('jwtString', jwtString);
      const data = JSON.parse(jwtString);
      const token = data.token;
      console.log('jwtString: token', token);
      setAccessToken(token);

      // sendRequest(
      //   {
      //     url: 'auth/verify-token',
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     payload: JSON.stringify({ token }),
      //   },
      //   (responseData: any) => {
      //     if (responseData.success) {
      //       setAccessToken(responseData.token);
      //     } else {
      //       localStorage.removeItem('jwt');
      //     }
      //   },
      // );
    }
  }, [window.location.href]);

  // useEffect(() => {
  //   getCollections();
  // }, []);

  useEffect(() => {
    fetchDiscordUser();
  }, []);

  // const getCollections = () => {
  //   sendRequest(
  //     {
  //       url: 'collections',
  //       method: 'GET',
  //     },
  //     (data: any) => {
  //       setCollections(data);
  //     },
  //   );
  // };
  useEffect(() => {
    if (error) {
      notify(`${error}`, 'error');
      navigate('/');
    }
  }, [error]);
  return (
    <div>
      <div className="text-white text-center">Redirecting you back to the platform in while...</div>
    </div>
  );
};

export default TwitterCallback;
