import React, { useState, useEffect } from 'react';

interface Props {
  stage?: any;
}

const AvaxCountdown: React.FC<Props> = ({ stage }) => {
  const [remainingTime, setRemainingTime] = useState<number>(0);

  useEffect(() => {
    const calculateRemainingTime = () => {
      const now = new Date().getTime();

      if (stage?.keyClaimingStatus === 'Live') {
        const timeLeft = new Date(stage.keyClaimEndDate).getTime() - now;
        setRemainingTime(timeLeft);
      } else if (stage?.keyClaimingStatus === 'Soon') {
        const timeLeft = new Date(stage.keyClaimStartDate).getTime() - now;
        setRemainingTime(timeLeft);
      } else {
        setRemainingTime(0); // Set to 0 if not live or soon
      }
    };

    calculateRemainingTime(); // Initial calculation

    const intervalId = setInterval(() => {
      calculateRemainingTime(); // Update remaining time every second
    }, 1000);

    return () => clearInterval(intervalId); // Clear the interval on component unmount
  }, [stage]);

  // Function to format time in days, hours, minutes, seconds
  const formatRemainingTime = (time: number) => {
    if (time <= 0) return '00d 00h 00m 00s'; // Handle negative or zero time left

    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));

    return `${String(days).padStart(2, '0')}d ${String(hours).padStart(2, '0')}h ${String(
      minutes,
    ).padStart(2, '0')}m`;
  };

  // Conditionally render based on the stage status
  if (stage?.keyClaimingStatus === 'Live') {
    return <>{`Remaining: ${formatRemainingTime(remainingTime)}`}</>;
  } else if (stage?.keyClaimingStatus === 'Soon') {
    return <>{`00d 00h 00m`}</>;
  } else if (stage?.keyClaimingStatus === 'Expired') {
    return <> </>; // Display 'Expired' or handle expired case
  }

  return null; // Nothing to display if the stage is not handled
};

export default AvaxCountdown;
