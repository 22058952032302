import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useAccount, useSignMessage, useSwitchChain, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';

import { Collection, Stage } from 'src/components/common/cards/types/types';
import IconSvg from 'src/components/common/ui/IconSvg';
import ProgressBar from 'src/components/common/ui/ProgressBar';
import SyncLoader from 'react-spinners/SyncLoader';
import { useHttp } from 'src/hooks';

import Button from 'src/components/button/Button';
import { checkIsHashUsed } from 'src/utilities/looty';
import AvaxStageClaimSlot from './AvaxStageClaimSlot';
import CollectionDetail from 'src/pages/openbox/CollectionDetail';

interface Props {
  setShowClaimMintSuccessModal: any;
  setShowMintSuccessModal: any;
  collection: Collection[];
  stageId: string;
  arenaTotalMintedKeys: string;
  arenaTotalMintedKeysLoading: boolean;
  rewardsTotalValue: string;
  contractMetadata: any;
  claimWhitelistData: any;
}

const StagesAvax = ({
  collection,
  setShowMintSuccessModal,
  setShowClaimMintSuccessModal,
  stageId,
  arenaTotalMintedKeys,
  arenaTotalMintedKeysLoading,
  contractMetadata,
  claimWhitelistData,
}: Props) => {
  const { sendRequest } = useHttp();
  const { id } = useParams();

  const [blockchainKeyBalances, setBlockchainKeyBalances] = useState<Record<number, number>>({});

  const { signMessageAsync } = useSignMessage();
  const navigate = useNavigate();

  const [phase1Hash, setPhase1Hash] = useState<boolean>(false);
  const { address, isConnected, chainId } = useAccount();
    const { switchChain } = useSwitchChain();
  

  const [isPhase1Loading, setIsPhase1Loading] = useState(false);

  // =================================================================
  const { data: hash, error, isPending, writeContractAsync } = useWriteContract();
  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed,
    data: receipt,
  } = useWaitForTransactionReceipt({
    hash,
  });

  useEffect(() => {
    if (isConfirmed && receipt) {
      setShowMintSuccessModal(true);
    }
  }, [isConfirming, isConfirmed, receipt]);

  // =================================================================

  const {
    data: mintHashAvax,
    error: errorMintAvax,
    isPending: isPendingMintAvax,
    writeContractAsync: writeContractMintAvax,
  } = useWriteContract();
  const {
    isLoading: isConfirmingMintArena,
    isSuccess: isConfirmedMintArena,
    error: errorReceiptMintArena,
    data: receiptMintArena,
  } = useWaitForTransactionReceipt({
    hash: mintHashAvax,
  });

  useEffect(() => {
    if (isConfirmedMintArena && receiptMintArena) {
      // Parse the tokenId from logs[0].topics[3]
      // const tokenIdHex = receipt.logs[0].topics[3];
      // const tokenId = ethers.BigNumber.from(tokenIdHex).toString();
      // setTokenId(tokenId); // Set the tokenId state with the parsed value
      // setIsMintingInProgress(false); // Hide progress modal
      // setIsMintSuccessModalOpen(true); // Show success modal
      // toast.success("Congratulations! Mint Successful.", { autoClose: 6000 });
      // Delay opening the modal by 3 seconds
      setTimeout(() => {
        setIsPhase1Loading(false);
        handleMintKey();
        setPhase1Hash(true);
        fetchBlockchainKeyBalances();
        setShowClaimMintSuccessModal(true);
      }, 18000);

      if (errorReceiptMintArena) {
        setIsPhase1Loading(false);
        handleMintKey();
        setPhase1Hash(true);
        fetchBlockchainKeyBalances();
        setShowClaimMintSuccessModal(true);
        toast.error('Execution reverted for an unknown reason.');
      }
    }
  }, [isConfirmingMintArena, isConfirmedMintArena, receiptMintArena, errorReceiptMintArena]);

  // =================================================================

  useEffect(() => {
    if (isConnected && address && claimWhitelistData) {
      readPhase1Hash();
    }
  }, [isConnected, address, claimWhitelistData]);

  const readPhase1Hash = async (): Promise<void> => {
    if (!address || !claimWhitelistData) return;
    console.log('readphase contractMetadata', contractMetadata);
    const keysOfEachType = claimWhitelistData?.keysOfEachType || [];
    const ids = claimWhitelistData?.ids || [];
    const keyNonce = claimWhitelistData?.keyNonce;
    const messageHash = ethers.utils.solidityKeccak256(
      ['address', 'address', 'uint256', 'uint256', 'uint256[]', 'uint256[]'],
      [address, contractMetadata.LOOTY_KEY.address, keyNonce, ids.length, ids, keysOfEachType],
    );

    console.log('checking => phase1HashUsed.......', messageHash);
    const provider = new ethers.providers.Web3Provider(window.ethereum as any);
    const isHashUsed = await checkIsHashUsed(
      provider,
      contractMetadata.LOOTY_KEY_MINTER.address || '',
      messageHash,
    );

    console.log('phase1HashUsed', isHashUsed);
    setPhase1Hash(isHashUsed);
  };

  useEffect(() => {
    if (address && isConnected && contractMetadata) {
      fetchBlockchainKeyBalances();
    }
  }, [address, isConnected, contractMetadata]);

  const fetchBlockchainKeyBalances = async () => {
    if (!address) return;

    console.log('updated loadding....');

    const updatedBlockchainKeys: Record<number, number> = {};
    // Fetch the balance for each key (0 to 4)
    for (let keyIndex = 0; keyIndex < 3; keyIndex++) {
      const balance = await readKeyBalance(address, keyIndex);
      updatedBlockchainKeys[keyIndex] = balance;
    }

    console.log('arena disturgbution updatedBlockchainKeys', updatedBlockchainKeys);

    setBlockchainKeyBalances(updatedBlockchainKeys);
  };

  const readKeyBalance = async (walletAddress: string, keyIndex: number) => {
    try {
      console.log('collectioncollection', collection)
      const provider = new ethers.providers.JsonRpcProvider(collection?.network?.RPCUrl);

      const contract = new ethers.Contract(
        contractMetadata.LOOTY_KEY.address,
        contractMetadata.LOOTY_KEY.abi,
        provider,
      );
      const balance = await contract.balanceOf(walletAddress, keyIndex);
      console.log('Number(balance)', Number(balance));
      console.log(' keyIndex', keyIndex, Number(balance));
      return Number(balance);
    } catch (error) {
      console.error('Error reading balance:', error);
      return 0;
    }
  };

  const handleMintKeysPhase1 = async () => {
    console.log('handleMintKeysPhase1 start....');
    if (!isConnected) {
      toast.error('Please connect your wallet before Keys minting.');
      return;
    }

    if (chainId !== collection?.network?.chainId) {
      switchChain({ chainId: Number(collection?.network?.chainId) });
    }

    console.log('claimWhitelistData', claimWhitelistData)

    try {
      setIsPhase1Loading(true);
      console.log('try catch block');

      const keysOfEachType = claimWhitelistData?.keysOfEachType || [];

      const ids = claimWhitelistData?.ids || [];
      const keyNonce = claimWhitelistData?.keyNonce;
      const messageHash = ethers.utils.solidityKeccak256(
        ['address', 'address', 'uint256', 'uint256', 'uint256[]', 'uint256[]'],
        [address, contractMetadata.LOOTY_KEY.address, keyNonce, ids.length, ids, keysOfEachType],
      );

      const provider = new ethers.providers.JsonRpcProvider(collection?.network?.RPCUrl);
      console.log('provider', provider);

      console.log('writeContractMintAvax....');
      console.log('abc', [
        claimWhitelistData.signature,
        contractMetadata.LOOTY_KEY.address,
        claimWhitelistData.keyNonce,
        claimWhitelistData.ids,
        keysOfEachType,
      ])
      await writeContractMintAvax({
        address: contractMetadata.LOOTY_KEY_MINTER.address as `0x${string}`,
        abi: contractMetadata.LOOTY_KEY_MINTER.abi,
        functionName: 'mint',
        args: [
          claimWhitelistData.signature,
          contractMetadata.LOOTY_KEY.address,
          claimWhitelistData.keyNonce,
          claimWhitelistData.ids,
          keysOfEachType,
        ],
      });
      console.log('handleMintKeysPhase1 end....');
    } catch (error: any) {
      setIsPhase1Loading(false);
      console.error('Mint NFT error:', error);
    }
  };


  const handleMintKey = () => {
    console.log('calling handleMintKey');

    if (address) {
      sendRequest(
        {
          url: `user/add-mint-points/${address}`,
          method: 'POST',
        },
        (data: any) => {
          console.log(data);
        },
      );
    }
  };

  const connectAvax = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.enable();
      } else {
        toast.error('Metamask is not detected. Please install the browser extension to proceed.', {
          autoClose: 8000,
        });
      }
    } catch (error) {
      console.error('Connection rejected or failed:', error);
    }
  };
  return (
    <div
      key={stageId}
      className="xl:w-[46.46%] w-full bg-grayscale-800 p-[30px] pb-[40px] rounded-md h-fit"
    >
      <div className="font-semibold text-lg lg:text-[24px] text-brand-primary-light mb-[25px]">
        Earn stages
      </div>

      {collection?.stages?.active &&
        collection?.stages?.active.map((stage, index) => (
          <AvaxStageClaimSlot
            stage={stage}
            key={index}
            hashUsed={phase1Hash}
            claimWhitelistData={claimWhitelistData}
          />
        ))}

      {collection?.stages?.soon &&
        collection?.stages?.soon.map((stage, index) => (
          <AvaxStageClaimSlot
            stage={stage}
            key={index}
            hashUsed={phase1Hash}
            claimWhitelistData={claimWhitelistData}
          />
        ))}

      <div className="mb-[25px]">
        {arenaTotalMintedKeysLoading ? ( // Show loader only when loading
          <div className="flex justify-center items-center">
            <SyncLoader color="#B73FFF" size={10} />
          </div>
        ) : (
          <ProgressBar
            progress={
              arenaTotalMintedKeys ? Math.floor((Number(arenaTotalMintedKeys) / 2000) * 100) : 0
            }
            size="Full"
            totalValue="2000"
            estimatedValue={arenaTotalMintedKeys ? Number(arenaTotalMintedKeys) : 0}
          />
        )}
      </div>

      <div className="flex items-center justify-center mb-[25px]">
        <div className="flex items-center space-x-3 w-fit">
          <div className="text-lg text-grayscale-300">Points to earn</div>

          <div className="flex gap-2.5 items-center">
            <IconSvg icon="starM" />

            <div className="text-[24px] text-[#FAFAFA] font-semibold">110</div>
          </div>
        </div>
      </div>

      {/* {window.innerWidth < 1024 ? (
      <p className="text-xs font-bold text-grayscale-300">
        Please make sure you claim your keys on desktop for an optimal experience.
      </p>
    ) : ( */}
      <>
        {collection?.stages?.active[0].keyClaimingStatus === 'Live' && (
          <div className="flex justify-center mt-6">
            {isPhase1Loading ? (
              <SyncLoader color="#B73FFF" size={10} />
            ) : (
              <div className="flex flex-col items-center space-y-[10px]">
                <div className="flex items-center space-x-8">
                  {!address ? (
                    <Button onClick={() => connectAvax()} type="secondary" size="m">
                      Connect Wallet
                    </Button>
                  ) : (
                    <>
                      <Button
                        onClick={handleMintKeysPhase1}
                        type="primary"
                        size="m"
                        classNames={`${
                          !address || !claimWhitelistData || phase1Hash ? 'cursor-not-allowed' : ''
                        }`}
                        disabled={!address || isPhase1Loading || !claimWhitelistData || phase1Hash}
                      >
                        Claim
                      </Button>
                    </>
                  )}

                  {phase1Hash && (
                    <Button
                      onClick={() => navigate(`/avax/openbox/${id}`)}
                      type="secondary"
                      size="m"
                    >
                      Open Box
                    </Button>
                  )}
                </div>

                {/* <p className="text-xs font-normal text-grayscale-300">
                  Claiming is free, no fees
                </p> */}
              </div>
            )}
          </div>
        )}
      </>
      {/* )} */}
    </div>
  );
};

export default StagesAvax;
