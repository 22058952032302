import { Dialog, DialogPanel } from '@headlessui/react';
import IconSvg from '../ui/IconSvg';

interface Props {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  handleCopyLink: () => void;
}
const ReferFriendModal = ({ isOpen, onClose, handleCopyLink }: Props) => {
  return (
    <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={onClose}>
      <div className="overflow-y-auto fixed inset-0 z-10 w-screen bg-grayscale-900/50">
        <div className="flex justify-center items-center p-4 min-h-full">
          <DialogPanel
            transition
            className="relative p-4 w-full rounded-md border-2 lg:p-5 max-w-fit bg-grayscale-800 border-grayscale-700"
          >
            <button
              className="absolute lg:top-[11px] top-[3px] lg:right-[13px] right-[5px]"
              onClick={() => onClose(false)}
            >
              <IconSvg icon="closeM" className="text-grayscale-400" />
            </button>

            <div className="max-w-[595px] max-h-[calc(100vh-80px)] flex flex-col space-y-3">
              <img
                src="/assets/images/refer-image.png"
                alt=""
                className="lg:!mb-3 w-full h-auto object-contain flex-1 max-h-[calc(100vh-400px)]"
              />

              <h3 className="uppercase text-brand-primary-light">Refer friends and win more!</h3>

              <p className="text-xs text-grayscale-400 lg:text-base">
                Anyone can create their own referral link and share it to their friends to earn more
                points as they join the leaderboard and participate to the tasks! Check the
                structure to see how much you can potentially earn!
              </p>

              <div className="flex justify-center">
                <button onClick={() => handleCopyLink()} className="py-[8px] px-[16px] bg-grayscale-700 flex gap-1.5 font-bold h-fit rounded-md items-center text-grayscale-200 text-xs lg:text-base">
                  <IconSvg icon="copyM" className="text-brand-primary-light" />

                  <span>Referral Link</span>
                </button>
              </div>

              <p className="italic text-[10px] leading-none text-grayscale-400">
                <span className="font-bold">Disclaimer:</span> This referral program is not a pyramid scheme; rewards are based on genuine product usage rather than solely on recruiting new members. All potential rewards shown are illustrative and not guaranteed. This program does not constitute financial or investment advice. Participation may be subject to local laws and regulations, and any tax obligations are the participant&apos;s responsibility. We reserve the right to modify or discontinue the program at any time, without prior notice. We disclaim liability for any indirect or consequential damages arising from participation
              </p>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default ReferFriendModal;
