import IconSvg from 'src/components/common/ui/IconSvg';
import EnclaveClaimBtn from './EnclaveClaimBtn';
import EnclaveEnterBtn from './EnclaveEnterBtn';
import SyncLoader from 'react-spinners/SyncLoader';
import { useState } from 'react';

export type CardStatus = 'Done' | 'To Do' | 'claimable';
interface Props {
  status: CardStatus;
  bgImage: string;
  pointsToEarn: string;
  phase: string;
  title: string;
  description: string;
  slug: string; // Add slug to identify the card
  currentSlug: string; // Add slug to identify the card
  onEnter: (slug: string) => void; // Pass slug to handle the click
  handleConnectX: () => void; // Pass slug to handle the click
  onClaim: (slug: string) => void;
  isClaimed?: boolean;
  twitterConnected?: boolean;
  isEnterLoading?: boolean;
  isLoggedInMultiversX?: boolean;
  user?: any;
  handleCopyLink: () => void;
}

export default function EnclavePointsCard({
  status,
  bgImage,
  phase,
  title,
  description,
  slug,
  user,
  currentSlug,
  pointsToEarn,
  onEnter,
  handleConnectX,
  onClaim,
  isClaimed,
  twitterConnected,
  isEnterLoading,
  isLoggedInMultiversX,
  handleCopyLink,
}: Props) {
  console.log('pooints card user', user);
  // const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);
  
  const handleEnclaveButtonClick = () => {
    // Mapping of slugs to URLs
    const urlMapping: Record<string, string> = {
      'connect-enclave-wallet': 'https://trade.enclave.market/account/overview',
      'complete-kyc-enclave': 'https://trade.enclave.market/account/overview',
      'first-deposit': 'https://trade.enclave.market/account/overview',
      'trade-perpetuals': 'https://trade.enclave.market/perps',
    };

    if (urlMapping[slug]) {
      window.open(urlMapping[slug], '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <>
      {/* <XPortalTutorialModal
        isOpen={isTutorialModalOpen}
        onClose={setIsTutorialModalOpen}
        handleEnter={() => {
          onEnter(slug);
          setIsTutorialModalOpen(false);
        }}
        steps={[
          {
            step: 1,
            title: 'Download xPortal on iOS/Android',
            image: '/assets/images/tutorial/step-1.png',
            link: 'https://xportal.com/app',
            linkText: 'Download app',
          },
          {
            step: 2,
            title: 'Create or import your wallet on xPortal',
            image: '/assets/images/tutorial/step-2.png',
          },
          {
            step: 3,
            title: 'Connect Wallet to Looty',
            image: '/assets/images/tutorial/step-3.png',
            button: true,
            buttonText: !isLoggedInMultiversX ? 'Connect wallet' : 'Leaderboard',
          },
        ]}
      /> */}

      <div className="relative p-5 rounded-md bg-grayscale-800 pb-[101px] lg:min-h-[687px] min-h-[700px]">
        <div
          className="relative h-[271px] w-full p-4 mb-5"
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div
            className={`py-2 px-[15px] rounded-md mb-3 flex gap-1.5 items-center border w-fit ${
              status === 'To Do'
                ? 'bg-system-warning-dark border-system-warning'
                : 'bg-system-success-dark border-system-success'
            }`}
          >
            <IconSvg
              icon={status === 'To Do' ? 'comingSoonS' : 'liveS'}
              className={status === 'To Do' ? 'text-system-warning' : 'text-system-success'}
            />
            <span className="text-sm capitalize text-grayscale-100">{status}</span>
          </div>
          {user?.isEnclaveReferralPending && slug === 'enclave-referral'  && (
            <button
              onClick={handleCopyLink}
              className="absolute bottom-4 left-4 flex items-center gap-2 p-2 lg:px-4 lg:py-[8px] text-sm rounded-md bg-grayscale-700 w-fit text-grayscale-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.5 2H7.50009H8.5H8.50009V4.00001H9.50003H10.4999H10.5V11H10.4999V11H3.49994V11V10V9.00005H1.5V9V8.00005V3V2H2.5ZM7.50009 4.00001V3H2.5V8.00005H3.49994V5.00001V4.00001H4.49994H7.50009ZM4.49994 9.00005V10H9.50003V5.00001H4.49994V8.00005H4.5V9.00005H4.49994Z"
                  fill="#EEE0F9"
                />
              </svg>
              <span className="hidden lg:block">Referral Link</span>
            </button>
          )}
        </div>

        <div className="mb-2.5">
          <div className="mb-1 text-xs text-grayscale-400">{phase}</div>
          <h4 className="text-lg text-brand-primary-light">{title}</h4>
        </div>

        <p className="overflow-hidden text-sm text-grayscale-400">{description}</p>

        <div className="absolute right-5 bottom-5 left-5">
          {[
            'connect-enclave-wallet',
            'complete-kyc-enclave',
            'first-deposit',
            'trade-perpetuals',
          ].includes(slug) && (
            <div className="flex justify-end">
              <button
                className={`flex items-center justify-center gap-2 py-2 rounded-md text-sm font-bold text-grayscale-900 bg-brand-primary-light w-[132px]`}
                onClick={handleEnclaveButtonClick}
              >
                <span>ENCLAVE</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.9985 0H11.0007V1.83333H10.9985V7.33331H9.16521V3.66702H7.33475V1.83369H9.16521V1.83333H3.66737V0H9.16521H10.9985ZM7.33439 3.66737H5.50106V5.5007H7.33439V3.66737ZM3.66737 5.49945H5.5007V7.33278H3.66737V5.49945ZM3.66702 7.33314H1.83369V9.16646H3.66702V7.33314ZM1.83333 9.16682H0V11.0001H1.83333V9.16682Z"
                    fill="#3E3E3E"
                  />
                </svg>
              </button>
            </div>
          )}

          <div className="border-t-2 border-grayscale-700 my-[17px]"></div>

          <div className="flex justify-between items-center">
            <div className="space-y-[5px]">
              <div className="text-xs text-grayscale-400">Points to earn {isEnterLoading}</div>
              <div className={`text-grayscale-100 ${pointsToEarn === 'ALOT' ? 'font-bold' : ''}`}>
                {pointsToEarn}
              </div>
            </div>

            {isEnterLoading ? (
              <SyncLoader color="#B73FFF" size={10} />
            ) : (
              <>
                {(slug === 'follow-enclave-market-on-x' ||
                  slug === 'follow-wellsda2-on-x' ||
                  slug === 'follow-pwirtjes-on-x') &&
                !isClaimed &&
                !twitterConnected ? (
                  <EnclaveEnterBtn onClick={() => handleConnectX()} title={`Connect X`} />
                ) : slug === 'connect-enclave-wallet' ||
                  slug === 'complete-kyc-enclave' ||
                  slug === 'trade-perpetuals' ||
                  slug === 'use-alpha-strategies' ||
                  slug === 'first-deposit' ? (
                  <>
                    {user?.connectEnclaveWalletPending && slug === 'connect-enclave-wallet' ? (
                      <div className="flex flex-col space-y-2">
                        <p className="text-sm font-bold text-right text-brand-secondary">
                          Processing...
                        </p>

                        <p className="text-brand-secondary text-[10px] font-normal text-right">
                          Task will be validated in less than 24h
                        </p>
                      </div>
                    ) : (
                      <>
                        {slug === 'connect-enclave-wallet' && !isClaimed ? (
                          <EnclaveEnterBtn onClick={() => onEnter(slug)} title={'Enter'} />
                        ) : null}
                      </>
                    )}

                    {user?.completeKycEnclavePending && slug === 'complete-kyc-enclave' ? (
                      <div className="flex flex-col space-y-2">
                        <p className="text-sm font-bold text-right text-brand-secondary">
                          Processing...
                        </p>

                        <p className="text-brand-secondary text-[10px] font-normal text-right">
                          Task will be validated in less than 48h
                        </p>
                      </div>
                    ) : (
                      <>
                        {slug === 'complete-kyc-enclave' && !isClaimed ? (
                          <EnclaveEnterBtn onClick={() => onEnter(slug)} title={'Enter'} />
                        ) : null}
                      </>
                    )}

                    {user?.enclaveFirstDepositPending && slug === 'first-deposit' ? (
                      <div className="flex flex-col space-y-2">
                        <p className="text-sm font-bold text-right text-brand-secondary">
                          Processing...
                        </p>
                        <p className="text-brand-secondary text-[10px] font-normal">
                          Task will be validated in less than 48h
                        </p>
                      </div>
                    ) : (
                      <>
                        {slug === 'first-deposit' && !isClaimed ? (
                          <EnclaveEnterBtn onClick={() => onEnter(slug)} title={'Enter'} />
                        ) : null}
                      </>
                    )}

                    {user?.enclaveTradePerpetualsPending && slug === 'trade-perpetuals' ? (
                      <div className="flex flex-col space-y-2">
                        <p className="text-sm font-bold text-right text-brand-secondary">
                          In Progress
                        </p>
                      </div>
                    ) : (
                      <>
                        {slug === 'trade-perpetuals' && !isClaimed ? (
                          <EnclaveEnterBtn onClick={() => onEnter(slug)} title={'Enter'} />
                        ) : null}
                      </>
                    )}

                    {user?.enclaveUseAlphaStratsPending && slug === 'use-alpha-strategies' ? (
                      <div className="flex flex-col space-y-2">
                        <p className="text-sm font-bold text-right text-brand-secondary">
                          In Progress
                        </p>
                      </div>
                    ) : (
                      <>
                        {slug === 'use-alpha-strategies' && !isClaimed ? (
                          <EnclaveEnterBtn onClick={() => onEnter(slug)} title={'Enter'} />
                        ) : null}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {!isClaimed && status === 'To Do' && (
                      <EnclaveEnterBtn onClick={() => onEnter(slug)} title={'Enter'} />
                    )}
                  </>
                )}
              </>
            )}

            {!isClaimed && status === 'claimable' && (
              <EnclaveClaimBtn status="claimable" onClick={() => onClaim(slug)} />
            )}

            {isClaimed && (
              <EnclaveClaimBtn
                status="claimed"
                onClick={() => {
                  console.log('EnclaveClaimBtn -> isClaimed');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
