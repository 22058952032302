import { Collection } from 'src/components/common/cards/types/types';
import IconSvg from 'src/components/common/ui/IconSvg';
import StatusPill from 'src/components/StatusPill';

interface Props {
  collection: Collection;
}

function AvaxCollectionBanner({ collection }: Props) {
  return (
    <div
      className="h-[418px] rounded-md w-full px-10 py-[41.5px] pb-[63.5px] bg-grayscale-800 flex flex-col justify-between relative"
      style={{ backgroundImage: `url(/assets/images/avax-banner.png)`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div className="flex z-10 justify-end">
        <div className="flex gap-3">
          <div className="bg-black bg-opacity-70 py-3 px-[18px] rounded-md">
            <div className="mb-2 text-sm text-grayscale-200 text-end">Value of rewards</div>

            <div className="text-[24px] font-bold text-grayscale-100">≈ 2500 AVAX</div>
          </div>

          {/* <div className="bg-black bg-opacity-70 py-3 px-[18px] rounded-md">
            <div className="mb-2 text-sm text-grayscale-200 text-end">Rewards Claimed</div>

            <div className="text-[24px] font-bold text-grayscale-100">0,000/1,350</div>
          </div> */}
        </div>
      </div>

      <div className="flex z-10 justify-between items-end">
        <div className="flex gap-[19px] items-center">

          <div className="w-[109px] h-[109px] rounded-md">
            <img
              src={'/assets/images/avax-logo.png'}
              className="object-contain w-full h-full rounded-md"
            />
          </div>



          <div className="space-y-[12px]">
            <div className="flex gap-[13px] items-end">
              <h3 className="uppercase text-brand-primary-light">{collection?.slug === 'avax' ? 'Avax S2' : collection?.name}</h3>

              <div className="text-sm text-grayscale-200">AVAX</div>
            </div>

            <div>
              <div className="flex gap-[9px] items-center">
                {collection?.website && (
                  <a href={collection.website} target="_blank" rel="noopener noreferrer">
                    <IconSvg icon="webM" className="text-grayscale-200" />
                  </a>
                )}

                {collection?.twitter && (
                  <a href={collection.twitter} target="_blank" rel="noopener noreferrer">
                    <IconSvg icon="twitterM" className="text-grayscale-200" />
                  </a>
                )}

                {/* {collection?.hyperspaceLink && (
                  <a href={collection.hyperspaceLink} target="_blank" rel="noopener noreferrer">
                    <IconSvg icon="hyperspaceM" className="text-grayscale-200" />
                  </a>
                )} */}
              </div>
            </div>
          </div>
        </div>

        <div>
          <StatusPill status={collection?.status || 'live'} />
        </div>
      </div>
    </div>
  );
}

export default AvaxCollectionBanner;
