import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Collection } from 'src/components/common/cards/types/types';
import IconSvg from 'src/components/common/ui/IconSvg';
import { avaxRewardCardData } from 'src/dummydata';
import { useHttp } from 'src/hooks';
import { useAccount, useSwitchChain } from 'wagmi';
import { ethers } from 'ethers';
import StagesAvax from './components/StagesAvax';
import ClaimedRewardModal from 'src/components/common/modal/ClaimedRewardModal';
import RewardCard from 'src/components/common/cards/RewardCard';
import whitelistSeason2 from '../../../whitelist/avax/season2.json';
import AvaxMintSuccessModal from './components/AvaxMintSuccessModal';

const environment = process.env.REACT_APP_ENVIRONMENT;

const AvaxMint = () => {
  const { address, isConnected, chainId } = useAccount();
  const [contractMetadata, setContractMetadata] = useState(null);
  const [claimWhitelistData, setClaimWhitelistData] = useState(null);
  const [showMintSuccessModal, setShowMintSuccessModal] = useState(false);
  const [isShowClaimMintSuccessModal, setShowClaimMintSuccessModal] = useState(false);
  const [arenaTotalMintedKeys, setArenaTotalMintedKeys] = useState('');
  const [arenaTotalMintedKeysLoading, setArenaTotalMintedKeysLoading] = useState(false);

  const [collection, setCollection] = useState<Collection>();
  const { sendRequest } = useHttp();
  const { switchChain } = useSwitchChain();
  const { id, stageId } = useParams();

  const getAvaxKeyClaimStages = () => {
    sendRequest(
      {
        url: `collections/key-claiming-stages/avax`,
        method: 'GET',
      },
      (data: any) => {
        setCollection(data);
        switchChain({ chainId: Number(data?.network.chainId) });
      },
    );
  };

  useEffect(() => {
    if (collection) setContractMetadata(collection.stages?.active[0]?.metadata[environment]);
  }, [collection, address]);

  useEffect(() => {
    let whitelist;
    if (collection?.stages?.active[0].order === 1) {
      whitelist = whitelistSeason2;
    }

    if (address && whitelist) {
      setClaimWhitelistData(whitelist[address.toLowerCase()]);
    }
    // if (stages) setContractMetadata(stages?.active[0]?.metadata[environment]);
  }, [address, collection]);

  useEffect(() => {
    getAvaxKeyClaimStages();
  }, [id, chainId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const readMintedKeyEvents = async () => {
    try {
      const avaxCollection = collection;
      if (!contractMetadata) return;
      console.log('readMintedKeyEvents contractMetadata', contractMetadata);
      setArenaTotalMintedKeysLoading(true);

      const provider = new ethers.providers.JsonRpcProvider(avaxCollection?.network.RPCUrl);
      const contract1 = new ethers.Contract(
        contractMetadata.LOOTY_KEY.address,
        contractMetadata.LOOTY_KEY.abi,
        provider,
      );
      const eventFilter = contract1.filters.TransferBatch(
        null,
        '0x0000000000000000000000000000000000000000',
      );
      const fromBlock = 1;
      const toBlock = 'latest';

      const events = await contract1.queryFilter(eventFilter, fromBlock, toBlock);

      console.log('eeeeeee', events);
      let count = 0;
      const mintedKeys = events.reduce((sum, event) => {
        const ev: any = event;
        for (let i = 0; i < ev?.args[4].length; i++) {
          count += ev?.args[4][i].toNumber();
        }
        return count;
      }, 0);
      console.log('readMintedKeyEvents-> Total Minted Keys:', mintedKeys);
      setArenaTotalMintedKeys(mintedKeys);
      setArenaTotalMintedKeysLoading(false);
    } catch (error) {
      setArenaTotalMintedKeysLoading(false);
      console.error('Error initializing event listener:', error);
    }
  };
  useEffect(() => {
    if (address && isConnected && collection?.id && contractMetadata) readMintedKeyEvents();
  }, [address, isConnected, collection, contractMetadata]);

  return (
    <>
      <AvaxMintSuccessModal
        isOpen={isShowClaimMintSuccessModal}
        onClose={setShowClaimMintSuccessModal}
        mintedKeys={claimWhitelistData?.ids ?? []}
        keysOfEachType={claimWhitelistData?.keysOfEachType ?? []}
      />

      <ClaimedRewardModal isOpen={showMintSuccessModal} onClose={setShowMintSuccessModal} />

      <section className="mb-[36px] xl:mb-[50px]">
        <div className="w-full bg-gray-800 rounded-md">
          <img src={'/assets/images/avax-banner.png'} className="w-full h-auto max-h-[418px]" />
        </div>
      </section>

      <section className="mb-[36px] lg:mb-[72px]">
        <div className="flex flex-col justify-between xl:flex-row space-y-[36px]">
          <div className="xl:w-[44.76%]">
            <div className="flex gap-[19px] items-center mb-6">
              <img
                src={'/assets/images/avax-logo.png'}
                className="lg:w-[96px] lg:h-[96px] w-[60px] h-[60px] bg-grayscale-800 rounded-md"
              />

              <div className="space-y-[8px] lg:space-y-[17px]">
                <h3 className="uppercase text-brand-primary-light text-lg lg:text-[32px]">
                  {collection?.slug === 'avax' ? 'Avax S2' : collection?.name}
                </h3>

                <div className="flex gap-[9px]">
                  {collection?.website && (
                    <IconSvg
                      icon="webM"
                      onClick={() =>
                        window.open(collection.website, '_blank', 'noopener,noreferrer')
                      }
                      className="cursor-pointer text-grayscale-400 lg:w-fit lg:h-fit w-[12px] h-[12px]"
                    />
                  )}

                  {collection?.twitter && (
                    <IconSvg
                      icon="twitterM"
                      onClick={() =>
                        window.open(collection.twitter, '_blank', 'noopener,noreferrer')
                      }
                      className="cursor-pointer text-grayscale-400 lg:w-fit lg:h-fit w-[12px] h-[12px]"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="mb-9 space-y-4 text-gray-300 lg:space-y-6">
              <p className="text-sm lg:text-base">
                Welcome back to AVAX S2! If you held keys from the original S2 contract before the
                snapshot on March 10 at 11PM EST, it&apos;s time to claim your new keys. Make sure to
                connect the same wallet that held the original S2 keys to get started. Once claimed,
                head over to Open Box—your treasure awaits.
              </p>

              <p>
                AVAX S2 features the same five key types: Bronze, Silver, Gold, Platinum, and
                Diamond. Each offers a shot at valuable Avalanche-based NFTs and tokens. Higher-tier
                keys also come with re-rolls, giving you more chances to unlock prizes.
              </p>

              <div>
                <div className="mb-2 text-xs text-grayscale-400">Value of rewards</div>

                <div className="text-lg font-semibold text-grayscale-100">≈ 2500 AVAX</div>
              </div>
            </div>

            <div>
              <div className="mb-3 text-lg text-grayscale-100">Available keys:</div>

              <div className="flex flex-wrap gap-[15px]">
                {Array.from({ length: 5 }, (_, index) => (
                  <img
                    key={index}
                    src={`/assets/images/keys/${index}.png`}
                    className="w-[96px] h-[96px] rounded"
                  />
                ))}
              </div>
            </div>
          </div>

          {collection && (
            <StagesAvax
              collection={collection}
              stageId={stageId}
              contractMetadata={contractMetadata}
              arenaTotalMintedKeys={arenaTotalMintedKeys}
              arenaTotalMintedKeysLoading={arenaTotalMintedKeysLoading}
              rewardsTotalValue={collection.rewardsTotalValue}
              setShowClaimMintSuccessModal={setShowClaimMintSuccessModal}
              setShowMintSuccessModal={setShowMintSuccessModal}
              claimWhitelistData={claimWhitelistData}
            />
          )}
        </div>
      </section>

      <section>
        <div className="mb-[15px] lg:mb-[36px]">
          <h3 className="mb-2 text-brand-primary-light">Rewards</h3>

          <div className="text-grayscale-200">
            The better the key, the more chances you get at rewards
          </div>
        </div>

        <div className="grid gap-[18px] grid-cols-[repeat(auto-fit,minmax(326px,1fr))]">
          {avaxRewardCardData.map((card, index) => (
            <RewardCard
              key={index}
              name={card.name}
              image={card.image}
              size="medium"
              showRarity={false}
            />
          ))}
        </div>
      </section>
    </>
  );
};

export default AvaxMint;
