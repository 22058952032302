import HomeBanner from 'src/pages/home/components/cards/HomeBanner';
import RewardCard from '../../components/common/cards/RewardCard';
import RecentActivityCard from '../../components/common/cards/RecentActivityCard';
import IconSvg from 'src/components/common/ui/IconSvg';
import Slider, { CustomArrowProps, Settings } from 'react-slick';
import './Home.module.css';
import {
  rewardCardData,
  recentActivityData,
  homeBannerData as initialHomeBannerData,
  HomeBannerData,
} from 'src/dummydata';

import { useEffect, useState } from 'react';
import { Collection, Stage } from 'src/components/common/cards/types/types';
import { useHttp } from 'src/hooks';
import HomeEarnItem from './components/HomeEarnItem';
import { useAccount } from 'wagmi';
import { ethers } from 'ethers';

const environment = process.env.REACT_APP_ENVIRONMENT;

function CustomArrow(props: CustomArrowProps & { additionalStyles?: React.CSSProperties }) {
  const { className, style, onClick, additionalStyles } = props;
  return (
    <IconSvg
      icon={className?.includes('slick-next') ? 'arrowRightM' : 'arrowLeftM'}
      className={`text-brand-primary-light hover:!text-brand-primary-light !w-6 !h-6 ${className}`}
      onClick={onClick}
      style={{ ...style, ...additionalStyles }}
    />
  );
}

export function getSliderSettings(
  slidesToShow: number,
  breakpoints: { [key: number]: number },
  customDots?: boolean,
  showArrows = true,
  arrowStyles?: { right?: string; left?: string },
): Settings {
  const responsiveSettings = Object.entries(breakpoints).map(([breakpoint, slides]) => ({
    breakpoint: Number(breakpoint),
    settings: {
      slidesToShow: slides,
    },
  }));

  return {
    infinite: true,
    slidesToShow,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    arrows: showArrows,
    nextArrow: <CustomArrow additionalStyles={{ right: arrowStyles?.right, zIndex: 50 }} />,
    prevArrow: <CustomArrow additionalStyles={{ left: arrowStyles?.left, zIndex: 50 }} />,
    responsive: responsiveSettings,
    dots: customDots,
    appendDots: customDots ? (dots: React.ReactNode) => <ul>{dots}</ul> : undefined,
    customPaging: customDots
      ? () => (
          <div className="dot-container">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="dot-svg"
            >
              <rect x="2" y="2" width="8" height="8" fill="#ADADAD" className="filled-dot" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.5 1.5H2.5H9.5H10.5V10.5H9.5H2.5H1.5V1.5ZM9.5 9.5V2.5H2.5V9.5H9.5Z"
                fill="#ADADAD"
                className="empty-dot"
              />
            </svg>
          </div>
        )
      : undefined,
  };
}

function Home() {
  const [activeTab, setActiveTab] = useState('all');
  const [arenaTotalMintedKeys, setArenaTotalMintedKeys] = useState('');
  const { sendRequest } = useHttp();
  const [collections, setCollections] = useState<Collection[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [homeBannerData, setHomeBannerData] = useState<HomeBannerData[]>(initialHomeBannerData);
  const { address, isConnected } = useAccount();
  useEffect(() => {
    getCollections();
  }, [activeTab]);

  useEffect(() => {
    if (address && isConnected && collections?.length > 0) readMintedKeyEvents();
  }, [address, isConnected, collections]);

  const tabs: { value: string; label: string }[] = [
    { value: 'all', label: window.innerWidth > 1024 ? 'All Projects' : 'All' },
    {
      value: 'live',
      label: 'Live',
    },
    {
      value: 'soon',
      label: 'Soon',
    },

    {
      value: 'expired',
      label: 'Expired',
    },
  ];

  const sliderSettings1 = getSliderSettings(
    7,
    {
      2500: 6,
      2200: 5,
      1850: 4,
      1500: 3,
      1200: 2,
      1000: 1,
    },
    false,
    true,
    window.innerWidth < 1024
      ? {
          right: '-9px',
          left: '-9px',
        }
      : {},
  );

  const sliderSettings3 = getSliderSettings(1, {}, true, window.innerWidth > 1024, {
    right: '40px',
    left: '40px',
  });

  const getCollections = () => {
    sendRequest(
      {
        url: 'collections',
        method: 'GET',
      },
      (data: any) => {
        setCollections(data);

        for (const banner of initialHomeBannerData) {
          const matchedCollection = data.filter((collection: Collection) => {
            if (collection.slug === banner.slug) {
              console.log('collection.slug === banner.slug', collection.slug)
              banner.title = collection.name;
              banner.slug = collection.slug;
              banner.projectType = collection.network.networkCurrency;
              banner.twitter = collection.twitter;
              banner.website = collection.website;
              return collection;
            }
          });
          const stageId = matchedCollection[0].stages?.filter((st: Stage) => st.isLive);

          if (matchedCollection[0]) {
            banner.id = matchedCollection[0].id;
            banner.stageId = stageId.length ? stageId[0].id : null;
          }
        }
        setHomeBannerData(initialHomeBannerData);
      },
    );
  };

  let filteredCollectionsEarn = collections.filter(collection => {
    if (activeTab === 'all') return true; // Show all projects
    if (activeTab === 'live') return collection.status === 'Live';
    if (activeTab === 'soon') return collection.status === 'Soon';
    if (activeTab === 'expired') return collection.status === 'Expired';
    return false;
  });
  if (searchQuery)
    filteredCollectionsEarn = filteredCollectionsEarn.filter(
      collection =>
        collection.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        !collection.isEarnBlured,
    );

  let filteredCollectionsPlay = collections.filter(collection => {
    if (activeTab === 'all') return true; // Show all projects
    if (activeTab === 'live') return collection.status === 'Live' && !collection.isPlayBlured;
    if (activeTab === 'soon') return collection.status === 'Soon';
    if (activeTab === 'expired') return collection.status === 'Expired';
    return false;
  });
  if (searchQuery)
    filteredCollectionsPlay = filteredCollectionsPlay.filter(
      collection =>
        collection.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        !collection.isPlayBlured,
    );

  const readMintedKeyEvents = async () => {
    try {
      const arenaCollection = collections.filter(collection => collection.slug == 'arena')[0];
      const phase1Config = arenaCollection?.stages?.filter(stage => stage.order === 1)[0]?.metadata[
        environment
      ]; // right now just for arena
      if (!phase1Config || Object.keys(phase1Config).length === 0) return;


      const provider = new ethers.providers.JsonRpcProvider(arenaCollection?.network.RPCUrl);
      // const provider = new ethers.providers.JsonRpcProvider(
      //   `https://eth-sepolia.g.alchemy.com/v2/7HTsUbq3GedSAO_HZ2cswYr2vcJ9W6DP`
      // );
      // const provider = new ethers.providers.JsonRpcProvider(
      //   `https://base-sepolia.g.alchemy.com/v2/EnacGRWAvwYnhx_Vd4e6y6Q4wfafzxMg`
      // );
      // const provider = new ethers.providers.JsonRpcProvider(
      //   `https://avax-fuji.g.alchemy.com/v2/irtyiHXuwACSy69kigixjG-pJkVpxiL0`
      // );
      // const provider = new ethers.providers.JsonRpcProvider(
      //   `https://avax-mainnet.g.alchemy.com/v2/irtyiHXuwACSy69kigixjG-pJkVpxiL0`
      // );

      const contract1 = new ethers.Contract(
        phase1Config.LOOTY_KEY.address,
        phase1Config.LOOTY_KEY.abi,
        provider,
      );
      const eventFilter = contract1.filters.TransferBatch(
        null,
        '0x0000000000000000000000000000000000000000',
      );
      const fromBlock = 1;
      const toBlock = 'latest';
      console.log('eventFilter', eventFilter)

      const events = await contract1.queryFilter(eventFilter, fromBlock, toBlock);

      console.log('eeeeeee', events);
      console.log('=================================');
      let count = 0;
      const mintedKeys = events.reduce((sum, event) => {
        const ev: any = event;
        for (let i =0; i <  ev?.args[4].length; i++) {
          console.log('each one', ev?.args[4][i].toNumber())  
          count +=  ev?.args[4][i].toNumber()
        }
        return count;
      }, 0);
      console.log('readMintedKeyEvents-> Total Minted Keys:', mintedKeys);
      setArenaTotalMintedKeys(mintedKeys);
    } catch (error) {
      console.error('Error initializing event listener:', error);
    }
  };

  return (
    <>
      <div className="max-w-[2630px] mx-auto">
        <section className="mb-[36px] lg:mb-[72px]">
          {console.log('aaaa', homeBannerData)}
          <div className="min-w-0 min-h-0 mx-auto max-w-[2630px]">
            <Slider {...sliderSettings3}>
              {homeBannerData.map((data, index) => (
                <HomeBanner
                  key={index}
                  bannerImg={window.innerWidth > 1024 ? data.image : data.imageMobile}
                  btnText={data.btnText}
                  title={data.title}
                  slug={data.slug}
                  status={data.status}
                  collectionImage={data.collectionImage}
                  stageId={data?.stageId}
                  id={data?.id}
                  website={data.website}
                  twitter={data.twitter}
                  projectType={data.projectType}
                  showButtons={data.showButtons}
                  arenaTotalMintedKeys={arenaTotalMintedKeys}
                  backgroundPosition={data.backgroundPosition}
                />
              ))}
            </Slider>
          </div>
        </section>

        <section className="mb-[36px] lg:mb-[72px]">
          <div className="flex lg:items-end justify-between mb-[36px] lg:mb-[72px] lg:flex-row flex-col gap-5">
            <div>
              <h1 className="uppercase mb-[19px] text-brand-primary-light">
                We only <span className="text-brand-primary">Win</span>!
              </h1>

              <p className="text-sm lg:text-lg text-grayscale-200">
                Get keys. Trade keys. Open lootboxes. Win rewards.
              </p>
            </div>

            <div className="flex gap-6">
              <div>
                <div className="mb-2 text-xs lg:text-sm text-grayscale-400 lg:text-end">
                  Total Winnable Rewards
                </div>

                <div className="text-lg font-semibold text-grayscale-100 lg:text-end">
                  ≈ $370,000
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center px-5 py-3 mb-5 rounded-md lg:mb-10 bg-grayscale-800">
            <div className="flex gap-2">
              {tabs.map(tab => (
                <div
                  key={tab.value}
                  onClick={() => setActiveTab(tab.value)}
                  className={`px-4 py-2 rounded cursor-pointer select-none ${
                    activeTab === tab.value ? 'bg-grayscale-600' : ''
                  }`}
                >
                  <div
                    className={`text-xs lg:text-sm font-semibold ${
                      activeTab === tab.value ? 'text-grayscale-200' : 'text-gray-300'
                    }`}
                  >
                    {tab.label}
                  </div>
                </div>
              ))}
            </div>

            <div className="hidden relative lg:block">
              <input
                type="search"
                placeholder="Search"
                className="w-[309px] bg-grayscale-900 text-grayscale-200 placeholder:text-grayscale-600 pl-9 py-2 text-sm relative rounded-md pr-3"
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
              />

              <IconSvg
                icon="searchS"
                className="absolute inset-y-0 left-4 m-auto text-grayscale-300"
              />
            </div>
          </div>

          <div className="grid gap-y-3 gap-x-5 w-full 3xl:grid-cols-2">
            <div className="col-span-1">
              <div className="text-lg lg:text-2xl font-bold text-grayscale-300 mb-2.5">Earn</div>

              {filteredCollectionsEarn.length === 0 ? (
                <div
                  className={`flex items-center px-5 rounded-md py-[15px] bg-grayscale-800 h-[90px]`}
                >
                  <h5 className="text-brand-primary-light">No projects found</h5>
                </div>
              ) : (
                <>
                  {filteredCollectionsEarn
                    .filter(collection => !collection.isEarnBlured)
                    .map((collection, index) => (
                      <HomeEarnItem
                        arenaTotalMintedKeys={arenaTotalMintedKeys}
                        collection={collection}
                        key={index}
                        handleClick={() => ''}
                      />
                    ))}

                  {filteredCollectionsEarn
                    .filter(collection => collection.isEarnBlured)
                    .slice(0, 2)
                    .map((collection, index) => (
                      <HomeEarnItem
                        key={index}
                        index={index + 1}
                        collection={collection}
                        handleClick={() => ''}
                        arenaTotalMintedKeys={arenaTotalMintedKeys}
                      />
                    ))}
                </>
              )}
            </div>

            <div className="col-span-1">
              <div className="text-2xl font-bold text-grayscale-300 mb-2.5">Play</div>

              <img src="/assets/images/coming-soon.png" />
            </div>
          </div>
        </section>

        <section className="mb-[36px] lg:mb-[72px]">
          <div className="mb-12">
            <h2 className="uppercase mb-[19px] text-brand-primary-light">
              <span className="text-brand-primary">Hot</span> Rewards
            </h2>

            <p className="text-grayscale-200 max-w-[1255px] text-sm lg:text-lg">
              Our top rewards celebrate exceptional achievements, offering unique digital
              collectibles and unforgettable real-life experiences. Browse through the latest
              claimed rewards. Join our community of winners and claim your hot rewards!
            </p>
          </div>

          <div className="min-w-0 min-h-0 mx-auto max-w-[2630px]">
            <Slider {...sliderSettings1}>
              {rewardCardData.map((card, index) => (
                <div key={index} className="!block mx-auto px-4 lg:px-3">
                  <RewardCard
                    name={card.name}
                    estimatedValue={card.estimatedValue}
                    rarity={card.rarity}
                    image={card.image}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </section>

        <section>
          <div className="mb-12">
            <h2 className="uppercase mb-[19px] text-brand-primary-light">
              Recent <span className="text-brand-primary">Activities</span>
            </h2>

            <p className="text-grayscale-200 max-w-[895px] text-sm lg:text-lg">
              Track newly minted keys and opened boxes in real-time. Our live feed will guide you to
              optimize your strategy for minting and loot box openings.
            </p>
          </div>

          <div className="mx-auto min-w-0 min-h-0">
            <Slider {...sliderSettings1}>
              {recentActivityData.map((activity, index) => (
                <div className="!block mx-auto px-4 lg:px-3" key={index}>
                  <RecentActivityCard
                    projectName={'Project Name'}
                    estimatedValue={activity.estimatedValue}
                    rewardOpened={activity.rewardOpened}
                    image={activity.image}
                    activity={activity.projectName}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </section>
      </div>
    </>
  );
}

export default Home;
