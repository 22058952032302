import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Referral: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const referralCode = urlParams.get('code');
    const referralApp = urlParams.get('referralApp');

    if (referralCode && referralApp) {
      localStorage.setItem('referralCode', referralCode);
      localStorage.setItem('referralApp', referralApp);

      if (referralApp === 'Enclave') {
        navigate('/leaderboards/enclave');
      } else if (referralApp === 'Mvx') {
        navigate('/leaderboards/multiversx');
      } else {
        navigate('/');
      }
    }
  }, [location, navigate]);

  return <div className="text-white text-center">Loading...</div>;
};

export default Referral;
