import { useParams } from "react-router-dom";
import MultiverxDetail from "./multiverx/MultiverxDetail";
import EnclaveDetail from "./enclave/EnclaveDetail";

export default function LeaderboardDetail() {
  const { collectionSlug } = useParams();

  // Determine which component to render based on collectionSlug
  if (collectionSlug === "multiversx") {
    return <MultiverxDetail />;
  } else if (collectionSlug === "enclave") {
    return <EnclaveDetail />;
  } else {
    return <div>Not Found</div>;
  }
}
