import axios from 'axios';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import SyncLoader from 'react-spinners/SyncLoader';
import { useHttp } from 'src/hooks';
import { useAccount } from 'wagmi';
import rewardContracts from '../../../contracts/avax/AvaxRewardContract.json';

interface Props {
  activeProject: any;
}

const environment = process.env.REACT_APP_ENVIRONMENT;

function AvaxRewardCard({ activeProject }: Props) {
  const { address } = useAccount();
  const { sendRequest } = useHttp();

  const [nftRewards, setNftRewards] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchNFTRewards = async () => {
      const phase1Config =
        activeProject?.stages?.active[0]?.metadata[environment] ||
        activeProject?.stages?.expired[0]?.metadata[environment];

      if (!phase1Config) return;

      try {
        setLoading(true);
        const provider = new ethers.providers.JsonRpcProvider(activeProject?.network.RPCUrl);

        const contract = new ethers.Contract(
          phase1Config.LOOTY_TOKEN_LOTTERY.address,
          phase1Config.LOOTY_TOKEN_LOTTERY.abi,
          provider,
        );

        const fromBlock = 1;
        const toBlock = 'latest';

        // Fetch Fulfilled and Claimed events
        const fulfilledEvents = await contract.queryFilter(
          contract.filters.Claimed(address),
          fromBlock,
          toBlock,
        );
        // const claimedEvents = await contract.queryFilter(
        //   contract.filters.Claimed(address),
        //   fromBlock,
        //   toBlock,
        // );

        console.log('Fetched Fulfilled Events:', fulfilledEvents);

        const rewards = await Promise.all(
          fulfilledEvents.map(async event => {
            const isNft = event.args?.isNFT;
            const contractAddress = event.args?.rewardContract;
            const tokenIdOrAmount = event.args?.tokenIdOrAmount.toString();

            if (isNft) {
              const nftContract = new ethers.Contract(
                contractAddress,
                [
                  'function name() view returns (string)',
                  'function tokenURI(uint256) view returns (string)',
                ],
                provider,
              );

              let collectionName = 'Unknown Collection';
              let tokenUri = '';
              let imageUrl = '';
              let nftName = '';

              try {
                collectionName = await nftContract.name();
                tokenUri = await nftContract.tokenURI(tokenIdOrAmount);

                const metadata = await getMetadataByTokenUri(tokenUri);
                imageUrl = metadata?.image;
                nftName = metadata?.name;
              } catch (error) {
                console.warn(
                  `Could not fetch metadata for token ${tokenIdOrAmount} at contract: ${contractAddress}`,
                );
              }

              return {
                contractAddress,
                tokenId: tokenIdOrAmount,
                nftName,
                collectionName,
                imageUrl,
                status: 'Won',
              };
            } else {
              console.log(
                'token contract address',
                contractAddress,
                rewardContracts[contractAddress]?.collectionName,
              );

              let collectionName = 'Unknown Collection';

              if (rewardContracts && rewardContracts[contractAddress]) {
                collectionName = rewardContracts[contractAddress]?.collectionName;
              }

              const amount = (parseFloat(tokenIdOrAmount.toString() || '0') / Math.pow(10, 18))
                .toFixed(2)
                .toString();

              return {
                contractAddress,
                balance: amount,
                collectionName,
                status: 'Won',
              };
            }
          }),
        );

        console.log('rewards', rewards);
        // Mark claimed NFTs
        // claimedEvents.forEach(event => {
        //   const isNft = event.args?.isNFT;
        //   const claimedContract = event.args?.rewardContract;
        //   const claimedTokenId = event.args?.tokenIdOrAmount.toString();
        //   const rewardIndex = rewards.findIndex(
        //     reward =>
        //       reward.tokenId === claimedTokenId && reward.contractAddress === claimedContract,
        //   );
        //   if (rewardIndex !== -1) {
        //     rewards[rewardIndex].status = 'Claimed';
        //   }
        // });

        setNftRewards(rewards);
      } catch (error) {
        console.error('Error fetching NFT rewards:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNFTRewards();
  }, [activeProject, address, environment]);

  const getMetadataByTokenUri = (tokenUri: string) => {
    return new Promise((resolve, reject) => {
      sendRequest(
        {
          url: `metadata/nft`,
          method: 'POST',
          payload: { tokenUri },
        },
        (data: any) => {
          if (data) {
            resolve(data); // Resolve the promise with the data
          } else {
            reject(new Error('No metadata found'));
          }
        },
      );
    });
  };

  return (
    <>
      {loading ? (
        <div className="border-2 border-grayscale-700 p-5 w-full rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 min-h-[182px] sm:min-h-[400px] lg:min-h-[400px] uppercase col-span-full">
          <SyncLoader color="#B73FFF" size={10} />
        </div>
      ) : nftRewards.length > 0 ? (
        nftRewards.map((reward, index) => (
          <div key={index} className="p-5 rounded-md bg-grayscale-800 space-y-[17px] w-[280px]">
            {/* <img
              src={
                reward.nftName
                  ? reward.imageUrl
                  : `/assets/images/reward/avax/tokens/${reward.collectionName}.png`
              }
              alt=""
              className="w-full mx-auto"
            /> */}
            {reward.imageUrl?.endsWith('.mp4') ? (
              <video className="w-full mx-auto rounded-md" controls autoPlay loop>
                <source src={reward.imageUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={
                  reward.nftName
                    ? reward.imageUrl
                    : `/assets/images/reward/avax/tokens/${reward.collectionName}.png`
                }
                alt=""
                className="w-full mx-auto rounded-md"
              />
            )}

            <div className="py-3 rounded-md bg-grayscale-900">
              <h5 className="text-lg font-normal text-center text-brand-primary-light">
                {reward.nftName
                  ? `${reward.nftName}`
                  : `${reward.balance} ${reward.collectionName}`}
              </h5>
            </div>
          </div>
        ))
      ) : (
        <div className="border-2 border-grayscale-700 p-5 rounded-md flex items-center justify-center text-[22px] lg:text-[32px] font-joystix text-grayscale-700 min-h-[182px] sm:min-h-[400px] lg:min-h-[400px] uppercase w-full">
          No rewards found
        </div>
      )}
    </>
  );
}

export default AvaxRewardCard;
