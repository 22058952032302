import { Collection } from 'src/components/common/cards/types/types';
import IconSvg from 'src/components/common/ui/IconSvg';

interface Props {
  collection?: Collection;
  contractCurrentSeason: number | null;
}

function EnclaveBanner({ collection, contractCurrentSeason }: Props) {
  return (
    <div
      className="h-[418px] rounded-md w-full px-10 py-[41.5px] pb-[63.5px] bg-grayscale-800 flex flex-col justify-between relative"
      style={{
        backgroundImage: `url(/assets/images/enclave-banner.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'right',
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div className="flex z-10 justify-end">
        <div className="flex gap-3">
          <div className="bg-black bg-opacity-70 py-3 px-[18px] rounded-md">
            <div className="mb-2 text-sm text-grayscale-200 text-end">Value of rewards</div>

            <div className="text-[24px] font-bold text-grayscale-100">≈ $5,000</div>
          </div>

          {/* <div className="bg-black bg-opacity-70 py-3 px-[18px] rounded-md">
            <div className="mb-2 text-sm text-grayscale-200 text-end">Rewards Claimed</div>

            <div className="text-[24px] font-bold text-grayscale-100">0,000/1,350</div>
          </div> */}

          {/* <div className="bg-black bg-opacity-70 py-3 px-[18px] rounded-md">
            <div className="mb-2 text-sm text-grayscale-200 text-end">Minted keys</div>

            <div className="text-[24px] font-bold text-grayscale-100">0,000/0,000</div>
          </div> */}
        </div>
      </div>

      <div className="flex z-10 justify-between items-end">
        <div className="flex gap-[19px] items-center">
          <div className="w-[109px] h-[109px] rounded-md">
            <img
              src={'/assets/images/enclave-logo.png'}
              className="object-contain w-full h-full rounded-md"
            />
          </div>

          <div className="space-y-[12px]">
            <div className="flex gap-[13px] items-end">
              <h3 className="uppercase text-brand-primary-light">
                Season {contractCurrentSeason === 0 ? '1' : Number(contractCurrentSeason) + 1}
              </h3>

              <div className="text-sm text-grayscale-200">AVAX</div>
            </div>

            <div className="flex gap-[9px] items-center">
              <a href="https://www.enclave.market" target="_blank" rel="noopener noreferrer">
                <IconSvg icon="webM" className="text-[#E6E6E6]" />
              </a>

              <a href="https://x.com/enclavemarkets" target="_blank" rel="noopener noreferrer">
                <IconSvg icon="twitterM" className="text-[#E6E6E6]" />
              </a>
            </div>
          </div>
        </div>

        <div>
          <div className="py-2 px-[15px] rounded-md bg-system-success-dark border-system-success flex gap-1.5 items-center border w-fit">
            <IconSvg icon="liveS" className="text-system-success" />

            <span className="text-sm text-grayscale-100">Claiming live</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnclaveBanner;
