import IconSvg from '../ui/IconSvg';
interface Props {
  size?: 'small' | 'medium';
  name: string;
  estimatedValue?: string;
  rarity: string;
  image: string;
  blurName?: boolean;
  showRarity?: boolean;
}

const rarityToIconMap: Record<string, string> = {
  Common: 'commonM',
  Uncommon: 'uncommonM',
  Rare: 'rareM',
  Epic: 'epicM',
  Legendary: 'legendaryM',
  Mythical: 'mythicalM',
};

const rarityToColorMap: Record<string, string> = {
  Common: 'bg-grayscale-700 text-white', // Adjust colors as needed
  Uncommon: 'bg-rarity-uncommon-dark text-white',
  Rare: 'bg-rarity-rare-dark text-white',
  Epic: 'bg-rarity-epic-dark text-white',
  Legendary: 'bg-rarity-legendary-dark text-black',
  Mythical: 'bg-rarity-mythical-dark text-black',
};

function RewardCard({
  size = 'small',
  name,
  image,
  blurName = false,
  showRarity = true,
  rarity,
}: Props) {
  const thumbnailSize = size === 'small' ? 271 : 286;

  return (
    <div className="p-[18px] border-2 border-transparent rounded-md bg-grayscale-800 hover:border-grayscale-700 hover:bg-grayscale-900 group min-w-fit">
      <div
        className="bg-grayscale-700 rounded-md mb-[17px]"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: `${thumbnailSize}px`,
          minWidth: `${thumbnailSize}px`,
        }}
      ></div>

      <h5 className="flex gap-2 items-center max-w-full truncate text-brand-primary-light">
        {blurName && <img src="/assets/images/blur-reward-price.png" className="h-[40px]" />}

        {name}
      </h5>

      {showRarity && (
        <>
          <div className="w-full border-b-2 border-grayscale-700 my-[17px]"></div>

          <div className="flex justify-between items-center p-1 rounded-md border border-transparent bg-grayscale-900 group-hover:border-grayscale-700">
            <div className="font-semibold text-grayscale-300 pl-3.5">Rarity</div>

            <button
              className={`flex gap-2 items-center text-sm font-semibold rounded-md py-[6px] text-grayscale-200 px-[16px] ${rarityToColorMap[rarity]} h-fit`}
            >
              <IconSvg icon={rarityToIconMap[rarity] as any} />

              <span>{rarity}</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default RewardCard;
